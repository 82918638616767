import sanitizeHtml from 'sanitize-html';
import moment from 'moment-timezone';
import sanitizeFilename from 'sanitize-filename';

import { isPublisher } from './utils/users';
import { CustomNoticeFilingType } from './types/filingType';
import { getErrorReporter } from './utils/errors';
import { FIREBASE_PROJECT, CLOUDINARY_BUCKET, envs } from './constants';
import {
  FirebaseTimestamp,
  ENotice,
  ESnapshotExists,
  EOrganization,
  EInvoice,
  ESnapshot,
  EUser,
  ERef,
  ERate,
  XMLSyncExportSettings,
  BuildIntegrationExportSettings,
  EFirebaseContext,
  EDisplayParams,
  exists,
  EInvite,
  EQuerySnapshot,
  CustomerOrganization,
  Customer
} from './types';

import {
  getDeadlineTimeForPaper,
  publishingDayEnumValuesFromDeadlines,
  disableNonPublishingDays,
  getIsAfterPublishingDeadline
} from './utils/deadlines';
import {
  OrganizationType,
  OccupationType,
  InvoiceStatus,
  NoticeStatusType,
  NoticeType,
  CurrencyType,
  RateType
} from './enums';

import { FileType, getFileTypeFromExtensionString } from './types/mime';
import {
  getOrCreateCustomer,
  getOrCreateCustomerOrganizationForNotice,
  getOrCreateCustomerOrganization,
  getShouldInvoiceCustomerOrCustomerOrgOutsideColumn,
  getShouldRequireUpfrontPaymentFromCustomer
} from './notice/customer';
import { AffidavitDisabledNotice } from './types/notice';
import { getColumnInches, getInvoiceAmountsBreakdown } from './pricing';
import { OPEN_INVITE_STATUSES } from './users';
import { isAffidavitDisabled } from './affidavits';
import { hasPaymentOrPartialRefund } from './utils/invoices';
import { getNewspaperFromNotice } from './utils/references';
import { SyncTriggerEvent } from './types/events';
import { BuildFormat, SyncFormat } from './types/integrations/sync';
import { getInheritedProperty } from './utils/inheritance';
import { getOrThrow } from './utils/refs';
import { oklahoma } from './pricing/rateTypes/oklahoma';
import { INVOICE_STATUSES_CLOSED } from './model/objects/invoiceModel';
import { getDateStringForDateInTimezone } from './utils/dates';
import { isCustomerOnThirtyDaysEomBilling } from './billing/helpers';
import {
  getErrors,
  getResponses,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from './types/responses';
import { OrganizationTypeValue } from './enums/OrganizationType';
import { UserOccupationValue } from './enums/OccupationType';
import { ColumnService } from './services/directory';
import { CDN_URL_FOR_PROJECT } from './cdn';

export const getOccupationValFromOrganizationVal = (
  organizationVal: OrganizationTypeValue
): UserOccupationValue => {
  const organizationType = OrganizationType.by_value(organizationVal);
  return OccupationType.by_key(organizationType?.defaultOccupationKey)!.value;
};

export const getOrganizationValFromOccupationVal = (occupationVal: number) => {
  const occupationKey = OccupationType.by_value(occupationVal)?.key;
  return OrganizationType.items().find(
    org => org && org.defaultOccupationKey === occupationKey
  )?.value;
};
export const dateToDateString = (
  date: Date,
  timezone = 'America/Chicago',
  includeTimestamp = false
) => {
  if (!includeTimestamp) {
    date.setHours(12);
  }

  const stringFormat = !timezone?.startsWith('America')
    ? includeTimestamp
      ? 'D MMM YYYY, h:mm:ss a z'
      : 'D MMM YYYY'
    : includeTimestamp
    ? 'MMM. D, YYYY, h:mm:ss a z'
    : 'MMM. D, YYYY';

  return moment(date).tz(timezone).format(stringFormat);
};

export const publicationDateStarted = (
  publicationDate: Date | string,
  timezone: string
) => {
  const currentTime = moment(Date.now());
  return currentTime.isAfter(
    moment(publicationDate).tz(timezone).startOf('day')
  );
};

/**
 * Convert unix timestamp into a date based on the given time zone
 * @param {number} timestamp
 * @param {string} timezone
 * @returns Date with a format according to the timezone
 */
export const unixTimeStampToNewspaperTimezoneDate = (
  timestamp: number,
  timezone?: string
) => {
  return moment(new Date(timestamp * 1000))
    .tz(timezone || 'America/Chicago')
    .format(!timezone?.startsWith('America') ? 'D MMM YYYY' : 'MMM. D, YYYY');
};

export const dateToAbbrev = (date: Date, timezone?: string) => {
  if (!timezone) date.setHours(12);

  const dateFormat =
    timezone && !timezone.startsWith('America') ? 'D/M/YY' : 'M/D/YY';

  return getDateStringForDateInTimezone({
    date,
    timezone: timezone || 'America/Chicago',
    dateFormat
  });
};
export const dateToUtc = (date: Date) =>
  moment.utc(date).format('MMM. D, YYYY');

export const toLocaleString = (date: Date | string) =>
  moment(date).format('MMM. D, YYYY');

/**
 * @deprecated Use safeFirestoreTimestampOrDateToDate instead
 * Convert a date-like object to a JS Date.
 */
export const firestoreTimestampOrDateToDate = (
  date: { _seconds: number } | Date | FirebaseTimestamp | string
) => {
  try {
    if ((date as any)._seconds) return new Date((date as any)._seconds * 1000);
  } catch (err) {}

  if (!date) throw new Error('date is undefined');
  try {
    return (date as FirebaseTimestamp).toDate() as Date;
  } catch (err) {
    if ((date as FirebaseTimestamp).seconds)
      return new Date((date as FirebaseTimestamp).seconds * 1000);
    if (typeof date === 'number') return new Date(date);
    if (typeof date === 'string') return new Date(date);
    return date as Date;
  }
};

/**
 * Convert a date-like object to a JS Date.
 */
export const safeFirestoreTimestampOrDateToDate = (
  ...inputDate: Parameters<typeof firestoreTimestampOrDateToDate>
): ResponseOrError<ReturnType<typeof firestoreTimestampOrDateToDate>> => {
  try {
    const returnDate = firestoreTimestampOrDateToDate(...inputDate);
    return wrapSuccess(returnDate);
  } catch (err) {
    return wrapError(err as Error);
  }
};

export const areSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
};

/**
 * Checks if the user's local date is on the same UTC day of the given timestamp
 * @param {Date} date The user's local date
 * @param {number} timestamp The start of UTC day timestamp
 * @returns {boolean}
 */
export const areSameDayUTC = (date: Date, timestamp: number) => {
  return (
    moment.utc(moment(date).format('YYYY/MM/DD')).startOf('day').valueOf() ===
    timestamp
  );
};
export const countWords = (s: string) => {
  const trimmedLeadingAndTrailing = s.replace(/(^\s*)|(\s*$)/gi, ''); // exclude start and end white-space
  const trimmedInner = trimmedLeadingAndTrailing.replace(/\s{2,}/g, ' '); // 2 or more space to 1
  const trimmedNewlines = trimmedInner.replace(/\n/g, ' '); // exclude newline with a start spacing
  return trimmedNewlines.split(' ').filter(function (str) {
    return str !== '';
  }).length;
};

export const stripHtmlTags = (str: string) => {
  if (!str) return '';
  return str.replace(/<[^>]*>?/gm, '');
};

export const escapeHTML = (str: string) => {
  const entityMap = new Map<string, string>(
    Object.entries({
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    })
  );
  return str
    .replace(/[&<>"']/g, (tag: string) => entityMap.get(tag) || tag)
    .replace(/\s$/, '&nbsp;');
};

export const captureTaglessEscapedHTML = (escapedHtml: string) => {
  const entityMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&nbsp;': ' '
  };
  let capturedString = escapedHtml;
  for (const [escapedChar, capturedChar] of Object.entries(entityMap)) {
    capturedString = capturedString.replace(
      new RegExp(escapedChar, 'g'),
      capturedChar
    );
  }
  return capturedString;
};

export const centsToDollarsString = (cents: number) => {
  return (cents / 100).toFixed(2);
};

export const confirmationNumberFromTransferId = (transferId: string) => {
  return transferId.slice(-8);
};

export const guidGenerator = () => {
  const S4 = function () {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const rateCodeGenerator = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export const sameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const isValidDate = (d: Date) => {
  return !Number.isNaN(Date.parse(d.toDateString()));
};

export const closestDayFutureDay = (dates: Date[]) => {
  let closest = Infinity;
  const startOfToday = new Date(Date.now());
  startOfToday.setHours(0, 0, 0, 0);

  const comparableStartOfToday = startOfToday.getTime();

  dates.forEach(date => {
    const comparableDate = date.getTime();
    if (comparableDate >= comparableStartOfToday && comparableDate < closest) {
      closest = comparableDate;
    }
  });

  const close = new Date(closest);

  if (!isValidDate(close)) return null;
  return close;
};

// TODO: it's probably not good that we're using different logic
// to get the last pub date here and in lastNoticePublicationDate;
// we should reconcile
export const lastPublicationDate = (dates: Date[]) => {
  const lastDate = dates[dates.length - 1];
  if (!lastDate) return null;
  if (!isValidDate(lastDate)) return null;
  return lastDate;
};

export const sortNoticePublicationDatesChronologically = (
  publicationDates: ENotice['publicationDates']
) => {
  return publicationDates.sort((aTimestamp, bTimestamp) => {
    const a = aTimestamp.toDate();
    const b = bTimestamp.toDate();
    return a < b ? -1 : a > b ? 1 : 0;
  });
};

export const firstNoticePublicationTimestamp = (
  noticeSnap: ESnapshotExists<Pick<ENotice, 'publicationDates'>>
) => {
  return sortNoticePublicationDatesChronologically(
    noticeSnap.data().publicationDates
  )[0];
};

export const firstNoticePublicationDate = (
  noticeSnap: ESnapshotExists<Pick<ENotice, 'publicationDates'>>
) => {
  return firstNoticePublicationTimestamp(noticeSnap).toDate();
};

export const lastNoticePublicationDate = (
  noticeSnap: ESnapshotExists<Pick<ENotice, 'publicationDates'>>
) =>
  noticeSnap
    .data()
    .publicationDates.map(fbTime => fbTime.toDate())
    .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))[0];

/**
 * A helper function that checks if a notice has started and/or finished publication.
 */
export const noticeIsPublished = (
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  const { iana_timezone } = newspaper.data();

  const firstPublicationDate = moment
    .tz(firstNoticePublicationDate(notice), iana_timezone)
    .startOf('day');

  const hasReachedFirstPublication = moment().isAfter(firstPublicationDate);

  const lastPublicationDate = moment
    .tz(lastNoticePublicationDate(notice), iana_timezone)
    .startOf('day');

  const hasReachedFinalPublication = moment().isAfter(lastPublicationDate);

  return {
    hasReachedFirstPublication,
    hasReachedFinalPublication
  };
};

export const canPublisherEditNoticeWithoutSupport = (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  if (!noticeSnap.data().publicationDates) return true;
  return new Date() < firstNoticePublicationDate(noticeSnap);
};

export const canAdvertiserEditNoticeWithoutSupport = (
  noticeSnap: ESnapshotExists<ENotice>,
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const { deadlines, deadlineOverrides = {} } = newspaperSnap.data();
  if (!deadlines) throw new Error('No deadlines found for newspaper');
  const publicationDayEnumValues =
    publishingDayEnumValuesFromDeadlines(deadlines);

  // get any unusual publication dates that might have been edited in by the publisher
  const customPublicationDate = noticeSnap.data().publicationDates.find(date =>
    // returns true if date isn't a valid publication date
    disableNonPublishingDays(
      date.toDate(),
      publicationDayEnumValues,
      newspaperSnap.data().deadlineOverrides
    )
  );

  // if there is an unusual publication date, the advertiser cannot edit this notice
  if (customPublicationDate) return false;

  const nearestDeadline = getDeadlineTimeForPaper(
    firstNoticePublicationDate(noticeSnap),
    deadlines,
    deadlineOverrides,
    newspaperSnap.data().iana_timezone,
    noticeSnap.data(),
    newspaperSnap
  );

  return moment(new Date()).isBefore(nearestDeadline);
};

export const assetQuality = {
  high: {
    width: 1000
  }
};

export const IMAGE_TYPES = [FileType.TIF, FileType.PNG, FileType.JPG];

export const fileTypeIsImage = (fileType: FileType) =>
  IMAGE_TYPES.includes(fileType);

/**
 * Converts a cdnified URL into a storage path
 */
export const unCdnify = (
  url: string,
  { project }: { project?: string } = { project: FIREBASE_PROJECT }
): string => {
  const decodedUrl = decodeURI(url);
  if (decodedUrl.includes('imgix.net')) {
    return decodedUrl.replace(/https:\/\/.*?.imgix.net\//, '');
  }

  // Example:
  // https://firebasestorage.googleapis.com/v0/b/bucket.appspot.com/o/documentcloud%2FdJG9yJUrdPdK56twDH8L%2Ftemplates%2FBellevilleTemplate4.idml?alt=media&token=8dd21a65-9178-4ebb-ae83-488536cb21e9
  if (decodedUrl.includes('firebasestorage.googleapis.com')) {
    const path = decodedUrl.split('/o/')[1].split('?')[0];
    return decodeURIComponent(path);
  }

  if (decodedUrl.includes('storage.googleapis.com')) {
    const path = decodedUrl.split('appspot.com')[1].split('?')[0];
    return decodeURIComponent(path);
  }

  if (decodedUrl.includes('res.cloudinary.com')) {
    return decodedUrl.split(`${project}/`)[1];
  }

  if (decodedUrl.includes('cdn.column.us')) {
    return decodedUrl.split(`cdn.column.us/`)[1];
  }

  throw new Error(`Cannot unCdnify url ${url}`);
};

/**
 * Cloudinary restricts resource IDs to 255 characters
 * see https://support.cloudinary.com/hc/en-us/articles/207746165-Why-do-I-get-a-public-ID-too-long-error-when-trying-to-fetch-from-a-remote-URL-
 *
 * Attempting to access a resource with a longer ID will result in a 400 error
 */
export const CDN_MAX_CHARS = 255;

export const cdnIfy = (
  storagePath: string,
  options: {
    firebaseProject?: string;
    useImgix?: boolean;
    imgixTransformations?: Record<string, string>;
    cloudinaryTransformations?: string;
    useColumnCDN?: boolean;
  } = {}
) => {
  const encodedStoragePath = encodeURI(storagePath);
  // In the Admin environment we pass in the project, in all other environments
  // it is inferred
  const project = options.firebaseProject ?? FIREBASE_PROJECT;
  const projectPlusStoragePath = `${project}/${encodedStoragePath}`;
  const extensionString = (storagePath && storagePath.split('.').pop()) || '';
  const fileType = getFileTypeFromExtensionString(extensionString);

  const useColumnCdnForProject =
    options.useColumnCDN && project in CDN_URL_FOR_PROJECT;

  // we can use Cloud CDN if the project supports Cloud CDN and if there are no transformation required
  const useColumnBucketCDN =
    useColumnCdnForProject &&
    !options.imgixTransformations &&
    !options.cloudinaryTransformations;
  if (useColumnBucketCDN) {
    return `${CDN_URL_FOR_PROJECT[project]}/${encodedStoragePath}`;
  }

  // we use imgix if colud cdn is not enabled and the filetype is an imgix only filetype or the storage path is too long
  const requiresImgix =
    options.useImgix ||
    projectPlusStoragePath.length > CDN_MAX_CHARS ||
    (fileType &&
      [
        FileType.CSV,
        FileType.EPS,
        FileType.HEIC,
        FileType.HEIF,
        FileType.HTML,
        FileType.IDML,
        FileType.JSON,
        FileType.TIF,
        FileType.WORD_DOC,
        FileType.XML,
        FileType.ZIP
      ].includes(fileType));
  if (requiresImgix) {
    return `https://${project}.imgix.net/${encodedStoragePath}${
      options.imgixTransformations
        ? `?${new URLSearchParams(options.imgixTransformations).toString()}`
        : ''
    }`;
  }

  const isImageOrPdf =
    fileType && (fileTypeIsImage(fileType) || fileType === FileType.PDF);
  if (!project)
    throw new Error('Cannot create CDN link without firebase project');

  let resourceType: string;
  let transformations = '';

  if (isImageOrPdf) {
    resourceType = 'image';

    transformations = options?.cloudinaryTransformations
      ? `/${options.cloudinaryTransformations}`
      : '';
  } else {
    resourceType = 'raw';
  }

  if (useColumnCdnForProject && resourceType === 'image') {
    // we default to use DEMO infra for all other projects
    const cdnUrl =
      CDN_URL_FOR_PROJECT[project] ?? CDN_URL_FOR_PROJECT['enotice-demo-8d99a'];
    return `${cdnUrl}/${resourceType}${transformations}/${projectPlusStoragePath}`;
  }

  return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/${resourceType}/upload${transformations}/${projectPlusStoragePath}`;
};

export const safeCdnIfy = (...args: Parameters<typeof cdnIfy>) => {
  try {
    return wrapSuccess(cdnIfy(...args));
  } catch (err) {
    return wrapError(err as Error);
  }
};

export const preventXSS = (html: string) =>
  sanitizeHtml(html, {
    allowedTags: ['div', 'strong', 'ais-highlight-0000000000']
  });

type GetNoticeTypeOptions = {
  skipDisplayType?: boolean;
};

export type DataWithNoticeType = {
  confirmedHtml?: ENotice['confirmedHtml'];
  noticeType?: ENotice['noticeType'];
  previousNoticeType?: ENotice['previousNoticeType'];
  postWithoutFormatting?: ENotice['postWithoutFormatting'];
};

export const getNoticeTypeFromNoticeDataUnwrapped = (
  notice: DataWithNoticeType,
  newspaper: EOrganization | undefined,
  options?: GetNoticeTypeOptions
) => {
  const newspaperSpecificType = newspaper?.allowedNotices?.find(type => {
    if (
      notice.noticeType === NoticeType.display_ad.value &&
      options?.skipDisplayType &&
      notice.previousNoticeType
    )
      return type.value === notice.previousNoticeType;
    return type.value === notice.noticeType;
  });

  if (newspaperSpecificType) return newspaperSpecificType;

  const noticeType = NoticeType.by_value(notice.noticeType);
  if (noticeType) {
    return noticeType as unknown as CustomNoticeFilingType;
  }

  return null;
};

export const getNoticeTypeFromNoticeData = (
  notice: DataWithNoticeType,
  newspaper: ESnapshot<EOrganization> | undefined,
  options?: GetNoticeTypeOptions
) => {
  return getNoticeTypeFromNoticeDataUnwrapped(
    notice,
    newspaper?.data(),
    options
  );
};

export const getNoticeType = (
  noticeSnap: ESnapshot<DataWithNoticeType>,
  newspaperSnap: ESnapshot<EOrganization> | undefined,
  options?: GetNoticeTypeOptions
) => {
  if (!noticeSnap?.data())
    return NoticeType.custom as unknown as CustomNoticeFilingType;
  return getNoticeTypeFromNoticeData(
    noticeSnap.data()!,
    newspaperSnap,
    options
  );
};

// TODO (APP-388): ideally we can revert this back to 600 once we figure out how to mitigate the downstream affects on pagination size
export const DEFAULT_DPI = 300;
export const DEFAULT_DISPLAY_AD_BORDER_SIZE_IN_PIXELS = 8;

export const inchesToPixels = (inches: number, ppi?: number): number => {
  // Rounding here because decimal pixels are almost never useful
  return Math.round(inches * (ppi || DEFAULT_DPI));
};

export const pixelsToInches = (pixels: number, ppi?: number): number => {
  return pixels / (ppi || DEFAULT_DPI);
};

export const noticeNeedsUpFrontInvoice = (
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  if (!newspaper.data().allowedNotices) return false;
  if (notice.data().noticeType === NoticeType.custom.value) return false;
  if (notice.data().noticeType === NoticeType.display_ad.value) return false;
  if (notice.data().invoice) return false;
  if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
    return false;

  const typeform = newspaper
    .data()
    .allowedNotices!.find(
      an => an.value === notice.data().noticeType
    )?.typeform;

  if (!typeform) return false;

  return true;
};

/**
 * A notice should be auto-invoiced if:
 *  - autoInvoice is set on the newspaper, rate, or notice type AND
 *  - notice is not custom or cancelled
 */
export const shouldAutoInvoice = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  // Custom or canceled notices do not auto-invoice
  if (
    noticeSnap.data().noticeType === NoticeType.custom.value ||
    noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value
  ) {
    return false;
  }

  // TODO(COREDEV-1662): Use new parent boolean helper once merged!
  const newspaperSnap = await getOrThrow(noticeSnap.data().newspaper);
  const parentSnap = await newspaperSnap.data().parent?.get();
  const newspaperAutoInvoice =
    newspaperSnap.data().autoInvoice || parentSnap?.data()?.autoInvoice;

  if (newspaperAutoInvoice) {
    return true;
  }

  const rateSnap = await noticeSnap.data().rate?.get();
  if (rateSnap?.data()?.autoInvoice) {
    return true;
  }

  // If the notice is a display ad, we want to make sure we use the correct notice type settings by checking the previousNoticeType property
  const noticeTypeWithSettings = getNoticeType(noticeSnap, newspaperSnap, {
    skipDisplayType: true
  });
  if (noticeTypeWithSettings?.autoInvoice) {
    return true;
  }

  return false;
};

export const getShouldInvoiceNoticeOutsideColumnByNoticeType = (
  noticeSnap: ESnapshotExists<ENotice>,
  newspaperSnap: ESnapshot<EOrganization>
): boolean | null => {
  // If the notice is a display ad, we want to make sure we use the correct notice type settings by checking the previousNoticeType property
  const noticeTypeWithSettings = getNoticeType(noticeSnap, newspaperSnap, {
    skipDisplayType: true
  });

  // Keeping this block in as this has been the behavior return historically, but we
  // may want to remove this in the future
  if (
    noticeSnap.data().noticeType === NoticeType.custom.value ||
    noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value
  ) {
    return false;
  }

  return noticeTypeWithSettings?.invoiceOutsideColumn ?? null;
};

export const getShouldSendAffidavitNotification = (
  noticeSnap: ESnapshotExists<ENotice>,
  newspaperSnap: ESnapshot<EOrganization>,
  advertiserSnap: ESnapshot<EUser>
): boolean => {
  const { affidavit } = noticeSnap.data();

  if (!exists(newspaperSnap) || !exists(advertiserSnap) || !affidavit) {
    return false;
  }

  const affidavitDisabled = isAffidavitDisabled(
    noticeSnap.data(),
    newspaperSnap
  );

  if (affidavitDisabled) {
    return false;
  }

  return true;
};

export const getShouldSendAffidavitNotificationForNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const newspaperSnap = await noticeSnap.data().newspaper.get();
  const advertiserSnap = await noticeSnap.data().filer.get();
  return getShouldSendAffidavitNotification(
    noticeSnap,
    newspaperSnap,
    advertiserSnap
  );
};

// Returns if we should release the affidavit of a notice for the advertiser before invoice payment
export const getAlwaysAllowAffidavitDownloadForNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const newspaper = await noticeSnap.data().newspaper.get();
  const advertiserOrg = await noticeSnap.data().filedBy?.get();
  const advertiser = await noticeSnap.data().filer?.get();

  /**
   * Note: The current user may have access to the notice but not the original filer's customer record
   * In this case, the call to get the customer will fail with a permission error
   * However we want the check to continue, so log a warning rather than throwing the error
   */
  let customer: ESnapshotExists<Customer> | undefined;
  try {
    customer = await getOrCreateCustomer(ctx, advertiser, newspaper);
  } catch (error) {
    getErrorReporter().logAndCaptureWarning(
      `Cannot get customer for notice: ${noticeSnap.ref.id}. The original filer may be anonymous or no longer a member of the advertiser organization.`
    );
  }

  const customerOrg = await getOrCreateCustomerOrganizationForNotice(
    ctx,
    noticeSnap
  );

  return (
    advertiser?.data()?.alwaysAllowAffidavitDownload ||
    advertiserOrg?.data()?.alwaysAllowAffidavitDownload ||
    newspaper?.data()?.alwaysAllowAffidavitDownload ||
    customer?.data().enableAffidavitsBeforePayment ||
    customerOrg?.data().enableAffidavitsBeforePayment
  );
};

export const shouldReleaseAffidavit = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>,
  invoiceSnap: ESnapshot<EInvoice> | undefined
) => {
  if (!noticeSnap.data().affidavit) {
    throw new Error(
      `Should release affidavit helper called on a notice without an affidavit!`
    );
  }

  const isInvoicePaid =
    exists(invoiceSnap) &&
    (hasPaymentOrPartialRefund(invoiceSnap) ||
      invoiceSnap.data().invoiceOutsideColumn);

  if (isInvoicePaid) return true;

  const alwaysAllowAffidavitDownload =
    await getAlwaysAllowAffidavitDownloadForNotice(ctx, noticeSnap);
  if (alwaysAllowAffidavitDownload) return true;
  return false;
};

export const shouldReleaseAffidavitForNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const invoiceSnap = await noticeSnap.data().invoice?.get();
  return shouldReleaseAffidavit(ctx, noticeSnap, invoiceSnap);
};

export const getAllowMultiPageAffidavits = async (
  noticeSnap: ESnapshotExists<ENotice>,
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const rate = await noticeSnap.data().rate?.get();
  return (
    rate?.data()?.multiPageAffidavits ||
    newspaperSnap.data()?.multiPageAffidavits
  );
};

/**
 * This function Ensures that dates passed from frontend to backend are same
 * @param date Date string from new Date().toDateString();
 * @returns newDate;
 */
export const ensureSameDate = (date: string) => {
  if (!moment(date, 'ddd MMM DD YYYY', true).isValid())
    throw new Error('Invalid Date Format');
  return moment(date).toDate();
};

export const getCurrencySymbol = (currency?: string) => {
  return (
    CurrencyType.by_key(currency?.toLowerCase())?.symbol ??
    CurrencyType.usd.symbol
  );
};

/*
 * This function is used to determine whether or not a
 * notice requires upfront payment at any time. If
 * called before invoice creation, we pull from the
 * settings of associated objects. During invoice
 * creation, the user can use the override toggle &
 * then that value is set on the notice object.
 */
export const getNoticeRequiresUpfrontPayment = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  // Logic hierarchy for requireUpfrontPayment:
  // 0. Notice
  if (noticeSnap.data().requireUpfrontPayment != null) {
    return !!noticeSnap.data().requireUpfrontPayment;
  }

  // 1. Customer (user x for newspaper y)
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const advertiserSnap = (await noticeSnap
    .data()
    .filer.get()) as ESnapshotExists<EUser>;
  const customer = await getOrCreateCustomer(
    ctx,
    advertiserSnap,
    newspaperSnap
  );
  const customerRequiresUpfrontPayment =
    getShouldRequireUpfrontPaymentFromCustomer(customer.data());
  if (customerRequiresUpfrontPayment != null) {
    return customerRequiresUpfrontPayment;
  }
  // 2. Customer organization (advertiser org x for newspaper y)
  const customerOrganization = await getOrCreateCustomerOrganizationForNotice(
    ctx,
    noticeSnap
  );
  const customerOrganizationRequiresUpfrontPayment =
    getShouldRequireUpfrontPaymentFromCustomer(customerOrganization?.data());
  if (customerOrganizationRequiresUpfrontPayment != null) {
    return customerOrganizationRequiresUpfrontPayment;
  }
  // 3. Custom notice type
  const noticeType = getNoticeType(noticeSnap, newspaperSnap);
  if (noticeType && noticeType.upFrontPayment != null) {
    return noticeType.upFrontPayment;
  }
  // 4. Newspaper (does the newspaper default to requiring upfront payment?)
  if (newspaperSnap.data()?.requireUpfrontPayment != null) {
    return !!newspaperSnap.data().requireUpfrontPayment;
  }
  return false;
};

// For the given notice and newspaper this returns if bulk payments should be enabled for this notice
export const shouldBulkInvoiceUser_v2 = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  // Bulk payments must be enabled on the newspaper
  const parentOrg = await newspaper.data().parent?.get();

  if (newspaper.data().bulkPaymentEnabled_v2 === false) {
    return false;
  }

  if (
    !newspaper.data().bulkPaymentEnabled_v2 &&
    !parentOrg?.data()?.bulkPaymentEnabled_v2
  ) {
    return false;
  }

  const advertiserOrg = await notice.data().filedBy?.get();
  const advertiser = await notice.data().filer.get();
  const customer = await getOrCreateCustomer(ctx, advertiser, newspaper);
  const customerOrganization = advertiserOrg
    ? await getOrCreateCustomerOrganization(ctx, advertiserOrg, newspaper)
    : null;
  const customerBulkPayment = customer.data().bulkPaymentEnabled_v2;

  // For advertiser organiations, check bulk payment enablement at the customer organization level
  if (customerOrganization) {
    const customerOrgBulkPayment =
      customerOrganization.data().bulkPaymentEnabled_v2;
    if (typeof customerOrgBulkPayment === 'boolean') {
      return customerOrgBulkPayment;
    }

    // For individuals, check bulk payment enablement at the customer level
  } else if (typeof customerBulkPayment === 'boolean') {
    return customerBulkPayment;
  }

  // TODO: remove falling back to check enablement on advertiser(org) level
  // Fall back to checking bulk payments on the user and user's org level
  if (advertiserOrg?.data()?.bulkPaymentEnabled_v2) {
    return true;
  }

  if (advertiser.data()?.bulkPaymentEnabled_v2) {
    return true;
  }

  return false;
};

export const getDueDate = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>,
  requireUpfrontPayment?: boolean | null,
  date?: Date
) => {
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const {
    deadlines,
    deadlineOverrides = {},
    iana_timezone
  } = newspaperSnap.data() as EOrganization;
  if (!deadlines) throw new Error('No deadlines found for newspaper');

  const shouldBulkInvoiceUser = await shouldBulkInvoiceUser_v2(
    ctx,
    noticeSnap,
    newspaperSnap
  );
  const customerOnThirtyDaysEomBilling = await isCustomerOnThirtyDaysEomBilling(
    ctx,
    noticeSnap,
    newspaperSnap
  );

  if (
    shouldBulkInvoiceUser ||
    (customerOnThirtyDaysEomBilling && !requireUpfrontPayment)
  ) {
    const endOfNextMonth = moment()
      .tz(iana_timezone)
      .add(2, 'M')
      .startOf('month')
      .toDate()
      .getTime();
    return endOfNextMonth / 1000;
  }

  if (requireUpfrontPayment) {
    const closestPublicationDate: Date = firstNoticePublicationDate(noticeSnap);
    const deadline: moment.Moment = getDeadlineTimeForPaper(
      closestPublicationDate,
      deadlines,
      deadlineOverrides,
      iana_timezone,
      noticeSnap.data(),
      newspaperSnap
    );
    // If publisher changes due date then get the time of the ad deadline for the first publication date
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const hours = deadline.toDate().getHours();
      const minutes = deadline.toDate().getMinutes();
      const customDeadline = moment(formattedDate).set({
        hour: hours,
        minute: minutes
      });
      return customDeadline.toDate().getTime() / 1000;
    }
    return deadline.toDate().getTime() / 1000;
  }

  return moment().tz(iana_timezone).add(1, 'M').toDate().getTime() / 1000 - 2;
};

export const standardizePhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumberString;
};

export const validatePhoneNumbers = async (phoneNumberString: string) => {
  const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
  return !!phoneNumberString.match(phoneRegex);
};

export const getPhoneNumberFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const userSnap = await getOrThrow(notice.data().filer);
  const userIsPublisher = isPublisher(userSnap);
  const activeOrganizationSnap = await userSnap
    .data()
    .activeOrganization?.get();
  const activeOrgPhone = activeOrganizationSnap?.data()?.phone;
  const savedPhone = userIsPublisher
    ? activeOrgPhone || ''
    : userSnap.data().phone || activeOrgPhone || '';

  const standardizedPhone = standardizePhoneNumber(savedPhone);
  if (savedPhone !== standardizedPhone && userIsPublisher) {
    await activeOrganizationSnap?.ref.update({
      phone: standardizedPhone
    });
  } else {
    await userSnap.ref.update({
      phone: standardizedPhone
    });
  }

  return standardizedPhone;
};

export const maybeGetXMLSyncExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
): Promise<XMLSyncExportSettings | undefined> => {
  const newspaperXMLExport = newspaperSnap.data().xmlExport;
  if (newspaperXMLExport) {
    return newspaperXMLExport;
  }

  const parent = await newspaperSnap.data().parent?.get();
  const parentXMLExport = parent?.data()?.xmlExport;
  if (parentXMLExport) {
    return parentXMLExport;
  }

  return undefined;
};

export const getXMLSyncExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
): Promise<XMLSyncExportSettings> => {
  const syncExportSettings = await maybeGetXMLSyncExportSettings(newspaperSnap);

  if (!syncExportSettings) {
    throw new Error(
      `XML export information not specified for paper ${
        newspaperSnap.data().name
      } with ID: ${newspaperSnap.id}`
    );
  }

  return syncExportSettings;
};

/**
 * Helper function calls getXMLExportFormatFromNewspaper function
 * @param notice Snapshot of notice, used to pull newspaper data
 * @returns      XML Export settings from the newspaper referenced in the notice
 */
export const maybeGetXMLSyncExportFormatFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const newspaper = await getNewspaperFromNotice(notice);
  return await maybeGetXMLSyncExportSettings(newspaper);
};

/**
 * Helper function calls getXMLExportFormatFromNewspaper function
 * @param notice Snapshot of notice, used to pull newspaper data
 * @returns      XML Export settings from the newspaper referenced in the notice
 */
export const getXMLSyncExportFormatFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const newspaper = await getNewspaperFromNotice(notice);
  return await getXMLSyncExportSettings(newspaper);
};
//
export const maybeGetBuildIntegrationExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
): Promise<BuildIntegrationExportSettings | undefined> => {
  return getInheritedProperty(newspaperSnap.ref, 'buildExport') ?? undefined;
};

export const getBuildIntegrationExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
): Promise<BuildIntegrationExportSettings> => {
  const buildExportSettings = await maybeGetBuildIntegrationExportSettings(
    newspaperSnap
  );

  if (!buildExportSettings) {
    throw new Error(
      `Build export information missing for paper ${
        newspaperSnap.data().name
      } with ID ${newspaperSnap.id}`
    );
  }

  return buildExportSettings;
};

export const maybeGetBuildExportFormatFromNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const newspaperSnap = await getNewspaperFromNotice(noticeSnap);

  return await maybeGetBuildIntegrationExportSettings(newspaperSnap);
};

export const getBuildExportFormatFromNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const newspaperSnap = await getNewspaperFromNotice(noticeSnap);

  return await getBuildIntegrationExportSettings(newspaperSnap);
};

export const getExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const newspaperFTP = newspaperSnap.data().bulkDownload?.ftp;
  if (newspaperFTP) return newspaperFTP;

  const parent = await newspaperSnap.data().parent?.get();
  const parentFTPSettings = parent?.data()?.bulkDownload?.ftp;
  if (parentFTPSettings) return parentFTPSettings;

  throw new Error(
    `FTP export information not specified for paper ${
      newspaperSnap.data().name
    } with ID: ${newspaperSnap.id}`
  );
};

// count bold words from confirmedHtml
export const getBoldWords = (html: string, DOMparser: typeof DOMParser) => {
  const space = /\s/;
  const doc = new DOMparser().parseFromString(html, 'text/html');

  let totalBoldWords = 0;
  const totalBoldElements: string[] = [];
  const elementsWithStrongTag = doc.getElementsByTagName('strong');
  const elementsWithBTag = doc.getElementsByTagName('b');

  for (let i = 0; i < elementsWithStrongTag.length; i++) {
    totalBoldElements.push(elementsWithStrongTag?.[i].innerHTML);
  }

  for (let i = 0; i < elementsWithBTag.length; i++) {
    totalBoldElements.push(elementsWithBTag?.[i].innerHTML);
  }

  if (!totalBoldElements.length) return 0;
  for (let i = 0; i < totalBoldElements.length; i++) {
    if (space.test(totalBoldElements[i])) {
      const splitText = totalBoldElements[i]
        .split(' ')
        .filter((elem: string) => elem !== '');
      totalBoldWords += splitText.length;
    } else totalBoldWords += 1;
  }

  return totalBoldWords;
};

export const isPastDueInNewspaperTimezone = (
  dueDate: moment.Moment,
  timezone: string,
  now: moment.Moment
) => {
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_DAY = 86400;
  const MILLISECONDS_IN_SECOND = 1000;
  const dueDateNewspaperTimeZone = dueDate.tz(timezone);
  const offset = dueDateNewspaperTimeZone.utcOffset() * SECONDS_IN_MINUTE;
  return now.isAfter(
    moment(
      (Math.ceil(dueDateNewspaperTimeZone.unix() / SECONDS_IN_DAY) *
        SECONDS_IN_DAY -
        offset) *
        MILLISECONDS_IN_SECOND
    )
  );
};

// check if upload file type is accepted

export const getFileExtension = (fileName: string) => {
  return fileName?.split('.')?.pop()?.toLowerCase() as string;
};

export const isValidExtension = (
  currentFileName: string,
  validExtensions: string
) => {
  const extension = getFileExtension(currentFileName);

  if (validExtensions.includes(extension)) {
    return true;
  }
  return false;
};

export const getDaysSinceFirstWeekdayOfQuarter = (now: moment.Moment) => {
  let startOfQuarter = moment(now.format('YYYY-MM-DD')).startOf('quarter');

  // handle Saturday
  if (startOfQuarter.day() === 6) {
    startOfQuarter = startOfQuarter.add(2, 'days');
  }

  // handle Sunday
  if (startOfQuarter.day() === 0) {
    startOfQuarter = startOfQuarter.add(1, 'days');
  }

  return now.startOf('day').diff(startOfQuarter.startOf('day'), 'days');
};

type FulfilledPromiseResult<T> = {
  status: 'fulfilled';
  value: T;
};

type RejectedPromiseResult = {
  status: 'rejected';
  reason: Error;
};

export type AwaitAll<T> = FulfilledPromiseResult<T> | RejectedPromiseResult;

/**
 * This is a helper function meant to mimic the behavior of Promise.allSettled(),
 * which we do not have available until we upgrade to Node 12. It should be used
 * in place of Promise.all() when an early rejection of an item should not
 * cause the entire Promise.all() to reject.
 *
 * @deprecated
 * use Promise.allSettled() instead
 *
 * @param awaitArray is an array of items to wait for resolution on. If given a
 * promise, this function waits for it to settle.
 *
 * @returns a promise that resolves to an array after all promises in the given
 * array have settled (either resolved or rejected). The array contains objects
 * with a value if the corresponding item resolved or a reason if it rejected.
 */
export const awaitAllPromises = async <T>(
  awaitArray: (T | Promise<T>)[]
): Promise<AwaitAll<T>[]> => {
  const returnArray: AwaitAll<T>[] = Array(awaitArray.length);

  await Promise.all(
    awaitArray.map(
      async (promiseOrValue, index) =>
        /**
         * For each item in the array create a wrapper Promise that we resolve
         * only after the item settles (including a rejected Promise). This
         * ensures that the Promise.all() does not settle early on a single
         * reject.
         */
        new Promise<void>(res => {
          /**
           * We could get a non-promise, so we only need to add handling if it
           * is in fact a Promise.
           */
          if (promiseOrValue instanceof Promise) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            promiseOrValue
              .then(value => {
                returnArray[index] = { value, status: 'fulfilled' };
              })
              .catch(reason => {
                returnArray[index] = { reason, status: 'rejected' };
              })
              .finally(() => res());
            return;
          }

          /**
           * We reach this part of the function if the item is merely a value.
           * Push it to the return array as a resolved item and resolve the
           * wrapper Promise.
           */
          returnArray[index] = {
            status: 'fulfilled',
            value: promiseOrValue
          };
          res();
        })
    )
  );

  return returnArray;
};

export const getFulfilled = <T>(results: AwaitAll<T>[]): T[] => {
  const fulfilled = results.filter((res): res is FulfilledPromiseResult<T> => {
    if (res.status === 'fulfilled') {
      return true;
    }

    return false;
  });

  return fulfilled.map(({ value }) => value);
};

export const getRejected = <T>(results: AwaitAll<T>[]): Error[] => {
  const rejected = results.filter((res): res is RejectedPromiseResult => {
    if (res.status === 'fulfilled') {
      return false;
    }

    return true;
  });

  return rejected.map(({ reason }) => reason);
};

export const getNoticePreviewLines = (
  rate: ESnapshot<ERate> | undefined,
  displayParameters: EDisplayParams | undefined | null,
  noticeType: number
) => {
  // Some rates (OK, IA) are calculated on a per-line basis even for display ads
  // If the notice is a display, use the linesPerInch computed value
  // instead of displayParams.lines, which won't exist for a display
  if (
    exists(rate) &&
    !!displayParameters &&
    noticeType === NoticeType.display_ad.value
  ) {
    if (rate.data().rateType === RateType.oklahoma.value) {
      return oklahoma.getImageLines(displayParameters, rate.data());
    }
  }
  return displayParameters?.lines || 0;
};

type DisplayUnit = {
  /** The human-readable name for the unit (ex: Words, Display Inches) */
  unit: string;

  /** The number of units */
  value: number;
};

/**
 * Get the units and quantity for a given rate.
 *
 * ex: A notice priced at 4 inches would be
 * {
 *   unit: "Display Inches",
 *   value: 4
 * }
 */
export const getDisplayUnits = (
  rate: ESnapshot<ERate> | undefined,
  displayParameters: EDisplayParams | undefined | null
): DisplayUnit => {
  if (!displayParameters) {
    return rate?.data()?.rateType === RateType.inch.value
      ? {
          unit: RateType.inch.plural,
          value: 0
        }
      : {
          unit: RateType.column_inch.plural,
          value: 0
        };
  }

  return rate?.data()?.rateType === RateType.inch.value
    ? {
        unit: RateType.inch.plural,
        value: displayParameters.area
      }
    : {
        unit: RateType.column_inch.plural,
        value: getColumnInches(
          displayParameters.height,
          displayParameters.columns,
          rate?.data()?.roundOff || null
        )
      };
};

export const getNoticeIsInvoicedOutsideColumn = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
): Promise<boolean> => {
  try {
    // Note: throughout this function we use 'foo != null' to find values
    // that are neither 'null' or 'undefined' (relying on null == undefined).
    const newspaper = await notice.data().newspaper.get();
    const invoice = await notice.data().invoice?.get();

    // Logic hierarchy for invoiceOutsideColumn:
    // 0. Invoice if exists
    if (exists(invoice) && invoice.data().invoiceOutsideColumn != null) {
      return invoice.data().invoiceOutsideColumn!;
    }

    // 1. Newspaper
    if (!newspaper.data()?.allowInvoiceOutsideColumn) {
      return false;
    }

    // 2. Customer
    const customer = await getOrCreateCustomer(
      ctx,
      await notice.data().filer.get(),
      newspaper
    );
    const customerIsInvoicedOutsideColumn =
      getShouldInvoiceCustomerOrCustomerOrgOutsideColumn(customer.data());
    if (customerIsInvoicedOutsideColumn != null) {
      return customerIsInvoicedOutsideColumn;
    }

    // 3. Customer organization
    const customerOrg = await getOrCreateCustomerOrganizationForNotice(
      ctx,
      notice
    );
    const customerOrgIsInvoicedOutsideColumn =
      getShouldInvoiceCustomerOrCustomerOrgOutsideColumn(customerOrg?.data());
    if (customerOrgIsInvoicedOutsideColumn != null) {
      return customerOrgIsInvoicedOutsideColumn;
    }

    // 4. Notice type
    const noticeTypeIsInvoicedOutsideColumn =
      getShouldInvoiceNoticeOutsideColumnByNoticeType(notice, newspaper);
    if (noticeTypeIsInvoicedOutsideColumn != null) {
      return noticeTypeIsInvoicedOutsideColumn;
    }

    // 5. Newspaper
    return !!newspaper.data()?.invoiceOutsideColumn;
  } catch (err) {
    getErrorReporter().logAndCaptureError(
      ColumnService.PAYMENTS,
      err,
      `Error determining invoice outside Column status for notice`,
      { noticeId: notice.id }
    );
    return false;
  }
};

export const getMinutesAndSecondsStringFromSeconds = (
  seconds: number
): string => {
  if (seconds === 1) return '1 second';
  if (seconds < 60) return `${seconds} seconds`;
  const wholeMinutes = Math.floor(seconds / 60);
  const remainder = seconds % 60;
  if (!remainder)
    return `${wholeMinutes} ${wholeMinutes === 1 ? 'minute' : 'minutes'}`;
  return `${wholeMinutes} ${
    wholeMinutes === 1 ? 'minute' : 'minutes'
  } and ${getMinutesAndSecondsStringFromSeconds(remainder)}`;
};

export const getToastMessageFromXMLExportSettings = (
  exportSettings: XMLSyncExportSettings
) => {
  const baseMessage = `Sent request to sync notice.`;

  if (!exportSettings.debouncedQueueTimeInSeconds) {
    return baseMessage;
  }

  const debouncedTimeString = getMinutesAndSecondsStringFromSeconds(
    exportSettings.debouncedQueueTimeInSeconds
  );

  return `${baseMessage} Notice will sync ${debouncedTimeString} after most recent notice event`;
};

export const normalizeSpaces = (
  stringToNormalize: string | null | undefined
) => {
  if (!stringToNormalize) return '';
  return stringToNormalize.replace(/\s+/g, ' ').trim();
};

export const canRefundNoticeThruStripe = (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>
) => {
  /**
   * We cannot automatically refund notices paid by check in Stripe
   * We are making a product decision to also not allow automatic ACH refunds in Stripe;
   * for the time being, refunds for both of these payment methods will be handled manually
   */
  if (
    invoice.data().paymentMethod === 'check' ||
    invoice.data().paymentMethod === 'ach'
  ) {
    return false;
  }

  return (
    // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
    // If payment is initiated but not fully processed, how does refunding work?
    [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(
      invoice.data().status
    ) &&
    !invoice.data().paid_outside_stripe &&
    !invoice.data().invoiceOutsideColumn &&
    !notice.data().transfer
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const removeUndefinedFields = <T extends object>(obj: T, depth = 0) => {
  Object.keys(obj).forEach(key => {
    const val = (obj as any)[key];
    if (val === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete (obj as any)[key];
    }

    // Recurse into sub-properties but stop at depth 5 to avoid catastrophe
    if (typeof val === 'object' && val !== null && depth < 5) {
      removeUndefinedFields(val, depth + 1);
    }
  });
  return obj;
};

/**
 * This helper function identifies undefined values in an object and replaces them with the
 * firestore FieldValue indicating the field should be removed. It only works on first order
 * properties, though — to delete nested properties, use dot-notation keys in the update call.
 *
 * Ex. Replaces top-level properties:
 * ```ts
 * const replaced = replaceUndefinedWithDelete(ctx, {
 *   a: 1,
 *   b: undefined
 * })
 *
 * replaced === {
 *   a: 1,
 *   b: ctx.fieldValue().delete()
 * }
 * ```
 *
 * Ex. Ignores nested properties:
 * ```ts
 * const replaced = replaceUndefinedWithDelete(ctx, {
 *   a: 1,
 *   b: {
 *     c: undefined,
 *     d: 2
 *   }
 * })
 *
 * replaced === {
 *   a: 1,
 *   b: {
 *     c: undefined,
 *     d: 2
 *   }
 * }
 * ```
 *
 * To delete a nested field, use dot notation in update:
 * ```ts
 * await ref.update({
 *   'b.c': ctx.fieldValue().delete()
 * })
 * ```
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const replaceUndefinedWithDelete = <T extends object>(
  ctx: EFirebaseContext,
  obj: T
): T => {
  const replacedObj = { ...obj };
  Object.keys(obj).forEach(key => {
    const val = (obj as any)[key];
    if (val === undefined) {
      (replacedObj as any)[key] = ctx.fieldValue().delete();
    }
  });

  return replacedObj;
};

type NullToUndefined<T> = {
  [K in keyof T]: Exclude<T[K], null> | undefined;
};

/**
 * Replace any properties of the object set to 'null' with 'undefined'.
 * Mostly useful for converting EPlacement to Partial<ENotice> but could
 * have other uses too.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const replaceNullWithUndefined = <T extends object>(
  obj: T
): NullToUndefined<T> => {
  const res: Record<string, any> = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val === null) {
      res[key] = undefined;
    } else {
      res[key] = val;
    }
  });

  return res as NullToUndefined<T>;
};

/**
 * Identify Column user emails
 * @param {string} email
 * @returns {boolean}
 */
export const isColumnEmail = (email: string): boolean => {
  const allowedDomains = ['column.us', 'enotice.io'];

  return allowedDomains.some(domain => {
    return email.endsWith(domain);
  });
};

/**
 * Checks if a user is part of Column based on their email address
 * @param {ESnapshotExists<EUser>} user
 * @returns {boolean}
 */
export const isColumnUser = (user: ESnapshotExists<EUser>): boolean => {
  const { email = '' } = user.data();
  return isColumnEmail(email);
};

/**
 * getDefaultColumnsForUserUserOrgWithNewspaper looks for the property defaultColumns on a customer or customer organization object
 *
 * @param customer The customer object linking between the paper and the advertiser
 * @param customerOrganization The customer organization linking between the paper and the advertiser's org if any
 * @returns default number of columns if it is attached to the customer or customer org object if it exists
 */
export const getDefaultColumnsForUserUserOrgWithNewspaper = async (
  customer: ERef<Customer> | null,
  customerOrganization: ERef<CustomerOrganization> | null
): Promise<number | null> => {
  // if we have a customer organization and a default column
  // value set on it, use that first
  const customerOrganizationSnap = await customerOrganization?.get();
  const customerOrgDefaultColumns =
    customerOrganizationSnap?.data()?.defaultColumns;
  if (customerOrgDefaultColumns) {
    return customerOrgDefaultColumns;
  }

  // if there is a default on the customer, use it
  const customerSnap = await customer?.get();
  const customerDefaultColumns = customerSnap?.data()?.defaultColumns;
  if (customerDefaultColumns) {
    return customerDefaultColumns;
  }

  return null;
};

/**
 * a utility function that converts a numeric representation of a number to the
 * corresponding words in English. E.g., convert 1 to "one" or 19 to "nineteen".
 * It accepts the numeric as either a string or a number for ease of use, and
 * works for any number from 0 through 20.
 */
export const getWordsFromNumber = (numOrString: string | number): string => {
  const num =
    typeof numOrString === 'number' ? numOrString : parseInt(numOrString, 10);

  if (isNaN(num))
    throw new Error(
      `The number provided as a string (${numOrString}) could not be parsed`
    );

  const numStrings = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
    'Twenty'
  ];

  const numString = numStrings[num];

  if (!numString)
    throw new Error(
      `Sorry, this function does not convert negative numbers or positive numbers greater than 20`
    );

  return numString;
};

/**
 * See INVOICE_STATUSES_CLOSED for explanation
 */
export const isInvoiceClosed = (invoice: ESnapshot<EInvoice> | undefined) => {
  return (
    exists(invoice) && INVOICE_STATUSES_CLOSED.includes(invoice.data().status)
  );
};

/**
 * Returns the number of calendar days separating date1 from date2.
 *
 * If date1 is BEFORE date2 then this will return a negative number:
 * Example: calendarDaysApart('2022-05-24', '2022-05-26') === -2
 *
 * If date1 is AFTER date2 then this will return a positive number:
 * Example: calendarDaysApart('2022-05-26', '2022-05-24') === 2
 *
 * Note: localTimezone is the iana timezone string
 * See these docs for supported: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
 *
 * Also, see these docs https://momentjs.com/timezone/docs/#/using-timezones/
 * for how conversions to/from different timezones work
 */
export const calendarDaysApart = (
  date1: Date,
  date2: Date,
  localTimezone?: string
) => {
  const m1 = localTimezone
    ? moment.utc(date1).tz(localTimezone).startOf('day')
    : moment.utc(date1).startOf('day');
  const m2 = localTimezone
    ? moment.utc(date2).tz(localTimezone).startOf('day')
    : moment.utc(date2).startOf('day');
  return m1.diff(m2, 'days');
};

export const getDisplayName = (
  firstName: string | undefined,
  lastName: string | undefined
) => `${firstName || ''} ${lastName || ''}`.trim();

/**
 * a helper function that determines if a notice requires a partial transfer
 * upon affidavit upload instead of a full transfer. A partial transfer occurs
 * when an invoice is partially refunded, a refund less than the full amount is given, and
 * an affidavit is uploaded after notice cancelation.
 */
export const requiresPartialTransfer = (
  invoice: ESnapshot<EInvoice> | undefined,
  notice: ESnapshot<ENotice>,
  enablePartialRefundsV2 = false
) => {
  if (!exists(invoice)) return false;
  const refund_amount = invoice?.data()?.refund_amount || 0;

  const { totalInCents } = getInvoiceAmountsBreakdown(invoice);

  const publisherAmountInCents = enablePartialRefundsV2
    ? totalInCents
    : invoice?.data()?.pricing.publisherAmountInCents || 0;

  const invoiceOrNoticeHasRelevantStatusForPartialTransfer =
    enablePartialRefundsV2
      ? invoice?.data()?.status === InvoiceStatus.partially_refunded.value
      : notice?.data()?.noticeStatus === NoticeStatusType.cancelled.value;

  const relevantTotalAmountInCents = enablePartialRefundsV2
    ? totalInCents
    : publisherAmountInCents;

  return (
    exists(invoice) &&
    invoiceOrNoticeHasRelevantStatusForPartialTransfer &&
    refund_amount < relevantTotalAmountInCents
  );
};

export const shouldPreventLatePrepay = (
  userNotice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  const { deadlines, deadlineOverrides = {} } = newspaper.data();
  if (!deadlines) throw new Error('Newspaper deadlines not found');
  const isPastPublicationDeadline = getIsAfterPublishingDeadline(
    userNotice.data().publicationDates[0].toDate(),
    deadlines,
    deadlineOverrides,
    newspaper.data().iana_timezone,
    userNotice.data(),
    newspaper
  );

  return isPastPublicationDeadline && userNotice.data().requireUpfrontPayment;
};

export const hasAtLeastOneElement = <T>(arr: T[]): arr is [T, ...T[]] => {
  return arr.length >= 1;
};

export const getInvitesAssociatedWithEmail = async (
  ctx: EFirebaseContext,
  email: string,
  organizationId?: string
): Promise<ESnapshotExists<EInvite>[]> => {
  const normalizedEmail = email.toLowerCase();
  const baseQuery = ctx
    .invitesRef()
    .where('email', '==', normalizedEmail)
    .where('status', 'in', OPEN_INVITE_STATUSES);
  let inviteResults: EQuerySnapshot<EInvite>;

  if (organizationId) {
    inviteResults = await baseQuery
      .where('organizationId', '==', organizationId)
      .get();
  } else {
    inviteResults = await baseQuery.get();
  }

  return inviteResults.docs;
};

export const orgsHaveSameParent = (
  orgA: ESnapshot<EOrganization>,
  orgB: ESnapshot<EOrganization>
) => {
  return !!(
    exists(orgA) &&
    exists(orgB) &&
    orgA.data().parent &&
    orgB.data().parent &&
    orgA.data().parent?.id === orgB.data().parent?.id
  );
};
export const getOpenInvitesToRelatedOrgs = async (
  ctx: EFirebaseContext,
  user: ESnapshotExists<EUser>,
  organization: ESnapshot<EOrganization>
): Promise<ESnapshotExists<EInvite>[]> => {
  const pendingInvites: ESnapshotExists<EInvite>[] = [];
  if (!exists(organization)) {
    return pendingInvites;
  }

  const { email } = user.data();
  const invites = await ctx
    .invitesRef()
    .where('email', '==', email)
    .where('status', 'in', OPEN_INVITE_STATUSES)
    .where('organizationId', '!=', organization.id)
    .get();
  for (let i = 0; i < invites.docs.length; i++) {
    const inviteSnap = invites.docs[i];
    const { organizationId } = inviteSnap.data();
    if (!organizationId) {
      throw new Error(
        `In getOpenInvitesToRelatedOrgs expected organizationId to be a non-empty string but received ${
          typeof organizationId === 'string'
            ? 'an empty string'
            : typeof organizationId
        }.`
      );
    }
    // eslint-disable-next-line no-await-in-loop
    const orgSnap = await ctx.organizationsRef().doc(organizationId).get();

    if (exists(orgSnap) && orgsHaveSameParent(orgSnap, organization)) {
      pendingInvites.push(inviteSnap);
    }
  }

  return pendingInvites;
};

/**
 * Filter pending invites of anonymous users
 */
export const filterOpenInvitesForAnonUsers = (
  pendingInvites: ESnapshotExists<EInvite>[]
) => {
  return pendingInvites.filter(
    invite =>
      !invite.data().organizationId &&
      OPEN_INVITE_STATUSES.includes(invite.data().status)
  );
};

/**
 * Converts the given duration (milliseconds) to number of days, hours and seconds.
 */
export const convertMilliseconds = (milliseconds: number) => {
  const total_seconds = Math.floor(milliseconds / 1000);
  const total_minutes = Math.floor(total_seconds / 60);
  const total_hours = Math.floor(total_minutes / 60);
  const days = Math.floor(total_hours / 24);

  const seconds = total_seconds % 60;
  const minutes = total_minutes % 60;
  const hours = total_hours % 24;

  return { d: days, h: hours, m: minutes, s: seconds };
};

export const getOpenInvitesAssociatedWithEmail = async (
  ctx: EFirebaseContext,
  email: string,
  organizationId?: string
): Promise<ESnapshotExists<EInvite>[]> => {
  const baseQuery = ctx
    .invitesRef()
    .where('email', '==', email)
    .where('status', 'in', OPEN_INVITE_STATUSES);
  let inviteResults;

  if (organizationId) {
    inviteResults = await baseQuery
      .where('organizationId', '==', organizationId)
      .get();
  } else {
    inviteResults = await baseQuery.get();
  }

  return inviteResults.docs;
};

/**
 * Identify pattern of the e2e test user email.
 * These are random strings before and after @, like that ${random}@${random}.com; and random will be same at both
 * sides so this helps determine email from e2e.
 * @param {string} email
 * @returns {boolean}
 */
export const isTestUser = (email: string): boolean => {
  const [prefix, suffix] = email.split('@');
  if (`${prefix}.com` === suffix) {
    return true;
  }
  if (`${suffix}` === 'example.org') {
    return true;
  }
  return false;
};

/**
 * Restrict launchDarkly to initialize for e2e users and test environments. But allow for production.
 * @param {string} ENV
 * @param {ESnapshotExists<EUser>} user
 * @returns {boolean}
 */
export const shouldInitializeLD = (
  ENV: string | undefined,
  user: ESnapshotExists<EUser> | undefined = undefined
): boolean => {
  if (ENV === envs.PROD) {
    return true;
  }

  if ((user && isTestUser(user?.data().email)) || ENV === envs.TEST) {
    return false;
  }

  return true;
};

export const isBulkPaymentV2EnabledOnOrganization = (
  newspaper?: ESnapshot<EOrganization>,
  parent?: ESnapshot<EOrganization> | null
): boolean => {
  if (newspaper?.data()?.bulkPaymentEnabled_v2 === false) {
    return false;
  }
  return (
    !!newspaper?.data()?.bulkPaymentEnabled_v2 ||
    !!parent?.data()?.bulkPaymentEnabled_v2
  );
};

export function isDefined<T>(val: T | undefined): val is T {
  return val !== undefined;
}

const MAPBOX_TOKEN =
  'pk.eyJ1IjoibGhlbnRzY2hrZXIiLCJhIjoiY2pxMGI0d2RtMGt3ajQyb2R3NHFvaHBvciJ9.7W5gy4Fva8g5p0lDyDk89g';

export const geocode = async (query: string) => {
  const result = await fetch(
    `https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      query
    )}.json?access_token=${MAPBOX_TOKEN}`
  );
  const data = await result.json();
  if (!data) return null;
  const features = data.features as (undefined | { center: number[] })[];
  if (!features || !features.length) return null;

  const { center } = features[0]!;

  return {
    lat: center[1],
    lng: center[0]
  };
};

export const isAfterLastPublicationDate = (
  notice: ESnapshotExists<AffidavitDisabledNotice>
) => {
  const lastPublicationDate = lastNoticePublicationDate(notice);

  return moment().isAfter(moment(lastPublicationDate));
};

export const encodeRFC3986URIComponent = (str: string) => {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
};

export const isTriggerEventForBuildIntegration = (
  triggerEvent: ESnapshotExists<SyncTriggerEvent>
): boolean => {
  return triggerEvent.data().type.includes('build_ad');
};

export const getFormatOrBuildFormatFromNewspaperAndTrigger = async (
  newspaper: ESnapshotExists<EOrganization>,
  trigger: ESnapshotExists<SyncTriggerEvent>
): Promise<SyncFormat | BuildFormat> => {
  if (isTriggerEventForBuildIntegration(trigger)) {
    const { buildFormat } = await getBuildIntegrationExportSettings(newspaper);
    return buildFormat;
  }

  const { format } = await getXMLSyncExportSettings(newspaper);
  return format;
};

export const delay = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(resolve, ms);
  });

export const isNotNull = <T>(value: T | null): value is T => {
  return value !== null;
};

export const hasDefinedProperty = (obj?: Record<string, unknown>): boolean => {
  return (
    obj !== undefined &&
    Object.values(obj).some(
      param => param !== undefined && param !== null && param !== ''
    )
  );
};

/**
 * This will return due date and time string with timezone of the newspaper
 */
export const getDueDateAndTimeString = (
  invoiceSnap: ESnapshotExists<EInvoice>,
  newspaper: ESnapshot<EOrganization>
) => {
  const { due_date } = invoiceSnap.data();
  if (!exists(newspaper))
    return moment(due_date * 1000).format(`MMMM D, YYYY [at] LT`);
  const { iana_timezone } = newspaper.data();
  const date = moment(due_date * 1000).tz(iana_timezone);
  return date.format(`MMMM D, YYYY [at] LT z`);
};

/**
 * This function takes in a string and returns a consistent hash value.
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 */
export const deterministicStringHash = (input: string) => {
  let hash = 0;
  let i;
  let chr;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
};
export const sanitize = (str: string) =>
  sanitizeFilename(str)
    .replace(/[^a-zA-Z\d:.]/g, '_')
    .toLowerCase();

export const isResponseOrError = <T, E extends Error = Error>(
  possibleResponseOrError: any
): possibleResponseOrError is ResponseOrError<T, E> => {
  if (!possibleResponseOrError) {
    return false;
  }

  const hasResponseProperty = Object.prototype.hasOwnProperty.call(
    possibleResponseOrError,
    'response'
  );
  const hasErrorProperty = Object.prototype.hasOwnProperty.call(
    possibleResponseOrError,
    'error'
  );

  if (!hasResponseProperty || !hasErrorProperty) {
    return false;
  }

  const isResponse = isNotNull(possibleResponseOrError.response);
  const isError = isNotNull(possibleResponseOrError.error);

  if (isResponse && isError) {
    return false;
  }

  return true;
};

type MapFunction<T, U> = (
  item: T,
  index: number,
  array: T[]
) => Promise<ResponseOrError<U>> | Promise<U>;

export const asyncMap = async <T, U>(
  arr: T[],
  mapFn: MapFunction<T, U>
): Promise<ResponseOrError<U[]>> => {
  try {
    const items = await Promise.all(arr.map(mapFn));

    const responseOrErrorItems = items.map(item => {
      if (isResponseOrError(item)) {
        return item;
      }

      return wrapSuccess(item);
    });

    const errors = getErrors(responseOrErrorItems);

    if (errors.length) {
      return wrapError(new Error(errors.map(e => e.message).join(', ')));
    }

    const responses = getResponses(responseOrErrorItems);

    return wrapSuccess(responses);
  } catch (err) {
    return wrapError(err as Error);
  }
};

export const asyncFilter = async <T, U>(
  arr: T[],
  mapFn: MapFunction<T, U | null>
): Promise<ResponseOrError<U[]>> => {
  const { response: itemsOrNulls, error: errorMapping } = await asyncMap(
    arr,
    mapFn
  );

  if (errorMapping) {
    return wrapError(errorMapping);
  }

  const filteredItems = itemsOrNulls.filter(isNotNull);

  return wrapSuccess(filteredItems);
};

export const asyncEvery = async <T>(
  arr: T[],
  mapFn: MapFunction<T, boolean>
) => {
  try {
    const results = await Promise.all(arr.map(mapFn));

    const errors: Error[] = [];

    const booleanResults = results.map<boolean>(item => {
      if (!isResponseOrError(item)) {
        return item;
      }

      if (item.error) {
        errors.push(item.error);
        return false;
      }

      return item.response;
    });

    if (errors.length) {
      return wrapError(new Error(errors.map(e => e.message).join(', ')));
    }

    return wrapSuccess(booleanResults.every(Boolean));
  } catch (err) {
    return wrapError(err as Error);
  }
};
