import React, { useState } from 'react';
import { z } from 'zod';
import { SerializedConversationRequest } from 'lib/types/api';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import classNames from 'classnames';
import { getFirebaseContext } from 'utils/firebase';
import LinkTo from 'components/LinkTo';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { InvoiceStatus } from 'lib/enums';
import { isColumnUser } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { safeGetOrThrow } from 'lib/safeWrappers';

function DetailsRow({
  label,
  value,
  isLastRow
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  isLastRow: boolean;
}) {
  return (
    <div
      className={classNames(`flex w-full px-4 py-3.5 justify-between`, {
        'border-column-gray-50 border-b': !isLastRow
      })}
    >
      <div className="text-column-gray-400">{label}</div>
      <div className="text-column-gray-500" id={`${label}`}>
        {value ?? '--'}
      </div>
    </div>
  );
}

function DetailsTab({
  conversationRequest
}: {
  conversationRequest: z.infer<typeof SerializedConversationRequest>;
}) {
  const { value: notice } = useAsyncEffect({
    fetchData: async () => {
      if (!conversationRequest.noticeId) {
        return null;
      }
      const [noticeError, notice] = await safeGetOrThrow(
        getFirebaseContext().userNoticesRef().doc(conversationRequest.noticeId)
      );
      if (noticeError) {
        return null;
      }
      return notice;
    },
    dependencies: [conversationRequest.noticeId]
  });

  const affidavitUrl = notice?.data()?.affidavitURL;
  const noticeUrl = notice?.data()?.jpgURL;
  const user = useAppSelector(selectUser);

  return (
    <div>
      {noticeUrl && (
        <div className="w-full bg-column-gray-50 p-6">
          <img src={noticeUrl} className="w-full h-80 object-contain" />
        </div>
      )}
      <DetailsRow
        label="Custom ID"
        value={conversationRequest.metadata?.customId}
        isLastRow={false}
      />
      <DetailsRow
        label="Facility ID"
        value={conversationRequest.metadata?.facilityId}
        isLastRow={false}
      />
      {notice && (
        <>
          <DetailsRow
            label="Notice ID"
            value={
              <LinkTo
                href={`/notices/${notice.id}`}
                className="text-column-primary-500 underline"
              >
                <div className="flex items-center gap-2">
                  <span className="text-column-primary-500">{notice?.id}</span>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </div>
              </LinkTo>
            }
            isLastRow={false}
          />
          <DetailsRow
            label="Placement Dates"
            value={notice
              ?.data()
              ?.publicationDates?.map(timestamp =>
                moment(timestamp.toDate()).format('MM/DD/YYYY')
              )
              .join(', ')}
            isLastRow={false}
          />
          <DetailsRow
            label="Affidavit"
            value={
              affidavitUrl ? (
                <div className="flex items-center gap-2 text-column-primary-500 underline">
                  <a
                    href={affidavitUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Affidavit
                  </a>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </div>
              ) : (
                'None'
              )
            }
            isLastRow
          />
          <DetailsRow
            label="Invoice Status"
            value={
              conversationRequest.invoiceStatus
                ? InvoiceStatus.by_value(conversationRequest.invoiceStatus)
                    ?.label
                : 'Not invoiced yet'
            }
            isLastRow
          />
        </>
      )}
      {user && isColumnUser(user) && (
        <DetailsRow
          label="Conversation ID"
          value={
            <div className="flex items-center gap-2 text-column-primary-500 underline">
              <a
                href={`https://app.frontapp.com/open/${conversationRequest.conversationId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {conversationRequest.conversationId}
              </a>
              <ArrowTopRightOnSquareIcon className="w-4 h-4" />
            </div>
          }
          isLastRow
        />
      )}
    </div>
  );
}
export default function ConversationRequestDrawer({
  conversationRequest
}: {
  conversationRequest: z.infer<typeof SerializedConversationRequest>;
}) {
  const tabs = [
    {
      label: 'Details',
      enabled: true,
      id: 'details'
    }
  ];
  const [activeTab, setActiveTab] = useState<TabOption>(tabs[0]);

  return (
    <div>
      <TabGroup
        tabs={tabs}
        activeTab={activeTab}
        onClickTab={tab => setActiveTab(tab)}
        id="conversation-request-drawer-tabs"
      />
      {activeTab.id === 'details' && (
        <DetailsTab conversationRequest={conversationRequest} />
      )}
    </div>
  );
}
