import { useState } from 'react';
import { CompactSwitchControlledCard } from 'lib/components/Card/CompactSwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { TextField } from 'lib/components/TextField';
import { AdRate, ERate } from 'lib/types';
import { JobcaseFee, OrderRate } from 'lib/types/rates';

type JobcaseFeesProps = {
  onChange: (newRate: AdRate | ERate | OrderRate) => void;
  value: OrderRate;
};

const defaultJobcaseFee: JobcaseFee = {
  flatFee: 0,
  upsellDescription: 'Increase the reach of your order online with Jobcase',
  jobcaseAccountId: '',
  enabled: false
};

function JobcaseFeeConfig({ value, onChange }: JobcaseFeesProps) {
  const [jobcaseFee, setJobcaseFee] = useState<JobcaseFee>(
    value.jobcaseFee ?? defaultJobcaseFee
  );

  const handleToggleChange = (newValue: boolean) => {
    const updatedJobcaseFee = { ...jobcaseFee, enabled: newValue };
    setJobcaseFee(updatedJobcaseFee);
    onChange({ ...value, jobcaseFee: updatedJobcaseFee });
  };

  const handleFieldChange = <K extends keyof JobcaseFee>(
    field: K,
    newValue: JobcaseFee[K]
  ) => {
    const updatedJobcaseFee = { ...jobcaseFee, [field]: newValue };
    setJobcaseFee(updatedJobcaseFee);
    onChange({ ...value, jobcaseFee: updatedJobcaseFee });
  };

  return (
    <CompactSwitchControlledCard
      labelProps={{
        label: 'Enable Jobcase?',
        description:
          'Show paid Jobcase ad promotion option in the placement flow.',
        value: jobcaseFee.enabled,
        onChange: handleToggleChange
      }}
      background="white"
    >
      {jobcaseFee.enabled && (
        <>
          <GridInput fullWidth>
            <CurrencyTextField
              id="jobcase-flat-fee-input"
              labelText="Flat fee for Jobcase"
              initialValue={jobcaseFee.flatFee}
              onChange={newValue => handleFieldChange('flatFee', newValue || 0)}
            />
          </GridInput>
          <GridInput fullWidth>
            <TextField
              id="jobcase-upsell-description-input"
              labelText="Placement Flow Upsell Description"
              value={jobcaseFee.upsellDescription}
              onChange={newValue =>
                handleFieldChange(
                  'upsellDescription',
                  newValue || defaultJobcaseFee.upsellDescription
                )
              }
            />
          </GridInput>
          <GridInput fullWidth>
            <TextField
              id="jobcase-account-id-input"
              labelText="Jobcase Account ID"
              value={jobcaseFee.jobcaseAccountId}
              onChange={newValue =>
                handleFieldChange('jobcaseAccountId', newValue || '')
              }
            />
          </GridInput>
        </>
      )}
    </CompactSwitchControlledCard>
  );
}

export default JobcaseFeeConfig;
