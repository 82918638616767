import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import {
  useElements,
  useStripe,
  CardElement,
  Elements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayInvoiceData, InvoicePricingData } from 'lib/types/invoices';
import { apiPost } from 'api/typed';
import { logInfo, logWarn } from 'utils/logger';
import { InvoiceTransactionType } from 'lib/types/invoiceTransaction';
import { ColumnService } from 'lib/services/directory';
import { STRIPE_VARS } from '../../../../../../constants';
import PayInvoiceButton from '../buttons/PayInvoiceButton';
import {
  processStripeAuthorizationWithCard,
  processStripePaymentWithCard
} from '../../helpers/processStripeInvoicePayment';

const styles = () =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    bold: {
      fontWeight: 'bold'
    }
  });

type CheckoutFormProps = {
  payInvoiceData: PayInvoiceData;
  invoicePricingData: InvoicePricingData;
  disableSavedCards: boolean;
  enableAuthCapture: boolean;
  handleSuccessfulPayment: () => void;
  classes: Record<string, string>;
};

function CheckoutForm({
  payInvoiceData,
  invoicePricingData,
  disableSavedCards,
  enableAuthCapture,
  handleSuccessfulPayment,
  classes
}: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [userErr, setUserErr] = useState('');
  const [saveCreditCard, setSaveCreditCard] = useState(
    !disableSavedCards && !enableAuthCapture
  );
  const [idempotencyKey, setIdempotencyKey] = useState('');
  const [requestingSessionToken, setRequestingSessionToken] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);

  const loading = requestingSessionToken || processingPayment;
  const { invoice, customerToSaveCardOnName } = payInvoiceData;
  const { id: invoiceId } = invoice;
  if (!customerToSaveCardOnName && !disableSavedCards) {
    const errMsg =
      'customerToSaveCardOnName is undefined, but saved cards are enabled!';
    logAndCaptureException(ColumnService.PAYMENTS, new Error(errMsg), errMsg, {
      invoiceId
    });
  }

  const requestSessionToken = async () => {
    if (!enableAuthCapture) {
      return;
    }
    setRequestingSessionToken(true);
    try {
      const response = await apiPost('invoice-transactions/create-session', {
        invoiceId,
        sessionType: InvoiceTransactionType.Authorize
      });
      setIdempotencyKey(response.idempotencyKey);
      logInfo('Loaded Stripe payment session');
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Unable to get payment session token for Stripe',
        { invoiceId }
      );
      setUserErr(
        'Unable to start payment session. Try refreshing this page or contact help@column.us for support.'
      );
    }
    setRequestingSessionToken(false);
  };

  useEffect(() => {
    void requestSessionToken();
  }, [enableAuthCapture]);

  /**
   * Currently this function handles both auth/capture as well as the charge flow. We may refactor that when we get to
   * final implementation, but for now this serves as an early window into the auth / capture data flow to work with the
   * new invoice and invoice transaction statuses/types
   */
  const handleStripeSubmission = async () => {
    setProcessingPayment(true);
    setUserErr('');

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return requestSessionToken();
    }
    const stripeElement = elements.getElement(CardElement);
    if (!stripeElement) {
      return requestSessionToken();
    }

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: stripeElement
    });

    if (error) {
      // Previously we were trying to handle individual codes, but there are so many so may as well ignore all validation errors
      const isValidationError = error.type === 'validation_error';

      if (isValidationError) {
        logWarn('Failed to create Stripe payment method', {
          invoiceId,
          stripeErrorCode: error.code
        });
      } else {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          error,
          'Failed to create Stripe payment method',
          {
            invoiceId
          }
        );
      }

      setUserErr(error.message || '');
      setProcessingPayment(false);
      return requestSessionToken();
    }

    try {
      if (enableAuthCapture) {
        await processStripeAuthorizationWithCard(
          paymentMethod.id,
          'card',
          payInvoiceData,
          invoicePricingData,
          idempotencyKey
        );
      } else {
        await processStripePaymentWithCard(
          paymentMethod.id,
          'card',
          payInvoiceData,
          invoicePricingData,
          saveCreditCard
        );
      }
      handleSuccessfulPayment();
    } catch (err: any) {
      const userMessage =
        typeof err.message === 'string'
          ? err.message
          : 'There was a problem processing your payment';
      logAndCaptureMessage(err.message || err);
      setUserErr(userMessage);
      void requestSessionToken();
    } finally {
      setProcessingPayment(false);
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        void handleStripeSubmission();
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          border: '1px solid #DADADA',
          boxShadow: '0px 1px 3px rgba(230, 235, 241, 0.25)',
          borderRadius: '4px',
          color: '#A9B7C4',
          lineHeight: '19px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '90%',
            height: '30px'
          }}
        >
          <div
            style={{
              marginTop: '6px'
            }}
          >
            <CardElement options={{ hidePostalCode: true }} />
          </div>
        </div>
      </div>
      {!disableSavedCards && customerToSaveCardOnName && (
        <div
          className="pt-1 flex items-start"
          onClick={() => setSaveCreditCard(!saveCreditCard)}
        >
          <div>
            <input
              className="form-checkbox focus:ring-blue-400 h-4 w-4 text-blue-500 border-gray-300 rounded-md"
              defaultChecked={saveCreditCard}
              type="checkbox"
            />
          </div>
          <span className="font-medium text-sm text-gray-600 pl-2 pt-0.5">
            Save this card for all payments from {customerToSaveCardOnName}.
          </span>
        </div>
      )}
      <Box mt={1}>
        <PayInvoiceButton
          loading={loading}
          type={'submit'}
          disabled={loading}
          id="pay-invoice-stripe"
        />
      </Box>
      {userErr && (
        <Box mt={1} className={classes.flex}>
          <Typography color="error" variant="caption">
            {userErr}
          </Typography>
        </Box>
      )}
    </form>
  );
}

const stripePromise = loadStripe(STRIPE_VARS.key);
function StripeCardCheckoutForm(props: CheckoutFormProps) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}

export default withStyles(styles)(StripeCardCheckoutForm);
