import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { useState } from 'react';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { PackageService } from 'lib/services/packageService';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import ToastActions from 'redux/toast';

const packageService = new PackageService(getFirebaseContext());

type CreatePackageModalProps = {
  onClose: () => void;
};

export function CreatePackageModal({ onClose }: CreatePackageModalProps) {
  const dispatch = useAppDispatch();
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    <FullScreenModal
      id="create-package-modal"
      submittable={{
        buttonText: 'Create Package',
        onSubmit: async () => {
          if (!activeOrganization || !user) {
            return;
          }

          const [error] = await packageService.create({
            title,
            description,
            authorizedOrganization: activeOrganization.ref,
            createdBy: user.ref,
            publisherSettings: []
          });

          if (error) {
            logAndCaptureException(
              ColumnService.SETTINGS_MANAGEMENT,
              error,
              'Error creating package',
              {
                userId: user.id,
                organizationId: activeOrganization.id
              }
            );
            return dispatch(
              ToastActions.toastError({
                headerText: 'Error creating package',
                bodyText:
                  'An error occurred while creating the package. Please try again.'
              })
            );
          }

          dispatch(
            ToastActions.toastSuccess({
              headerText: 'Package created',
              bodyText: 'The package has been successfully created.'
            })
          );

          onClose();
        },
        disabled: false
      }}
      headerText="Create Package"
      onClose={onClose}
    >
      <CardGridLayout
        header={{
          title: 'Basic Properties',
          description: 'Configure basic properties for this package.'
        }}
      >
        <TextField
          id="title"
          labelText="Title"
          required
          value={title}
          onChange={setTitle}
        />
        <TextField
          id="description"
          labelText="Description"
          value={description}
          onChange={setDescription}
        />
      </CardGridLayout>
    </FullScreenModal>
  );
}
