import { ResponseError, wrapError } from '../types/responses';

export abstract class ColumnError extends Error {
  static isColumnError = (error: Error): error is ColumnError => {
    return !!(error as ColumnError).status;
  };

  constructor(public status: number, message: string, other?: ErrorOptions) {
    super(message, other);
  }
}

export class BadRequestError extends ColumnError {
  static status = 400;

  static isBadRequestError = (error: Error): error is BadRequestError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === BadRequestError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(BadRequestError.status, message, other);
  }
}

export class UnauthorizedError extends ColumnError {
  static status = 401;

  static isUnauthorizedError = (error: Error): error is UnauthorizedError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === UnauthorizedError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(UnauthorizedError.status, message, other);
  }
}

export class ForbiddenError extends ColumnError {
  static status = 403;

  static isForbiddenError = (error: Error): error is ForbiddenError => {
    return (
      ColumnError.isColumnError(error) && error.status === ForbiddenError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(ForbiddenError.status, message, other);
  }
}

export class NotFoundError extends ColumnError {
  static status = 404;

  static isNotFoundError = (error: Error): error is NotFoundError => {
    return (
      ColumnError.isColumnError(error) && error.status === NotFoundError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(NotFoundError.status, message, other);
  }
}

export class TimeoutError extends ColumnError {
  static status = 408;

  static isTimeoutError = (error: Error): error is TimeoutError => {
    return (
      ColumnError.isColumnError(error) && error.status === TimeoutError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(TimeoutError.status, message, other);
  }
}

export class TeapotError extends ColumnError {
  static status = 418;

  static isTeapotError = (error: Error): error is TeapotError => {
    return (
      ColumnError.isColumnError(error) && error.status === TeapotError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(TeapotError.status, message, other);
  }
}

export class TooManyRequestsError extends ColumnError {
  static status = 429;

  static isTooManyRequestsError = (
    error: Error
  ): error is TooManyRequestsError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === TooManyRequestsError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(TooManyRequestsError.status, message, other);
  }
}

export class InternalServerError extends ColumnError {
  static status = 500;

  static isInternalServerError = (
    error: Error
  ): error is InternalServerError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === InternalServerError.status
    );
  };

  constructor(message: string, other?: ErrorOptions) {
    super(InternalServerError.status, message, other);
  }
}

type ColumnErrorConstructor = new (
  message: string,
  other?: ErrorOptions
) => ColumnError;

export const wrapErrorAsColumnError = (
  error: Error,
  ErrorClass: ColumnErrorConstructor = InternalServerError
): ResponseError<ColumnError> => {
  if (ColumnError.isColumnError(error)) {
    return wrapError(error);
  }

  return wrapError(new ErrorClass(error.message, error));
};
