import { getFirebaseContext } from 'utils/firebase';
import {
  PublicationIssueSectionSearchRequest,
  PublicationIssueStatus
} from 'lib/types/publicationIssue';
import { Product } from 'lib/enums';
import { EOrganization, ESnapshot, ESnapshotExists, exists } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { logError } from 'utils/logger';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import {
  PaginationTabs,
  PaginationIssueFilter,
  PAGINATION_TABS_TO_STATUSES,
  getDatesForIssueQueryFromTableFilter
} from '../paginationTableUtils';

async function getPublishingMediumQuery(
  organization: ESnapshot<EOrganization> | null,
  product: Product
): Promise<ResponseOrError<PublishingMedium[] | undefined>> {
  if (!exists(organization)) {
    return wrapSuccess(undefined);
  }
  const organizationModel = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    organization
  );
  const { response: publishingMediums, error } =
    await organizationModel.fetchAvailablePublishingMediums(product);
  if (error) {
    return wrapError(error);
  }

  if (publishingMediums) {
    return wrapSuccess(publishingMediums);
  }

  return wrapSuccess(undefined);
}

/**
 * Gets the relevant set of publisher IDs to load for pagination
 * This logic is more complicated than just showing the availableOrganizations as:
 */
const getSelectedPublisherIds = (
  activeOrganization: ESnapshot<EOrganization> | null,
  availableOrganizations: ESnapshotExists<EOrganization>[],
  showAllOrgsNotices: boolean,
  product: Product
): string[] => {
  if (!exists(activeOrganization)) {
    return [];
  }

  // if not in all orgs just show the current organization
  if (!showAllOrgsNotices) {
    return [activeOrganization.id];
  }

  if (product === Product.Notice) {
    return availableOrganizations.map(org => org.id);
  }

  const activeOrgParent = activeOrganization.data().parent;

  // if the current org has a parent, show all the others with the same parent
  if (activeOrgParent) {
    return availableOrganizations
      .filter(otherOrg => otherOrg.data().parent?.id === activeOrgParent.id)
      .map(org => org.id);
  }

  // we should assume the current org *is* the parent and find all available with matching parent

  const orgsWithActiveAsParent = availableOrganizations.filter(
    org => org.data().parent?.id === activeOrganization.id
  );

  return orgsWithActiveAsParent
    .map(org => org.id)
    .concat(activeOrganization.id);
};

export function useGetPublicationIssueQuery(
  issueFilter: PaginationIssueFilter,
  paginationTableTab: PaginationTabs,
  activeOrganization: ESnapshot<EOrganization> | null,
  showAllOrgsNotices: boolean | undefined,
  availableOrganizations: ESnapshotExists<EOrganization>[],
  product: Product
) {
  const DEFAULT_PUBLICATION_ISSUE_QUERY: PublicationIssueSectionSearchRequest =
    {
      publisherIds: [],
      statuses: [PublicationIssueStatus.PENDING],
      sectionType: product,
      publishingMediums: []
    };

  const activeOrganizationId = activeOrganization?.id;

  const { value } = useAsyncEffect({
    fetchData: async () => {
      const statuses = PAGINATION_TABS_TO_STATUSES[paginationTableTab.id];
      const updatedDatesForQuery =
        getDatesForIssueQueryFromTableFilter(issueFilter);

      const publisherIds = getSelectedPublisherIds(
        activeOrganization,
        availableOrganizations,
        !!showAllOrgsNotices,
        product
      );

      const { response: publishingMediums, error } =
        await getPublishingMediumQuery(activeOrganization, product);
      if (error) {
        logError(error.message, { organizationId: activeOrganizationId });
      }

      const publicationIssueQuery = {
        publisherIds,
        statuses,
        publishingMediums: publishingMediums || [],
        sectionType: product,
        ...updatedDatesForQuery
      };

      return { publishingMediums, publicationIssueQuery };
    },
    dependencies: [
      paginationTableTab.id,
      JSON.stringify(issueFilter),
      activeOrganizationId,
      showAllOrgsNotices,
      availableOrganizations.length,
      product
    ]
  });

  const { publicationIssueQuery, publishingMediums } = value || {};
  return {
    publicationIssueQuery:
      publicationIssueQuery || DEFAULT_PUBLICATION_ISSUE_QUERY,
    publishingMediums: publishingMediums || []
  };
}
