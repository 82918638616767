import axios from 'axios';
import {
  PAYWAY_SUCCESS_CODE,
  PaywayAuthorizeRequest,
  PaywayGatewayTransactionResponse,
  PaywayGatewayTransactionResponseSchema
} from 'lib/types/payway';
import { logAndCaptureException } from 'utils';
import { safeValidateSchema } from 'lib/utils/joi';
import { ColumnService } from 'lib/services/directory';
import { isObject } from 'lib/model/typeCheckers';

export const DEFAULT_PAYMENT_USER_ERROR =
  'The transaction could not be processed. Please try again, or contact help@column.us for further assistance.';

const getPaywayMessageAndCodeFromResponse = (
  data: unknown
): { paywayCode: string; paywayMessage: string } => {
  const paywayCode =
    isObject(data) && 'paywayCode' in data ? data.paywayCode : '';
  const paywayMessage =
    isObject(data) && 'paywayMessage' in data ? data.paywayMessage : '';
  if (typeof paywayCode !== 'string' || typeof paywayMessage !== 'string') {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      new Error(`Unexpected Payway response: ${JSON.stringify(data)}`),
      'Unexpected Payway response'
    );
    return { paywayCode: '', paywayMessage: '' };
  }
  return { paywayCode, paywayMessage };
};

export const authorizePaywayCardAndGetToken = async (
  requestData: PaywayAuthorizeRequest,
  paymentAuthorizationUrl: string
): Promise<string> => {
  const paywayUrl = paymentAuthorizationUrl;
  let data: unknown;
  try {
    data = (await axios.post(`${paywayUrl}/Payment/CreditCard`, requestData))
      .data;
  } catch (error) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      error,
      'Failed to authorize card with Payway due to rejected POST request'
    );
    throw Error(DEFAULT_PAYMENT_USER_ERROR);
  }
  const { paywayCode, paywayMessage } =
    getPaywayMessageAndCodeFromResponse(data);
  if (paywayCode !== PAYWAY_SUCCESS_CODE) {
    const errorMessage = getPaywayErrorMessageFromErrorCode(
      paywayCode,
      paywayMessage
    );
    throw Error(errorMessage);
  }

  const [validationError, validation] =
    safeValidateSchema<PaywayGatewayTransactionResponse>(
      PaywayGatewayTransactionResponseSchema,
      data,
      'Invalid response from Payway authorization'
    );
  if (validationError) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      validationError,
      'Unexpected response from Payway authorization'
    );
    throw Error(DEFAULT_PAYMENT_USER_ERROR);
  }
  if (validation.cardAccount.paywayToken) {
    return validation.cardAccount.paywayToken.toString();
  }
  logAndCaptureException(
    ColumnService.PAYMENTS,
    new Error(
      'Payway token not received from successful authorization attempt'
    ),
    'Failed to authorize card with Payway'
  );
  throw Error(DEFAULT_PAYMENT_USER_ERROR);
};

export const getPaywayErrorMessageFromErrorCode = (
  errorCode: string,
  message: string
) => {
  let userMessage = '';
  switch (errorCode) {
    case '5012':
      userMessage =
        'The transaction was declined by the issuer. Please check that the card details entered above are correct. If the card details are correct, contact your card issuer.';
      break;
    case '5020':
      userMessage =
        'Duplicate transaction request. The payment request has been rejected.';
      break;
    case '5032':
      userMessage =
        'The card issuer has disallowed this type of transaction. Contact your card issuer if you expect this transaction type to be permitted.';
      break;
    case '5039':
      userMessage =
        'The merchant is not authorized to process this card type. Please try another method of payment.';
      break;

    // detail-specific declines
    case '5035':
      userMessage =
        'Invalid card number entered. Please check that the card details entered above are correct.';
      break;
    case '5036':
      userMessage =
        'Invalid address entered. Please check that the card details entered above are correct.';
      break;
    case '5037':
      userMessage =
        'Invalid credit card expiration date entered. Please check that the card details entered above are correct.';
      break;
    case '5038':
      userMessage =
        'Invalid CVV or card security code. Please check that the card details entered above are correct.';
      break;
    case '5040':
      userMessage =
        'Invalid city entered. Please check that the card details entered above are correct.';
      break;
    case '5044':
      userMessage =
        'Invalid state entered. Please check that the card details entered above are correct.';
      break;
    case '5045':
      userMessage =
        'Invalid zip or postal code entered. Please check that the card details entered above are correct.';
      break;
    case '1':
      userMessage = DEFAULT_PAYMENT_USER_ERROR;
      break;
    default:
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error(
          `Unexpected Payway error code received: ${errorCode}. Message: ${message}`
        ),
        'Unexpected Payway error code'
      );
      userMessage = DEFAULT_PAYMENT_USER_ERROR;
      break;
  }
  return userMessage;
};
