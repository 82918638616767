import { guidGenerator, isDefined } from '../helpers';

type VisionModelAuditSuccess = {
  resolved: boolean;
  minimal_whitespace: boolean;
  no_duplicate_ads: boolean;
  no_weird_characters: boolean;
  no_pictures: boolean;
  error_description: string;
};
type VisionModelAuditFailure = {
  resolved: false;
  error_description: string;
};
export type VisionModelResponse =
  | VisionModelAuditSuccess
  | VisionModelAuditFailure;

export type OCRNoticeAuditResponse = {
  order_numbers: string[];
};
export type OCROrderAuditResponse = {
  ads: {
    title: string;
    content: string;
  }[];
};
type OCRAuditResponse = OCRNoticeAuditResponse | OCROrderAuditResponse;
export const isOCRNoticeAuditResponse = (
  response: OCRAuditResponse
): response is OCRNoticeAuditResponse => {
  return isDefined((response as OCRNoticeAuditResponse).order_numbers);
};

type PaginationBlockAudit<T extends OCRAuditResponse> = {
  ocrResult: T;
  blockImageUrl: string;
  visionModelResult: VisionModelResponse;
};
export type NoticePaginationBlockAudit =
  PaginationBlockAudit<OCRNoticeAuditResponse>;
export type OrderPaginationBlockAudit =
  PaginationBlockAudit<OCROrderAuditResponse>;
export type GenericPaginationBlockAudit =
  PaginationBlockAudit<OCRAuditResponse>;
type ResolvedPaginationBlockAudit<T extends OCRAuditResponse> =
  PaginationBlockAudit<T> & {
    visionModelResult: VisionModelAuditSuccess;
  };
export type ResolvedNoticePaginationBlockAudit =
  ResolvedPaginationBlockAudit<OCRNoticeAuditResponse>;
export type ResolvedOrderPaginationBlockAudit =
  ResolvedPaginationBlockAudit<OCROrderAuditResponse>;
export type ResolvedGenericPaginationBlockAudit =
  ResolvedPaginationBlockAudit<OCRAuditResponse>;
type UnresolvedPaginationBlockAudit<T extends OCRAuditResponse> =
  PaginationBlockAudit<T> & {
    visionModelResult: VisionModelAuditFailure;
  };
export type UnresolvedNoticePaginationBlockAudit =
  UnresolvedPaginationBlockAudit<OCRNoticeAuditResponse>;
export type UnresolvedOrderPaginationBlockAudit =
  UnresolvedPaginationBlockAudit<OCROrderAuditResponse>;
export const blockAuditIsResolved = <T extends OCRAuditResponse>(
  blockAudit: PaginationBlockAudit<T>
): blockAudit is ResolvedPaginationBlockAudit<T> => {
  return blockAudit.visionModelResult.resolved;
};

export type NoticePaginationManifestData = {
  internalID: string;
  noticeID: string;
};
export type OrderPaginationManifestData = {
  orderID: string | undefined;
  title: string | undefined;
  content: string | undefined;
};
export type PaginationManifestData =
  | NoticePaginationManifestData
  | OrderPaginationManifestData;
export const isPaginationManifestForNotice = (
  adData: PaginationManifestData
): adData is NoticePaginationManifestData =>
  !!(adData as NoticePaginationManifestData).noticeID;

type CombinedStyleAuditResult<T extends PaginationManifestData> = {
  adsForWrongPublisher: T[];
  adsForWrongDate: T[];
  duplicateAds: T[];
  missingAds: T[];
  matchedAds: T[];
  unknownAds: string[];
};
export type CombinedNoticeStyleAuditResult =
  CombinedStyleAuditResult<NoticePaginationManifestData>;
export type CombinedOrderStyleAuditResult =
  CombinedStyleAuditResult<OrderPaginationManifestData>;

type PaginationManifestCheck<T extends PaginationManifestData> = {
  adsNotInManifest: T[];
  extraAdsInManifest: T[];
};
export type NoticePaginationManifestCheck =
  PaginationManifestCheck<NoticePaginationManifestData>;
export type OrderPaginationManifestCheck =
  PaginationManifestCheck<OrderPaginationManifestData>;

type FullPaginationSubmissionAudit<
  T extends PaginationManifestData,
  K extends OCRAuditResponse
> = {
  auditPassed: boolean;
  combinedStyleAudit: CombinedStyleAuditResult<T>;
  auditSuccesses: ResolvedPaginationBlockAudit<K>[];
  auditFailures: ResolvedPaginationBlockAudit<K>[];
  unresolvedAudits: UnresolvedPaginationBlockAudit<K>[];
  manifestCheck: PaginationManifestCheck<T>;
};
export type FullNoticePaginationSubmissionAudit = FullPaginationSubmissionAudit<
  NoticePaginationManifestData,
  OCRNoticeAuditResponse
>;
export type FullOrderPaginationSubmissionAudit = FullPaginationSubmissionAudit<
  OrderPaginationManifestData,
  OCROrderAuditResponse
>;
export type FullGenericPaginationSubmissionAudit =
  FullPaginationSubmissionAudit<PaginationManifestData, OCRAuditResponse>;

/**
 * When you have two excalidraw elements with the same ID they move together in tandem.
 * This change enables splitting the two
 */
const SPLIT_VALUE = '__';
export const getIdForDuplicatableElement = (elementId: string) => {
  return `${elementId}${SPLIT_VALUE}${guidGenerator().slice(0, 5)}`;
};

export const getElementIdFromDuplicatableElementId = (
  duplicatableElementId: string
) => {
  return duplicatableElementId.split(SPLIT_VALUE)[0];
};
