import api from 'api';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { isWickOrderNumber } from 'lib/integrations/affinityx/helpers';
import {
  AFFINITY_ORDER_NUMBER_INCREMENTORS,
  AffinityXOrderNumber,
  AffinityXOrderNumberIncrementor
} from 'lib/integrations/affinityx/types';
import { ColumnService } from 'lib/services/directory';
import {
  ERef,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists,
  ETemplate,
  ETemplateStyles
} from 'lib/types';
import {
  ManualBuildAdRequestEvent,
  ManualCancelBuildAdRequestEvent
} from 'lib/types/events';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { isUndefined } from 'lodash';
import { logAndCaptureException } from 'utils';
import { ResponseOrError } from '../../../lib/types/responses';

export const getMaxAdHeightForTemplate = async (
  adTemplate: ERef<ETemplate>
): Promise<number | null> => {
  const reqBody: ERequestTypes['templates/styles'] = {
    templateId: adTemplate.id,
    forceRefresh: true
  };

  let styles: ETemplateStyles;
  try {
    const resp: EResponseTypes['templates/styles'] = await api.post(
      'templates/styles',
      reqBody
    );
    if (!resp.success) {
      throw new Error('Unable to get template styles');
    }
    styles = resp.styles;
  } catch (err) {
    logAndCaptureException(
      ColumnService.AFFINITY_X,
      err,
      'Unable to get template styles',
      {
        adTemplateId: adTemplate.id
      }
    );
    return null;
  }

  const {
    pageHeight: pageHeightInPx,
    borderWidth: borderWidthInPx,
    pointsPerInch
  } = styles;
  if (!pointsPerInch || !pageHeightInPx || isUndefined(borderWidthInPx)) {
    return null;
  }

  const maxPageHeightInPx = pageHeightInPx - 2 * borderWidthInPx;
  const maxPageHeightInInches = maxPageHeightInPx / pointsPerInch;
  return maxPageHeightInInches;
};

export enum AffinityXSyncStatus {
  /**
   * Indicates that no sync has yet been attempted with the current order number, no sync is in progress,
   * and the last sync attempt did not fail
   */
  READY_TO_SYNC = 'READY_TO_SYNC',

  /**
   * Indicates that a sync for this notice is already in progress
   */
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',

  /**
   * Indicates that the sync is in progress, but AX is waiting for materials to be uploaded
   */
  SYNC_IN_PROGRESS_AWAITING_MATERIALS = 'SYNC_IN_PROGRESS_AWAITING_MATERIALS',

  /**
   * Indicates that the sync is in progress, and that AX is working on the order in production
   */
  SYNC_IN_PROGRESS_IN_PRODUCTION = 'SYNC_IN_PROGRESS_IN_PRODUCTION',

  /**
   * Indicates that the sync is in progress, and that AX has finished the order and is awaiting review
   */
  SYNC_IN_PROGRESS_AWAITING_REVIEW = 'SYNC_IN_PROGRESS_AWAITING_REVIEW',

  /**
   * Indicates that the last sync was successful, but a new order number is required to sync again
   */
  SYNC_SUCCESSFUL = 'SYNC_SUCCESSFUL',

  /**
   * Indicates that the last sync was cancelled, but a new order number is required to sync again
   */
  SYNC_CANCELLED_EDIT_REQUIRED = 'SYNC_CANCELLED_EDIT_REQUIRED',

  /**
   * Indicates that the last attempt to sync failed at some point after the order was created in AffinityX, so a new order number is required to retry
   */
  SYNC_FAILED_AFTER_ORDER_CREATION = 'SYNC_FAILED_AFTER_ORDER_CREATION',

  /**
   * Indicates that the last attempt to sync failed in Column before the order was created in AffinityX, so it is ready for a retry with the current order number
   */
  SYNC_FAILED_BEFORE_ORDER_CREATION = 'SYNC_FAILED_BEFORE_ORDER_CREATION',

  /**
   * Uknown. Should not happen under normal circumstances. But can happen as we roll out.
   */
  SYNC_UNKNOWN = 'SYNC_UNKNOWN'
}

export const getOrderDetailsFromAXApi = async (
  orderNumber: string
): Promise<
  ResponseOrError<{ status: AffinityXSyncStatus; orderId: string }, Error>
> => {
  const instance = isWickOrderNumber(orderNumber) ? 'wick' : 'col';

  const apiStatusToPill = (status: string) => {
    if (status === 'Awaiting Materials')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_MATERIALS;
    if (status === 'In Production')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS_IN_PRODUCTION;
    if (status === 'In Progress' || status === 'Order Materials Submitted')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS;
    if (status === 'Awaiting Review')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_REVIEW;
    if (status === 'Killed')
      return AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
    if (status === 'Delivery Processed')
      return AffinityXSyncStatus.SYNC_SUCCESSFUL;
    if (status === 'Delivered') return AffinityXSyncStatus.SYNC_SUCCESSFUL;
    return AffinityXSyncStatus.SYNC_UNKNOWN;
  };

  try {
    const apiOrderDetails = await api.get(
      `display-ads/ax/${instance}/orderNumbers/${orderNumber}`
    );
    const status = apiStatusToPill(apiOrderDetails.production_status);
    const orderId = apiOrderDetails.id;
    if (!status) return wrapError(new Error('Unknown status'));
    return wrapSuccess({
      status,
      orderId
    });
  } catch (e) {
    if (e instanceof Error && (e as Error).message.includes('404')) {
      return wrapSuccess({
        status: AffinityXSyncStatus.READY_TO_SYNC,
        orderId: ''
      });
    }
    return wrapError(new Error('Failed to get status from AX Api'));
  }
};

export const getButtonTextFromSyncStatus = (
  syncStatus: AffinityXSyncStatus | null
): string => {
  switch (syncStatus) {
    case AffinityXSyncStatus.READY_TO_SYNC:
    case AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION:
      return 'Start sync';
    case AffinityXSyncStatus.SYNC_IN_PROGRESS:
    case AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_MATERIALS:
    case AffinityXSyncStatus.SYNC_IN_PROGRESS_IN_PRODUCTION:
    case AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_REVIEW:
    case AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION:
    case AffinityXSyncStatus.SYNC_SUCCESSFUL:
      return 'Update order';
    case AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED:
      return 'Sync new order';
    default:
      return 'Start sync';
  }
};

const incrementOrderNumber = (
  previousOrderNumber: AffinityXOrderNumber,
  previousIncrementor: AffinityXOrderNumberIncrementor,
  newIncrementor: AffinityXOrderNumberIncrementor
): AffinityXOrderNumber => {
  if (previousIncrementor === '') {
    return `${previousOrderNumber}${newIncrementor}` as AffinityXOrderNumber;
  }

  return `${previousOrderNumber.slice(
    0,
    -1
  )}${newIncrementor}` as AffinityXOrderNumber;
};

const getNextValidAffinityXOrderNumber = (
  previousOrderNumber: AffinityXOrderNumber
): AffinityXOrderNumber => {
  const qualifiedIncrementor =
    affinityXLibHelpers.getOrderNumberIncrementor(previousOrderNumber);
  if (isUndefined(qualifiedIncrementor)) {
    throw new Error(
      'AffinityX order number does not have qualified incrementor'
    );
  }

  const previousIncrementorIndex =
    AFFINITY_ORDER_NUMBER_INCREMENTORS.indexOf(qualifiedIncrementor);
  const newIncrementor =
    AFFINITY_ORDER_NUMBER_INCREMENTORS[previousIncrementorIndex + 1];
  if (isUndefined(newIncrementor)) {
    throw new Error(
      `Cannot increment AffinityX order number: no more qualified incrementors`
    );
  }

  return incrementOrderNumber(
    previousOrderNumber,
    qualifiedIncrementor,
    newIncrementor
  );
};

export const getValidAffinityXOrderNumber = ({
  customId,
  cancelEvents,
  mostRecentTriggerEvent,
  incrementOrderNumberIfUsed
}: {
  customId: string | null | undefined;
  mostRecentTriggerEvent:
    | ESnapshotExists<ManualBuildAdRequestEvent>
    | undefined;
  cancelEvents: ESnapshotExists<ManualCancelBuildAdRequestEvent>[];
  incrementOrderNumberIfUsed: boolean;
}): AffinityXOrderNumber => {
  if (!affinityXLibHelpers.isAffinityXOrderNumber(customId)) {
    throw new Error('Custom ID invalid for AffinityX sync');
  }

  if (!mostRecentTriggerEvent) {
    return customId;
  }

  const mostRecentTriggerWasCancelled = !!cancelEvents.find(
    cancelEvent =>
      cancelEvent.data().data.initialOrderRequest.id ===
      mostRecentTriggerEvent.id
  );
  if (mostRecentTriggerWasCancelled && incrementOrderNumberIfUsed) {
    return getNextValidAffinityXOrderNumber(
      mostRecentTriggerEvent.data().data.orderNumber
    );
  }

  return mostRecentTriggerEvent.data().data.orderNumber;
};

export const __private = {
  incrementOrderNumber
};
