import { TableLayout } from 'lib/components/TableLayout';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { EOrganization, ESnapshotExists, Package } from 'lib/types';
import { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { CreatePackageModal } from './CreatePackageModal';

type PackagesTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};
export default function PackagesTable({
  activeOrganization
}: PackagesTableProps) {
  const [showCreatePackageModal, setShowCreatePackageModal] = useState(false);

  const packagesQuery = getFirebaseContext()
    .packagesRef()
    .where('authorizedOrganization', '==', activeOrganization.ref);

  const packagesList = useFirestoreQueryListener(
    packagesQuery,
    [activeOrganization.id],
    {
      message: 'Failed to load packages',
      tags: {
        location: 'PackagesTable',
        organizationId: activeOrganization.id ?? ''
      }
    }
  );

  if (showCreatePackageModal) {
    return (
      <CreatePackageModal onClose={() => setShowCreatePackageModal(false)} />
    );
  }

  return (
    // TODO: Hide or support search
    // TODO: Implement edit flow
    <TableLayout<ESnapshotExists<Package>>
      filterable={{
        shouldShowTableItem: () => true
      }}
      creatable={{
        onCreate: () => setShowCreatePackageModal(true),
        createButtonText: 'Add Package'
      }}
      header={{
        subtitle: `Configure the packages available to your customers.`,
        title: 'Packages'
      }}
      columns={['Package Name']}
      renderRow={packageSnap => (
        <>
          <td>{packageSnap.data().title}</td>
        </>
      )}
      data={packagesList?.docs || []}
      loading={!packagesList}
      id="packages-settings"
    />
  );
}
