/* eslint-disable no-use-before-define */
import { FirebaseTimestamp, ESnapshotExists, EInvoiceLineItem } from '.';
import { ERef } from './firebase';
import { EUser } from './user';
import { EOrganization } from './organization';
import { EAddress } from './address';
import { Collections, ELAVON, PAYWAY, PaymentGateway } from '../constants';
import { WithRequiredFields } from './utilityTypes';
import { EnumOutputItem } from './enums';
import { CurrencyItemType } from '../enums/Currency';
import { UserModel } from '../model/objects/userModel';
import { InvoiceModel } from '../model/objects/invoiceModel';
import { SerializedModel } from '../model/types';
import { Order } from './order';
import { Product } from '../enums';
import { PaperCheck } from './paperCheck';
import { NoticeFeeSplitResults } from './feeSplit';

export const MAX_MEMO_LENGTH = 500;

export enum InvoiceType {
  BULK = 'bulk',
  PUBLIC_NOTICE = 'public_notice',
  ORDER = 'order'
}

export type InvoicePricingData = {
  currency: EnumOutputItem<CurrencyItemType>;
  processingFee: number;
  taxFee: number;
  subtotal: number;
  appliedBalance: number;
  netTotal: number;
  totalAmount: number;
  discountTotalInCents?: number;
  inAppLineItems: EInvoiceLineItem[];
  stripeCustomerId?: string;
  surchargeInCents?: number;
};

export type BasePayInvoiceData = {
  type: InvoiceType;
  billingName: string | undefined;
  gateway: string;
  invoicePricingData: InvoicePricingData;
  customerToSaveCardOnName: string | undefined;
  surchargeWarning?: string;
};

export type PayNoticeInvoiceData = BasePayInvoiceData & {
  type: InvoiceType.PUBLIC_NOTICE;
  advertiser: UserModel;
  invoice: InvoiceModel<PublicNoticeInvoice>;
  invoiceRecipient: EInvoiceRecipient | undefined;
  preventLatePayment: boolean;
  requireUpfrontPayment: boolean;
  bulkInvoiceHostedUrl: string | undefined;
  paymentAuthorizationUrl: string | undefined;
};

export type PayOrderInvoiceData = BasePayInvoiceData & {
  type: InvoiceType.ORDER;
  advertiser: UserModel | undefined;
  customerEmail: string;
  invoice: InvoiceModel<OrderInvoice>;
  orderVersion: number;
  product: Product;
  coupons: string[];
};

export type PayInvoiceData = PayNoticeInvoiceData | PayOrderInvoiceData;

export const isPayOrderInvoiceData = (
  payInvoiceData: PayInvoiceData
): payInvoiceData is PayOrderInvoiceData => {
  return payInvoiceData.type === InvoiceType.ORDER;
};

export type LoadPayOrderInvoiceResponse = Omit<
  PayOrderInvoiceData,
  'invoice' | 'advertiser'
> & {
  serializedInvoice: SerializedModel<typeof Collections.invoices>;
  serializedAdvertiser?: SerializedModel<typeof Collections.users>;
};

export type LoadPayNoticeInvoiceResponse = Omit<
  PayNoticeInvoiceData,
  'invoice' | 'advertiser'
> & {
  serializedInvoice: SerializedModel<typeof Collections.invoices>;
  serializedAdvertiser: SerializedModel<typeof Collections.users>;
};

export type LoadPayInvoiceResponse =
  | LoadPayNoticeInvoiceResponse
  | LoadPayOrderInvoiceResponse;

export type LineItem = {
  /** The amount in cents */
  amount: number;

  /** Publishing date in newspaper timezone */
  date: Date | FirebaseTimestamp;

  description?: string;
  singleNoticeId?: string; // in case of bulk invoice
  refund?: boolean; // in case of bulk invoice or partial refund

  /**
   * The price per unit (in cents) and quantity of a line item, such that:
   * `quantity * price = amount`
   */
  unitPricing?: {
    quantity: number;
    price: number;
  };

  /**
   * Connects the line item to the stripe line item on the stripe invoice
   */
  stripeLineItemId?: string;

  /**
   * Amount of all discounts applied to this line item in cents
   * This discount has already been removed from the amount field
   */
  discountAmountInCents?: number;

  /**
   * See LineItemType
   */
  type: number | null | undefined;
};

// TODO: Consolidate this type with LineItem
export type EInAppLineItem = {
  amount: number;
  date: FirebaseTimestamp;
  description: string;
  type: number | null | undefined;

  /**
   * The price per unit (in cents) and quantity of a line item, such that:
   * `quantity * price = amount`
   */
  unitPricing?: {
    quantity: number;
    price: number;
  };
};

export type PaymentMethods =
  | 'card'
  | 'ach'
  | 'check'

  // Invoice is $0 (or close enough) that stripe marks it paid automatically
  | 'free'

  // Invoice was paid using a balance on their Stripe customer
  | 'applied-balance'

  // Invoice was paid using a credit note
  | 'credit-note'

  // Invoice status was set to paid as part of a bulk invoice payment
  | 'bulk-invoice'

  // Invoice was outside of column, so we assume paid
  | 'ioc'

  // Invoice was marked as paid by support
  | 'support'

  /**
   * Invoice was marked as being paid outside of Stripe and is not Elavon or Payway (expected 'card' for those)
   * 'ioc' or 'support' values are prefered if applicable as they are more specific
   * Using 'paid-to-publisher' instead of 'mark-as-paid' verbiage, though they imply a similar outcome
   */
  | 'paid-to-publisher'
  | 'unknown';

export type EInvoiceRecipientName = {
  /**
   * @deprecated firstName
   * in favor of unified field 'name'
   */
  firstName?: string;
  /**
   * @deprecated lastName
   * in favor of unified field 'name'
   */
  lastName?: string;
  /**
   * @deprecated organizationName
   * in favor of unified field 'name'
   */
  organizationName?: string;
  name?: string;
};

export type EInvoiceRecipientEmail = EInvoiceRecipientName & {
  type: 'email';
  email: string;
};

export type EInvoiceRecipientMail = EInvoiceRecipientName & {
  type: 'mail';
  address: EAddress;
};

export type InvoicePricingObject = {
  /**
   * The sum of the subtotal and the tax amount
   * Not used for Order invoices
   */
  publisherAmountInCents: number;

  /**
   * The monetary amount calculated by (1) multiplying each taxable line item by the tax percentage expressed as a decimal,
   * (2) rounding the product of each such calculation to the nearest cent, then (3) summing the rounded results (see calculateTaxFromLineItems)
   */
  taxesInCents: number;

  /**
   * The monetary amount, rounded to the nearest cent, calculated as the subtotal multiplied by the convenience fee percentage,
   * expressed as a decimal, plus a flat fee, if applicable, and capped at the specified fee cap, if applicable
   */
  convenienceFeeInCents: number;

  /**
   * The sum of all invoice line-item amounts, including any additional fees collected by the publisher (excluding sales tax!)
   */
  subtotalInCents: number;

  /* This is the total price of the invoice in cents,
   * i.e. the price that includes both the subtotal
   * that publishers retain plus any fees that Column retains
   * or invoices back from the publisher
   */
  totalInCents: number;

  /**
   * The total discount amount for an invoice in cents
   * This amount has already been removed from the invoice total
   */
  totalDiscountInCents?: number;

  /**
   * This is the Column fee for managing affidavit reconciliation for the notice of this invoice
   */
  affidavitFeeInCents?: number;

  /**
   * the total price of the invoice minus any applied balance (in cents)
   */
  netTotalInCents?: number;

  /**
   * @property publisherFeeSplits
   * is used for tracking feeSplits on public notices (until notices are subsumed into the orders concept)
   */
  publisherFeeSplits?: NoticeFeeSplitResults;
};

export type EInvoiceRecipient = EInvoiceRecipientMail | EInvoiceRecipientEmail;

export type BaseInvoice = {
  /**
   * Invoice from Stripe in in_* format
   */
  stripeInvoiceId: string;

  /**
   * Customer id from Stripe in cus_* format
   * Should be defined on all invoices after Nov 1, 2024
   */
  stripeCustomerId?: string;

  pricing: InvoicePricingObject;

  hosted_invoice_url?: string;
  invoice_pdf?: string;
  normalInvoice?: string;

  /*
   * The path to the receipt PDF. This is either a Stripe URL
   * or Firebase storage path
   */
  receipt_pdf?: string;

  advertiser?: ERef<EUser>;

  /**
   * TODO: Is this different than pricing.inAppLineItems?
   */
  inAppLineItems: LineItem[];

  /**
   * Tax for rate for an invoice
   * This is a newspaper-dependent setting, so this top-level setting is not applicable
   * for orders where one invoice is created for multiple newspapers
   */
  inAppTaxPct?: number;
  created: FirebaseTimestamp | Date;

  due_date: number;

  /**
   * Memo displayed on Pay Invoice page, not sent to Stripe
   * MAX_MEMO_LENGTH is enforced in the UI
   */
  customMemo?: string;
  invoice_number: string;

  status: number;

  convenienceFeeCap?: number;
  disableACH?: boolean;

  /** @deprecated */
  disableMinimumConvenieceFee?: boolean;

  amount_paid?: number;

  /**
   * Actually used as a 'paid_at' field and has compensation implications! 🙀
   */
  finalized_at?: FirebaseTimestamp | Date;

  refunded_at?: FirebaseTimestamp | Date;
  refund_id?: string;

  /**
   * When true the payment for this invoice was handled outside Stripe. For
   * example the publisher may have received a payment directly, or the
   * payment may have been handled by a non-Stripe gateway like Payway.
   * NOTE: This property informs `paymentMethod`
   */
  paid_outside_stripe?: boolean;

  refund_amount?: number;
  manual_refund?: boolean;

  /**
   * @deprecated
   * Use type BulkInvoice instead
   */
  isBulkInvoice_v2?: boolean;
  isWithinBulkInvoice?: boolean;

  /**
   * @deprecated
   * Use type BulkInvoice instead
   *
   * The relevant date field for whether or not an invoice is included in
   * a bulk invoicing run is either:
   * 1) the created field on the invoice
   * 2) the final publication date on the notice
   * if bulkInvoiceByPubDate is true, then we use the final publication date on the notice
   */
  bulkInvoiceByPubDate?: boolean;

  /**
   * The advertiser's organization.
   */
  advertiserOrganization?: ERef<EOrganization>;

  currency?: string;
  lastReminded?: FirebaseTimestamp;

  /**
   * Column Support has marked this invoice as paid
   * NOTE: This property informs `paymentMethod`
   */
  payBySupport?: boolean;

  /**
   * The publisher is responsible for billing the advertiser directly
   * We assume the invoice is paid in this case
   * NOTE: This property informs `paymentMethod`
   */
  invoiceOutsideColumn?: boolean;

  /*
   * Optional details added when the publisher manually processes invoice payment
   * via check, cash, etc.
   */
  manualPaymentDetails?: {
    note: string;
    user: ERef<EUser>;
  };

  /*
   * Log the payment method if known
   */
  paymentMethod?: PaymentMethods;

  /**
   * how much balance (in cents) is applied to an invoice. If no applied balance, this is 0.
   */
  appliedBalance?: number;

  /**
   * stores payment gateway used for invoice
   */
  paymentGateway?: PaymentGateway;

  customer_email?: string;
  void?: boolean;
  voided_at?: FirebaseTimestamp | Date;

  /**
   * Optional fields used to compose the EPaywayInvoice type
   */

  /**
   * @deprecated
   * Use invoiceTransaction.paymentMethodToken on the charge transaction instead
   */
  paywayToken?: string;

  /**
   * @deprecated
   * Use invoiceTransaction.gatewayTransactionId on the charge transaction instead
   */
  paymentName?: string;

  /**
   * @deprecated
   * Use invoiceTransaction.gatewayTransactionId on the refund transaction instead
   */
  paywayRefundToken?: string;

  /**
   * @deprecated
   * Use invoiceTransaction.gatewayTransactionId on the refund transaction instead
   */
  paywayRefundName?: string;

  /**
   * @deprecated
   * Use invoiceTransaction.gatewayTransactionDate on the refund transaction instead
   */
  paywayRefundDate?: FirebaseTimestamp;

  /**
   * Optional fields used to compose the MNGPaidInvoice
   */
  adbaseTransactionNumber?: string;
  paidDateOnSync?: string;

  /**
   * This field is set when the individual invoice was marked as paid as a result of paying the bulk invoice containing it.
   * This is required to not add a negative line item on the upcoming bulk invoice
   * NOTE: This property informs `paymentMethod`
   */
  paidByBulkInvoice?: boolean;

  // TODO(goodpaul): Make this required after db migration
  invoiceType?: InvoiceType;
  surchargeInCents?: number;

  /**
   * References to paper checks that have been processed for this invoice
   */
  paperChecks?: ERef<PaperCheck>[];
};

export type BulkInvoice = BaseInvoice & {
  advertiser: ERef<EUser>;
  bulkInvoiceByPubDate?: boolean;
  inAppTaxPct: number;
  invoiceType: InvoiceType.BULK;
  isBulkInvoice_v2: true;
  /**
   * The relevant date field for whether or not an invoice is included in
   * a bulk invoicing run is either:
   * 1) the created field on the invoice
   * 2) the final publication date on the notice
   * if bulkInvoiceByPubDate is true, then we use the final publication date on the notice
   */
  hosted_invoice_url: string;

  /**
   * The publisher's organization
   */
  organization: ERef<EOrganization>;
};

export const isBulkInvoiceData = (
  invoiceData: Invoice
): invoiceData is BulkInvoice => {
  return (
    invoiceData.invoiceType === InvoiceType.BULK ||
    !!invoiceData.isBulkInvoice_v2
  );
};

export type PublicNoticeInvoice = BaseInvoice & {
  advertiser: ERef<EUser>;
  convenienceFeePct: number;
  convenienceFeeFlat?: number;
  inAppTaxPct: number;
  invoiceType?: InvoiceType.PUBLIC_NOTICE;
  hosted_invoice_url: string;
  noticeId: string;

  /**
   * The publisher's organization
   */
  organization: ERef<EOrganization>;
};

export const isPublicNoticeInvoiceData = (
  invoiceData: Invoice
): invoiceData is PublicNoticeInvoice => {
  return (
    invoiceData.invoiceType === InvoiceType.PUBLIC_NOTICE ||
    ('noticeId' in invoiceData &&
      // noticeId === '0' was done for bulk invoices as a workaround for the type
      invoiceData.noticeId !== '0' &&
      invoiceData.noticeId !== '')
  );
};

export type OrderInvoice = BaseInvoice & {
  /**
   * @deprecated
   */
  orderId?: string;
  order: ERef<Order>;

  /**
   * The edit version of the order to which the order invoice belongs
   */
  orderVersion: number;
  invoiceType: InvoiceType.ORDER;
};

export const isOrderInvoiceData = (
  invoiceData: Invoice
): invoiceData is OrderInvoice => {
  return invoiceData.invoiceType === InvoiceType.ORDER && !!invoiceData.order;
};

/**
 * @deprecated
 * Use Invoice instead
 */
export type EInvoice = PublicNoticeInvoice;

/**
 * Discriminated union of all invoice types
 */
export type Invoice = BulkInvoice | PublicNoticeInvoice | OrderInvoice;

export type EPaywayInvoice = WithRequiredFields<
  EInvoice,
  'paywayToken' | 'paymentName'
>;

/**
 * @deprecated
 * Use isPaywayInvoice() instead
 * This function is used in both SCNG and BANG integrations
 * Determines if this invoice was ever processed in Payway (i.e. has a paywayToken).  May not be in a paid state!
 */
export const wasInvoiceProcessedInPayway = (
  invoice: ESnapshotExists<Invoice> | undefined
): invoice is ESnapshotExists<EPaywayInvoice> => {
  return !!(invoice as ESnapshotExists<EPaywayInvoice>)?.data().paywayToken;
};

export const isElavonInvoice = (invoice: ESnapshotExists<Invoice>) => {
  return invoice.data().paymentGateway === ELAVON;
};

export const isPaywayInvoice = (invoice: ESnapshotExists<EInvoice>) => {
  return invoice.data().paymentGateway === PAYWAY;
};

/**
 * Event data used to create notice invoices
 * Invoice creation is pending moving to a serial queue that is
 * initiated by an API endpoint instead of an event
 */
export type EventInvoiceData = {
  /**
   * The Stripe customer id in cus_* format
   */
  customer: string;

  lineItems: LineItem[];
  publisherAmountInCents: number;
  inAppTaxPct: number;
  userNoticeId: string;
  organizationId: string;
  advertiserId: string;

  /** Order number in the publisher's system */
  customId?: string;
  customMemo?: string;
  publisherId: string;
  dueDate?: number;
  invoiceOutsideColumn?: boolean;
  createdBy?: ERef<EUser>;
  suppressNotification?: boolean;
};
