import { useState } from 'react';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { exists } from 'lib/types';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import LoadingState from 'components/LoadingState';
import NoticeRatesTable from './NoticeRatesTable';
import CrossPublisherFees from './crossPublisherFees';
import PackagesTable from './packagesTable/PackagesTable';

const RATES_TABLE_TAB: TabOption = {
  label: 'Rates',
  enabled: true,
  id: 'rates-table-tab'
};

const CROSS_PUBLISHER_FEES_SETTINGS: TabOption = {
  label: 'Cross-Publisher Fees',
  enabled: true,
  id: 'cross-publisher-fees-settings'
};

const PACKAGES: TabOption = {
  label: 'Packages',
  enabled: true,
  id: 'packages-settings'
};

function getRatesTabs(enablePackages: boolean): TabOption[] {
  const RATES_TABS = [RATES_TABLE_TAB, CROSS_PUBLISHER_FEES_SETTINGS];

  if (enablePackages) {
    RATES_TABS.push(PACKAGES);
  }

  return RATES_TABS;
}

export default function RatesSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const enablePackages = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_PACKAGES,
    false
  );
  const ratesTabs = getRatesTabs(enablePackages);
  const [rateSettingsTab, setRateSettingsTab] = useState(RATES_TABLE_TAB);

  if (!exists(activeOrganization)) {
    return <LoadingState />;
  }

  return (
    <SettingsPage>
      <TabGroup
        onClickTab={setRateSettingsTab}
        activeTab={rateSettingsTab}
        tabs={ratesTabs}
        id="rates-settings-tabs"
      />
      {rateSettingsTab.label === RATES_TABLE_TAB.label && (
        <NoticeRatesTable activeOrganization={activeOrganization} />
      )}
      {rateSettingsTab.label === CROSS_PUBLISHER_FEES_SETTINGS.label && (
        <CrossPublisherFees activeOrganization={activeOrganization} />
      )}
      {rateSettingsTab.label === PACKAGES.label && (
        <PackagesTable activeOrganization={activeOrganization} />
      )}
    </SettingsPage>
  );
}
