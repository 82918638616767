// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENotice, EOrganization, ESnapshotExists, EUser } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import {
  MANUAL_BUILD_AD_REQUEST,
  MANUAL_CANCEL_BUILD_AD_REQUEST,
  ManualBuildAdRequestEvent,
  ManualCancelBuildAdRequestEvent
} from 'lib/types/events';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { isNoticeAfterPublicationDeadline } from 'lib/utils/deadlines';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { AffinityXOrderNumber } from 'lib/integrations/affinityx/types';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import { useFetchAffinityXOrderNumber } from 'hooks/useFetchAffinityXOrderNumber';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import { NOTICE_ROUTES } from 'router/routes';
import { CONFIRM_AD } from 'routes/placeScroll/helpers/calculatePlacementSteps';
import { CopyText } from 'lib/components/CopyText';
import AffinityXSyncButton from './AffinityXSyncButton';
import AffinityXSyncInputs from './AffinityXSyncInputs';
import AffinityXSyncHelpText from './AffinityXSyncHelpText';
import {
  AffinityXSyncStatus,
  getValidAffinityXOrderNumber,
  getMaxAdHeightForTemplate,
  getOrderDetailsFromAXApi
} from './helpers';
import AffinityXSyncStatusPill from './AffinityXSyncStatusPill';

type AffinityXSyncPanelProps = {
  noticeSnap: ESnapshotExists<ENotice>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  requestingUser: ESnapshotExists<EUser>;
};

function AffinityXSyncPanel({
  noticeSnap,
  newspaperSnap,
  requestingUser
}: AffinityXSyncPanelProps) {
  const navigate = useNavigate();

  const [numColumns, setNumColumns] = useState<number | null>(null);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [approxHeight, setApproxHeight] = useState<number | null>(null);
  const [heightIsValid, setHeightIsValid] = useState(true);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [syncStatus, setSyncStatus] = useState<AffinityXSyncStatus | null>(
    null
  );
  const [affinityXOrderNumber, setAffinityXOrderNumber] =
    useState<AffinityXOrderNumber | null>(null);
  const [orderIdInAffinityX, setOrderIdInAffinityX] = useState<number | null>(
    null
  );
  const [noticeIsAfterDeadline, setNoticeIsAfterDeadline] = useState(true);

  const affinityBuildEventsQueryRef =
    affinityXLibHelpers.getLatestAffinityXEventsQuery<ManualBuildAdRequestEvent>(
      getFirebaseContext(),
      { notice: noticeSnap.ref, type: MANUAL_BUILD_AD_REQUEST }
    );
  const affinityBuildEventsQuerySnap =
    useFirestoreQueryListener<ManualBuildAdRequestEvent>(
      affinityBuildEventsQueryRef,
      [noticeSnap.id]
    );
  // The query to get the trigger events calls a descending sort on `createdAt`
  const mostRecentTriggerEvent = affinityBuildEventsQuerySnap?.docs[0];

  const affinityCancelEventsQueryRef =
    affinityXLibHelpers.getLatestAffinityXEventsQuery<ManualCancelBuildAdRequestEvent>(
      getFirebaseContext(),
      { notice: noticeSnap.ref, type: MANUAL_CANCEL_BUILD_AD_REQUEST }
    );
  const affinityCancelEventsQuerySnap =
    useFirestoreQueryListener<ManualCancelBuildAdRequestEvent>(
      affinityCancelEventsQueryRef,
      [noticeSnap.id]
    );

  useEffect(() => {
    const determineWhetherAfterDeadline = async () => {
      const afterDeadline = await isNoticeAfterPublicationDeadline(noticeSnap);
      setNoticeIsAfterDeadline(afterDeadline);
    };

    void determineWhetherAfterDeadline();
  }, [noticeSnap.id]);

  useFetchAffinityXOrderNumber(
    noticeSnap,
    mostRecentTriggerEvent,
    affinityCancelEventsQuerySnap,
    setAffinityXOrderNumber
  );

  const { modularSizes } = usePublisherModularSizes(newspaperSnap.ref ?? null);
  useEffect(() => {
    const setInitialInputValues = async () => {
      const maxHeightForTemplate = await getMaxAdHeightForTemplate(
        noticeSnap.data().adTemplate
      );
      setMaxHeight(maxHeightForTemplate);

      if (!mostRecentTriggerEvent) {
        setApproxHeight(maxHeightForTemplate);

        // Use modular size data to populate the columns and height inputs only when no previous sync event exists
        // This leaves the possibility for a rep to override the modular size data if needed
        // The most recent trigger event will be used to populate the columns and height inputs if it exists (see below)
        if (modularSizes.length) {
          const { height: displayParamsHeight, columns: displayParamsColumns } =
            noticeSnap.data().displayParams || {};
          setApproxHeight(
            Number(displayParamsHeight?.toFixed(1)) || maxHeightForTemplate
          );
          setNumColumns(displayParamsColumns || 1);
        }

        return;
      }

      const {
        numberOfColumns: numColumnsFromTriggerEvt,
        approxHeightInInches: approxHeightFromTriggerEvt
      } = mostRecentTriggerEvent.data().data;

      // Allow the change coming from the sync input component otherwise use data from the most recent trigger event
      setNumColumns(numColumns || numColumnsFromTriggerEvt);
      setApproxHeight(approxHeightFromTriggerEvt || maxHeightForTemplate);
    };

    void setInitialInputValues();
  }, [
    mostRecentTriggerEvent?.id,
    noticeSnap.data().adTemplate.id,
    noticeSnap.data().displayParams?.height,
    noticeSnap.data().displayParams?.columns
  ]);

  useEffect(() => {
    const fetchSyncStatusFromAPI = async () => {
      if (!affinityXOrderNumber) {
        setSyncStatus(AffinityXSyncStatus.READY_TO_SYNC);
        return;
      }
      const [statusError, orderDetails] = await getOrderDetailsFromAXApi(
        affinityXOrderNumber
      );
      if (statusError) {
        logAndCaptureException(
          ColumnService.AFFINITY_X,
          statusError,
          'Error refreshing AffinityX sync status',
          {
            noticeId: noticeSnap.id
          }
        );
        setSyncStatus(null);
        return;
      }
      setSyncStatus(orderDetails.status);
      setOrderIdInAffinityX(Number(orderDetails.orderId));
    };
    void fetchSyncStatusFromAPI();
  }, [affinityXOrderNumber]);

  const incrementOrderNumber = () => {
    try {
      const incrementedOrderNumber = getValidAffinityXOrderNumber({
        customId: affinityXOrderNumber,
        mostRecentTriggerEvent,
        cancelEvents: affinityCancelEventsQuerySnap?.docs ?? [],
        incrementOrderNumberIfUsed: true
      });
      setAffinityXOrderNumber(incrementedOrderNumber);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFINITY_X,
        err,
        'Error incrementing AffinityX order number',
        {
          noticeId: noticeSnap.id
        }
      );
    }
  };

  return (
    <div className="mt-8">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold text-column-gray-700">
          Sync to AffinityX
        </p>
        <AffinityXSyncStatusPill syncStatus={syncStatus} />
      </div>
      {syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (
        <div className="mt-2">
          <Alert
            id="affinity-x-success-edit-alert"
            icon={<ExclamationCircleIcon className="h-5 w-5" />}
            description={
              <p>
                Notice is ready for review,{' '}
                <span
                  className="font-bold underline cursor-pointer"
                  onClick={() => {
                    const url = getDynamicRoute(
                      NOTICE_ROUTES.PLACE,
                      { id: noticeSnap.id },
                      { step: CONFIRM_AD }
                    );
                    navigate(url);
                  }}
                >
                  click here
                </span>{' '}
                or use the edit button to view it.
              </p>
            }
          />
        </div>
      )}
      <div
        id="affinity-x-sync-panel"
        className="bg-primary-25 rounded-md border border-column-gray-150 p-4 my-4"
      >
        {affinityXOrderNumber && (
          <section className="bg-primary-25 mb-3">
            <div id="ax-order-number" className="text-column-gray-400">
              <CopyText copyText={affinityXOrderNumber || ''}>
                AffinityX Order Number:&nbsp;
                <span
                  className={`${
                    syncStatus ===
                    AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED
                      ? 'line-through'
                      : ''
                  }`}
                >
                  {affinityXOrderNumber}
                </span>
              </CopyText>
            </div>
          </section>
        )}
        <AffinityXSyncInputs
          numColumns={numColumns}
          setNumColumns={setNumColumns}
          pageCount={pageCount}
          setPageCount={setPageCount}
          approxHeight={approxHeight}
          maxHeight={maxHeight}
          setApproxHeight={setApproxHeight}
          setHeightIsValid={setHeightIsValid}
          newspaperSnap={newspaperSnap}
        />
        <AffinityXSyncButton
          heightIsValid={heightIsValid}
          noticeSnap={noticeSnap}
          mostRecentTriggerEvent={mostRecentTriggerEvent}
          publicationSnap={newspaperSnap}
          syncStatus={syncStatus}
          noticeIsAfterDeadline={noticeIsAfterDeadline}
          setSyncStatus={setSyncStatus}
          orderNumber={affinityXOrderNumber}
          orderIdInAffinityX={orderIdInAffinityX}
          reqData={{
            numberOfColumns: numColumns || 0,
            requestingUserId: requestingUser.id,
            approxHeightInInches: approxHeight || 0,
            pageCount: pageCount || 1
          }}
        />
        <AffinityXSyncHelpText
          noticeSnap={noticeSnap}
          syncStatus={syncStatus}
          setSyncStatus={setSyncStatus}
          noticeIsAfterDeadline={noticeIsAfterDeadline}
          orderNumber={affinityXOrderNumber}
          mostRecentTriggerEvent={mostRecentTriggerEvent}
          incrementOrderNumber={incrementOrderNumber}
        />
      </div>
    </div>
  );
}

export default AffinityXSyncPanel;
