import React from 'react';
import { SerializedConversationRequest } from 'lib/types/api';
import moment from 'moment';
import { z } from 'zod';
import { InvoiceStatus } from 'lib/enums';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const MAX_FILENAME_LENGTH = 20;
const extractNameFromUrl = (url: string) => {
  const fileName = url.split('/').pop();
  const fileNameWithoutExtension = fileName?.split('.').slice(0, -1).join('.');
  if (!fileNameWithoutExtension) {
    return 'Unknown';
  }
  const extensionWithoutQueryParams = fileName?.split('.').pop()?.split('?')[0];

  if (fileNameWithoutExtension?.length > MAX_FILENAME_LENGTH) {
    return `${fileNameWithoutExtension.slice(
      0,
      MAX_FILENAME_LENGTH
    )}...${extensionWithoutQueryParams}`;
  }
  return `${fileNameWithoutExtension}.${extensionWithoutQueryParams}`;
};

export default function ConversationRequestRow({
  conversationRequest
}: {
  conversationRequest: z.infer<typeof SerializedConversationRequest>;
}) {
  return (
    <>
      <td>
        <div className="flex flex-col gap-1">
          <div className="text-sm text-grey-500">
            {conversationRequest.metadata?.customId}
          </div>
          <div className="text-sm text-grey-400">
            {moment(conversationRequest.createdAt).format('M/DD/YYYY hh:mm A')}{' '}
            - Facility {conversationRequest.metadata?.facilityId}
          </div>
        </div>
      </td>
      <td>
        {conversationRequest.noticeId ? (
          <div className="ml-12">
            <a
              href={`/notices/${conversationRequest.noticeId}`}
              className="text-sm text-column-primary-500 underline flex gap-2 items-center"
            >
              <span>{conversationRequest.noticeId}</span>
              <span>
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </span>
            </a>
          </div>
        ) : (
          <div className="ml-12">
            <div className="text-sm text-columngrey-500">Unplaced</div>
          </div>
        )}
      </td>
      <td>
        <div className="ml-12">
          {conversationRequest.invoiceStatus
            ? InvoiceStatus.by_value(conversationRequest.invoiceStatus)?.label
            : 'Not invoiced yet'}
        </div>
      </td>
      <td>
        <div className="ml-12">
          {conversationRequest.affidavitUrl ? (
            <a
              href={conversationRequest.affidavitUrl}
              target="_blank"
              className="text-sm text-column-primary-500"
              rel="noreferrer"
            >
              {extractNameFromUrl(conversationRequest.affidavitUrl)}
            </a>
          ) : (
            <div className="text-sm text-column-grey-500">No affidavit</div>
          )}
        </div>
      </td>
    </>
  );
}
