import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  DocumentArrowUpIcon,
  WrenchIcon,
  DocumentArrowDownIcon
} from '@heroicons/react/24/outline';
import api from 'api';

import {
  ENotice,
  ERef,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists
} from 'lib/types';
import { ManualBuildAdRequestEvent } from 'lib/types/events';
import React, { useState } from 'react';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { AffinityXSyncStatus, getOrderDetailsFromAXApi } from './helpers';

export const createCancelBuildAdRequestEvent = async ({
  noticeRef,
  initialBuildAdRequestEvent
}: {
  noticeRef: ERef<ENotice>;
  initialBuildAdRequestEvent: ERef<ManualBuildAdRequestEvent>;
}) => {
  const reqBody: ERequestTypes['integrations/async-design/notice/:noticeId/cancel-build-ad-request'] =
    {
      buildAdRequestEventId: initialBuildAdRequestEvent.id
    };

  try {
    const resp: EResponseTypes['integrations/async-design/notice/:noticeId/cancel-build-ad-request'] =
      await api.post(
        `integrations/async-design/notice/${noticeRef.id}/cancel-build-ad-request`,
        reqBody
      );
    if (!resp.success) {
      throw new Error(resp.error);
    }
  } catch (err) {
    logAndCaptureException(
      ColumnService.AFFINITY_X,
      err,
      'Problem creating CancelBuildAdRequestEvent on client',
      {
        noticeId: noticeRef.id,
        buildAdRequestEventId: initialBuildAdRequestEvent.id
      }
    );
  }
};

type AffinityXSyncHelpTextProps = {
  noticeSnap: ESnapshotExists<ENotice>;
  syncStatus: AffinityXSyncStatus | null;
  setSyncStatus: (status: AffinityXSyncStatus) => void;
  noticeIsAfterDeadline: boolean;
  orderNumber: string | null;
  mostRecentTriggerEvent:
    | ESnapshotExists<ManualBuildAdRequestEvent>
    | undefined;
  incrementOrderNumber: () => void;
};

type AffinityXSyncHelpTextInnerProps = {
  icon: React.ReactElement;
  text: React.ReactElement | string;
  messageType: 'info' | 'error';
};

function AffinityXSyncHelpTextInner({
  icon,
  text,
  messageType
}: AffinityXSyncHelpTextInnerProps) {
  const MESSAGE_TYPE_STYLES: Record<
    AffinityXSyncHelpTextInnerProps['messageType'],
    string
  > = {
    info: 'text-column-gray-400',
    error: 'text-red-600'
  };
  const messageTypeStyle = MESSAGE_TYPE_STYLES[messageType];

  return (
    <div
      className={`flex flex-row items-center mt-4 ${messageTypeStyle} text-sm`}
    >
      {icon}
      <div className="pl-1">{text}</div>
    </div>
  );
}

export default function AffinityXSyncHelpText({
  syncStatus,
  setSyncStatus,
  orderNumber
}: AffinityXSyncHelpTextProps) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {syncStatus === AffinityXSyncStatus.READY_TO_SYNC && (
        <AffinityXSyncHelpTextInner
          icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
          text="Set layout and confirm notice to start sync."
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS && (
        <AffinityXSyncHelpTextInner
          icon={
            <ArrowPathIcon
              className={`h-4 w-4 cursor-pointer ${
                isLoading ? 'animate-spin' : ''
              }`}
              onClick={async () => {
                if (!orderNumber) return;
                setIsLoading(true);
                const [error, orderDetails] = await getOrderDetailsFromAXApi(
                  orderNumber
                );
                if (error) return;
                setSyncStatus(orderDetails.status);
                setIsLoading(false);
              }}
            />
          }
          text={<p>Sync in progress.</p>}
          messageType="info"
        />
      )}
      {syncStatus ===
        AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_MATERIALS && (
        <AffinityXSyncHelpTextInner
          icon={<DocumentArrowUpIcon className="h-5 w-5" />}
          text={
            <p className="max-w-80 text-center">Order is awaiting materials</p>
          }
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS_IN_PRODUCTION && (
        <AffinityXSyncHelpTextInner
          icon={<WrenchIcon className="h-5 w-5" />}
          text={<p className="max-w-80 text-center">Order is in production</p>}
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_REVIEW && (
        <AffinityXSyncHelpTextInner
          icon={<DocumentArrowDownIcon className="h-5 w-5" />}
          text={
            <p className="max-w-80 text-center">Order is ready for review</p>
          }
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED && (
        <AffinityXSyncHelpTextInner
          icon={<QuestionMarkCircleIcon className="h-5 w-5" />}
          text={
            <p className="max-w-80 text-center">
              Sync cancelled. You can sync a new order
            </p>
          }
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (
        <AffinityXSyncHelpTextInner
          icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
          text="You can update the order by clicking the sync button."
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION && (
        <AffinityXSyncHelpTextInner
          icon={<ExclamationTriangleIcon className="h-4 w-4" />}
          text="Your sync to AffinityX failed. Please try again."
          messageType="error"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION && (
        <AffinityXSyncHelpTextInner
          icon={
            <ExclamationTriangleIcon className="h-4 w-4 self-start mt-1 -mr-2" />
          }
          text={
            <p className="max-w-80 text-center">
              Sync failed. See the Activity Log for details or cancel the order.
            </p>
          }
          messageType="error"
        />
      )}
    </>
  );
}
