import { isUndefined } from 'lodash';
import { SyncStatusType } from '../../enums';
import { WickPaperAbbreviation } from './wick/constants';
import { awaitAllPromises, getFulfilled } from '../../helpers';
import {
  EFirebaseContext,
  EOrganization,
  ENotice,
  EQuery,
  EQuerySnapshot,
  ERef,
  ESnapshotExists,
  StringValue
} from '../../types';
import {
  MANUAL_BUILD_AD_REQUEST,
  MANUAL_CANCEL_BUILD_AD_REQUEST,
  ManualBuildAdRequestEvent,
  ManualCancelBuildAdRequestEvent,
  SyncEvent,
  Event
} from '../../types/events';
import {
  FileType,
  getFileTypeFromExtensionString,
  getVerifiedExtensionFromFileName
} from '../../types/mime';
import {
  SyncCategoryAndStatusCriteria,
  syncStatusMatchesCriteria
} from '../../utils/events';
import {
  AFFINITY_ORDER_NUMBER_INCREMENTORS,
  AffinityXOrderNumber,
  AffinityXOrderNumberIncrementor,
  AffinityXResponseFileName
} from './types';
import { ColumnPublisherAbbreviation } from './column/constants';
import { CacheManager } from '../caches';
import { AX_CONSTANTS_ID, CACHE_KEYS } from './constants';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import { safeGetOrThrow } from '../../safeWrappers';

export const getLatestAffinityXEventsQuery = <T extends Event>(
  ctx: EFirebaseContext,
  request: Partial<T>
) => {
  let query = ctx.eventsRef<T>() as EQuery<T>;

  for (const [key, value] of Object.entries(request)) {
    query = query.where(key, '==', value);
  }

  // Note: altering this orderBy will have negative downstream
  // effects, as we assume the first event returned from this query
  // is the most recent (see AffinityXSyncPanel/index.ts & AffinityXSyncPanel/helpers.ts)
  return query.orderBy('createdAt', 'desc');
};

export const hasBuildRequestBeenCancelled = async (
  ctx: EFirebaseContext,
  buildRequestEvent: ESnapshotExists<ManualBuildAdRequestEvent>
): Promise<boolean> => {
  const { notice: noticeRef } = buildRequestEvent.data();

  const cancellationRequests =
    await getLatestAffinityXEventsQuery<ManualCancelBuildAdRequestEvent>(ctx, {
      notice: noticeRef,
      type: MANUAL_CANCEL_BUILD_AD_REQUEST
    })
      .where('data.initialOrderRequest', '==', buildRequestEvent.ref)
      // limiting to 1 because we're just checking existence of at least one matching event
      .limit(1)
      .get();

  return !!cancellationRequests.size;
};

const getSyncEventsForTriggerEvent = async (
  ctx: EFirebaseContext,
  triggerEvent: ESnapshotExists<ManualBuildAdRequestEvent>
): Promise<EQuerySnapshot<SyncEvent<ManualBuildAdRequestEvent>>> => {
  const { notice: noticeRef } = triggerEvent.data();

  const syncEventQuerySnap = await ctx
    .eventsRef<SyncEvent<ManualBuildAdRequestEvent>>()
    .where('notice', '==', noticeRef)
    .where('trigger', '==', triggerEvent.ref)
    .get();

  return syncEventQuerySnap;
};

/**
 * The purpose of this function is to determine whether, given a trigger event,
 * an order was _actually created_ in AffinityX. This occurs when we actually send them
 * an XML to create the order, so we should return `false` from this function if the sync
 * failed before any XML was sent.
 *
 * For simplicity, we use the presence of an `awaiting_response` event on a trigger to
 * determine whether or not the XML was sent. Importantly, this function is designed with
 * the Wick integration in mind, so it may need to be updated in future versions of this
 * integration if, for example, the integration uses a method other than `awaiting_response`
 * to receive response files.
 */
export const checkHasSyncEventWithStatus = async (
  ctx: EFirebaseContext,
  triggerEvent: ESnapshotExists<ManualBuildAdRequestEvent>,
  syncCategoryAndStatusCriteria: SyncCategoryAndStatusCriteria
): Promise<boolean> => {
  const noticeSyncEvents = await getSyncEventsForTriggerEvent(
    ctx,
    triggerEvent
  );

  const hasAwaitingResponseEvent = noticeSyncEvents.docs.some(syncEvent => {
    const { syncStatus } = syncEvent.data().data;
    return syncStatusMatchesCriteria(syncStatus, syncCategoryAndStatusCriteria);
  });

  return hasAwaitingResponseEvent;
};

const getIsBuildRequestForOpenAffinityXOrder = async (
  ctx: EFirebaseContext,
  buildRequestEvent: ESnapshotExists<ManualBuildAdRequestEvent>
): Promise<boolean> => {
  const orderHasBeenCreatedInAffinity = await checkHasSyncEventWithStatus(
    ctx,
    buildRequestEvent,
    { categories: [], statuses: [SyncStatusType.awaiting_response] }
  );
  const orderHasBeenCancelledInAffinity = await hasBuildRequestBeenCancelled(
    ctx,
    buildRequestEvent
  );
  return orderHasBeenCreatedInAffinity && !orderHasBeenCancelledInAffinity;
};

export const maybeGetMostRecentBuildRequestEventForNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
): Promise<ESnapshotExists<ManualBuildAdRequestEvent> | undefined> => {
  const buildAdRequestQuery =
    getLatestAffinityXEventsQuery<ManualBuildAdRequestEvent>(ctx, {
      type: MANUAL_BUILD_AD_REQUEST,
      notice: noticeSnap.ref
    });

  const buildAdRequestQuerySnap = await buildAdRequestQuery.get();

  if (buildAdRequestQuerySnap.empty) {
    return undefined;
  }

  return buildAdRequestQuerySnap.docs[0];
};

export const getNoticeHasOpenAffinityXOrder = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const mostRecentBuildRequestEvent =
    await maybeGetMostRecentBuildRequestEventForNotice(ctx, noticeSnap);
  if (!mostRecentBuildRequestEvent) {
    return false;
  }

  return await getIsBuildRequestForOpenAffinityXOrder(
    ctx,
    mostRecentBuildRequestEvent
  );
};

export const getOrderNumberHasAlreadyBeenSentToAffinity = async (
  ctx: EFirebaseContext,
  orderNumber: AffinityXOrderNumber,
  affinityBuildEventsQuerySnap: EQuerySnapshot<ManualBuildAdRequestEvent>
): Promise<boolean> => {
  const triggerEventsWithOrderNumber = affinityBuildEventsQuerySnap.docs.filter(
    triggerEvent => triggerEvent.data().data.orderNumber === orderNumber
  );
  const orderNumberHasAlreadySyncedResults = await awaitAllPromises(
    triggerEventsWithOrderNumber.map(
      async triggerEvent =>
        await checkHasSyncEventWithStatus(ctx, triggerEvent, {
          categories: [],
          statuses: [SyncStatusType.awaiting_response]
        })
    )
  );
  const orderNumberHasAlreadyBeenSyncedToAffinity = getFulfilled(
    orderNumberHasAlreadySyncedResults
  ).some(result => !!result);
  return orderNumberHasAlreadyBeenSyncedToAffinity;
};

/**
 * The character limit for the XML field is 24. We are standardizing AffinityX order number lengths
 * to 9 characters for readability, and conditionally appending a 10th character as an "incrementor" in rare
 * instances where we may need to create multiple orders for the same notice.
 *
 * Note that an extra 3-4 character AffinityX prefix will be prepended to the order number when synced,
 * so even if we alter this formula in the future, the length of the string generated by this function
 * must never exceed 20.
 */
const WICK_ORDER_NUMBER_LENGTH_NO_INCREMENTOR = 9;
const WICK_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR = 10;
// Column order numbers will contain an additional three characters
// Ex. FCCGFH123456 to help differeniate publishers we have
// the parent abbreviation FCC, newspaper abbreviation GFH, and an order number 123456
const COLUMN_ORDER_NUMBER_LENGTH_NO_INCREMENTOR = 12;
const COLUMN_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR = 13;

export const isWickOrderNumber = (noticeCustomId: string) => {
  return Object.values(WickPaperAbbreviation).includes(
    noticeCustomId.slice(0, 3) as WickPaperAbbreviation
  );
};

const getIsExpectedLength = (noticeCustomId: string) => {
  if (isWickOrderNumber(noticeCustomId)) {
    return (
      noticeCustomId.length === WICK_ORDER_NUMBER_LENGTH_NO_INCREMENTOR ||
      noticeCustomId.length === WICK_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR
    );
  }

  return (
    noticeCustomId.length === COLUMN_ORDER_NUMBER_LENGTH_NO_INCREMENTOR ||
    noticeCustomId.length === COLUMN_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR
  );
};

const getStartsWithAcceptedAbbreviation = (noticeCustomId: string) => {
  if (isWickOrderNumber(noticeCustomId)) {
    return Object.values(WickPaperAbbreviation).includes(
      noticeCustomId.slice(0, 3) as WickPaperAbbreviation
    );
  }

  return Object.values(ColumnPublisherAbbreviation).includes(
    noticeCustomId.slice(0, 3) as ColumnPublisherAbbreviation
  );
};

const getNextSixCharsAfterAbbrevAreDigits = (noticeCustomId: string) => {
  if (isWickOrderNumber(noticeCustomId)) {
    return /^\d{6}$/.test(noticeCustomId.slice(3, 9));
  }

  return /^\d{6}$/.test(noticeCustomId.slice(6, 12));
};

const getIncrementor = (orderNumber: AffinityXOrderNumber) => {
  if (isWickOrderNumber(orderNumber)) {
    return orderNumber.length === WICK_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR;
  }

  return orderNumber.length === COLUMN_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR;
};

export const getOrderNumberIncrementor = (
  orderNumber: AffinityXOrderNumber
): AffinityXOrderNumberIncrementor | undefined => {
  const incrementor = getIncrementor(orderNumber)
    ? orderNumber.split('').pop()
    : '';

  if (
    isUndefined(incrementor) ||
    !AFFINITY_ORDER_NUMBER_INCREMENTORS.includes(
      incrementor as AffinityXOrderNumberIncrementor
    )
  ) {
    return undefined;
  }

  return incrementor as AffinityXOrderNumberIncrementor;
};

/**
 * AffinityX order numbers must be unique and include the following, in order:
 * - A stipulated 3-letter abbreviation for the publication
 * - A 6-digit number unique within the publication to the particular notice
 * - Optionally, an incrementor ('A', 'B', 'C', or 'D') used for notices that need multiple order numbers generated
 *
 * TODO: as needed, expand the use of this function beyond Wick
 */
export const isAffinityXOrderNumber = (
  noticeCustomId: string | null | undefined
): noticeCustomId is AffinityXOrderNumber => {
  if (!noticeCustomId) return false;

  const isExpectedLength = getIsExpectedLength(noticeCustomId);
  const startsWithAcceptedAbbreviation =
    getStartsWithAcceptedAbbreviation(noticeCustomId);
  const nextSixCharsAfterAbbrevAreDigits =
    getNextSixCharsAfterAbbrevAreDigits(noticeCustomId);
  const qualifiedIncrementor = getOrderNumberIncrementor(
    noticeCustomId as AffinityXOrderNumber
  );

  return (
    isExpectedLength &&
    startsWithAcceptedAbbreviation &&
    nextSixCharsAfterAbbrevAreDigits &&
    !isUndefined(qualifiedIncrementor)
  );
};

export const getAffinityXBaseUrlForOrder = (orderNumber: string) => {
  if (isWickOrderNumber(orderNumber))
    return 'https://wickcommunicationsportal.affinitydigital.net';
  return 'https://col1portal.affinitydigital.net';
};

/**
 * When generating AffinityX order numbers, we need to also account for custom prefixes and suffixes that Column generates.
 * For example:
 *  COL-001 -> GJDS001
 * As:
 * 1. We need the COL- prefix to manage automatic order recognition in pagination
 * 2. Affinity does not recognize the GJDS prefix, so we need to strip it
 */
export const getCustomIdWithAffinityXPrefix = async (
  noticeSnap: ESnapshotExists<ENotice>,
  fetchedAffinityXConstants: AffinityXConstants,
  newspaperRef: ERef<EOrganization>
) => {
  if (isAffinityXOrderNumber(noticeSnap.data().customId)) {
    return wrapSuccess(noticeSnap.data().customId);
  }

  let customIdWithoutColumnPrefixOrSuffix = noticeSnap.data().customId;
  if (!customIdWithoutColumnPrefixOrSuffix) {
    return wrapSuccess(customIdWithoutColumnPrefixOrSuffix);
  }

  const [newspaperError, newspaper] = await safeGetOrThrow(newspaperRef);
  if (newspaperError) {
    return wrapError(newspaperError);
  }

  const prefix = newspaper?.data()?.orderNumberGeneration?.orderNumPrefix || '';
  const suffix = newspaper?.data()?.orderNumberGeneration?.orderNumSuffix || '';

  if (prefix) {
    customIdWithoutColumnPrefixOrSuffix =
      customIdWithoutColumnPrefixOrSuffix.replace(prefix, '');
  }
  if (suffix) {
    customIdWithoutColumnPrefixOrSuffix =
      customIdWithoutColumnPrefixOrSuffix.replace(suffix, '');
  }
  return wrapSuccess(
    `${fetchedAffinityXConstants.newspaperAbbreviation}${customIdWithoutColumnPrefixOrSuffix}`
  );
};

/**
 * A response file from AffinityX will be a PDF named with the order number
 * (minus the AffinityX prefix for the publisher, e.g. "WCC")
 */
export const isAffinityXResponseFileName = (
  fileName: string
): fileName is AffinityXResponseFileName => {
  const { verifiedExtension, fileNameMinusExtension } =
    getVerifiedExtensionFromFileName(fileName);
  const isPDF =
    getFileTypeFromExtensionString(verifiedExtension || '') === FileType.PDF;
  const isValidAffinityXOrderNumber = isAffinityXOrderNumber(
    fileNameMinusExtension
  );
  return isPDF && isValidAffinityXOrderNumber;
};

export type AffinityXConstants = {
  accountNumber: string;
  publicationCode: string;
  newspaperAbbreviation: string;
};
export const getAffinityXConstantsFromCache = async (
  ctx: EFirebaseContext,
  newspaperRef: ERef<EOrganization>
): Promise<ResponseOrError<AffinityXConstants>> => {
  const cache = new CacheManager<StringValue, StringValue>(
    ctx,
    newspaperRef,
    AX_CONSTANTS_ID
  );

  const accountNumber = await cache.getValueIfExists(CACHE_KEYS.ACCOUNT_NUMBER);

  if (!accountNumber) {
    return wrapError(
      new Error('Newspaper does not have an account number in the cache')
    );
  }

  const publicationCode = await cache.getValueIfExists(
    CACHE_KEYS.PUBLICATION_CODE
  );

  if (!publicationCode) {
    return wrapError(
      new Error('Newspaper does not have a publication code in the cache')
    );
  }

  const newspaperAbbreviation = await cache.getValueIfExists(
    CACHE_KEYS.NEWSPAPER_ABBREVIATION
  );

  if (!newspaperAbbreviation) {
    return wrapError(
      new Error('Newspaper does not have a newspaper abbreviation in the cache')
    );
  }

  return wrapSuccess({ accountNumber, publicationCode, newspaperAbbreviation });
};
