import { z } from 'zod';
import { ENotice, ERef, FirebaseTimestamp } from '.';

export enum BulkUploadStatus {
  NOT_APPLICABLE = 0,
  RECEIVED = 1,
  INITIATED = 2,
  PROCESSED = 3
}

export enum ExpressNoticeStatus {
  NOT_APPLICABLE = 0,
  RECEIVED = 1,
  DRAFTED = 2,
  SENT = 3,
  PLACED = 4
}

export const Late2LienConversationMetadata = z.object({
  partner: z.literal('late2lien'),
  facilityId: z.string(),
  customId: z.string(),
  firstValidPublishingDate: z.string(),
  lastValidPublishingDate: z.string(),
  facilityName: z.string(),
  numberOfPublicationDates: z.number(),
  conversationType: z.string()
});

type Late2LienConversationMetadata = z.infer<
  typeof Late2LienConversationMetadata
>;

export type ExpressEmailConversation = {
  conversationId: string;
  conversationSubject: string;
  conversationCreatedAt: FirebaseTimestamp;
  bulkUploadStatus: BulkUploadStatus;
  expressNoticeStatus: ExpressNoticeStatus;
  notice: ERef<ENotice> | null;

  // TODO: figure out if we want more type specificity on this
  metadata?: Partial<Late2LienConversationMetadata>;
};
