// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from 'react';
import {
  ENotice,
  EOrganization,
  ERef,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists
} from 'lib/types';
import { useLoading } from 'lib/components/hooks/useLoading';
import { Tooltip } from 'lib/components/Tooltip';
import { noticeIsPublished } from 'lib/helpers';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ColumnButton } from 'lib/components/ColumnButton';
import { AffinityXOrderNumber } from 'lib/integrations/affinityx/types';
import { ColumnService } from 'lib/services/directory';
import { getAffinityXBaseUrlForOrder } from 'lib/integrations/affinityx/helpers';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ManualBuildAdRequestEvent } from 'lib/types/events';
import { AffinityXSyncStatus, getButtonTextFromSyncStatus } from './helpers';
import { createCancelBuildAdRequestEvent } from './AffinityXSyncHelpText';

type AffinityXSyncButtonProps = {
  heightIsValid: boolean;
  noticeSnap: ESnapshotExists<ENotice>;
  mostRecentTriggerEvent:
    | ESnapshotExists<ManualBuildAdRequestEvent>
    | undefined;
  publicationSnap: ESnapshotExists<EOrganization>;
  syncStatus: AffinityXSyncStatus | null;
  setSyncStatus: (status: AffinityXSyncStatus) => void;
  noticeIsAfterDeadline: boolean;
  orderNumber: AffinityXOrderNumber | null;
  orderIdInAffinityX: number | null;
  reqData: Omit<
    ERequestTypes['integrations/async-design/notice/:noticeId/create-build-ad-request'],
    'orderNumber'
  >;
};

const createBuildAdRequestEvent = async ({
  noticeRef,
  reqData
}: {
  noticeRef: ERef<ENotice>;
  reqData: ERequestTypes['integrations/async-design/notice/:noticeId/create-build-ad-request'];
}) => {
  try {
    const resp: EResponseTypes['integrations/async-design/notice/:noticeId/create-build-ad-request'] =
      await api.post(
        `integrations/async-design/notice/${noticeRef.id}/create-build-ad-request`,
        reqData
      );
    if (!resp.success) {
      throw new Error(resp.error);
    }
  } catch (err) {
    logAndCaptureException(
      ColumnService.AFFINITY_X,
      err,
      'Problem creating BuildAdRequestEvent on client',
      {
        noticeId: noticeRef.id,
        orderNumber: reqData.orderNumber ?? 'null',
        requestingUserId: reqData.requestingUserId
      }
    );
  }
};

function AffinityXSyncButton({
  heightIsValid,
  noticeSnap,
  mostRecentTriggerEvent,
  publicationSnap,
  syncStatus,
  noticeIsAfterDeadline,
  setSyncStatus,
  orderNumber,
  orderIdInAffinityX,
  reqData
}: AffinityXSyncButtonProps) {
  const dispatch = useAppDispatch();
  const [loadingSyncToAffinity, handleSyncToAffinityWithLoading] = useLoading();
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [modalToShow, setModalToShow] = useState<
    | 'after-deadline-modal'
    | 'already-exists-modal'
    | 'killed-order-modal'
    | null
  >(null);
  const showSyncAfterDeadlineModal = modalToShow === 'after-deadline-modal';
  const showOrderAlreadyExistsModal = modalToShow === 'already-exists-modal';
  const showInactiveOrderModal = modalToShow === 'killed-order-modal';
  const { hasReachedFirstPublication } = noticeIsPublished(
    noticeSnap,
    publicationSnap
  );

  const disableSync =
    hasReachedFirstPublication ||
    noticeSnap.data().isArchived ||
    !reqData.numberOfColumns ||
    !reqData.approxHeightInInches ||
    !reqData.pageCount ||
    !syncStatus ||
    syncStatus === AffinityXSyncStatus.SYNC_UNKNOWN ||
    !heightIsValid;

  const disableViewButton = !orderNumber || !orderIdInAffinityX;
  let baseUrl: string | null = null;
  if (orderNumber) {
    baseUrl = getAffinityXBaseUrlForOrder(orderNumber);
  }

  const handleSyncToAffinity = async (createNew = false) => {
    if (disableSync) {
      return;
    }
    const orderNumberToUse = createNew ? null : orderNumber;

    try {
      await createBuildAdRequestEvent({
        noticeRef: noticeSnap.ref,
        reqData: { ...reqData, orderNumber: orderNumberToUse }
      });
      setSyncStatus(AffinityXSyncStatus.SYNC_IN_PROGRESS);
    } catch (err) {
      dispatch(
        ToastActions.toastError({
          headerText: `Failed to sync to AffinityX`,
          bodyText: `Please try again later. Reach out to support if the issue persists.`
        })
      );
      setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION);
    }
    setModalToShow(null);
  };

  const handleCancelOrder = async () => {
    if (!mostRecentTriggerEvent || !orderNumber) {
      return;
    }

    try {
      await createCancelBuildAdRequestEvent({
        noticeRef: noticeSnap.ref,
        initialBuildAdRequestEvent: mostRecentTriggerEvent.ref
      });
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFINITY_X,
        err,
        'Error creating cancel build ad request event',
        { noticeId: noticeSnap.id }
      );
      dispatch(
        ToastActions.toastError({
          headerText: `Failed to cancel the order`,
          bodyText: `Please try again later. Reach out to support if the issue persists.`
        })
      );
      return;
    }

    setShowCancelOrderModal(false);
    dispatch(
      ToastActions.toastSuccess({
        headerText: `You've successfully cancelled the order`,
        bodyText: `You can sync again using a new order number if needed.`
      })
    );
  };

  const onSyncButtonClick = async () => {
    const activeOrderAlreadyExists =
      orderNumber && syncStatus?.startsWith('SYNC_IN_PROGRESS');
    const killedOrderExists =
      orderNumber &&
      syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
    if (activeOrderAlreadyExists) {
      setModalToShow('already-exists-modal');
      return;
    }
    if (killedOrderExists) {
      setModalToShow('killed-order-modal');
      return;
    }

    if (noticeIsAfterDeadline) {
      setModalToShow('after-deadline-modal');
      return;
    }

    await handleSyncToAffinityWithLoading(handleSyncToAffinity, () =>
      setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION)
    );
  };

  const buttonText = getButtonTextFromSyncStatus(syncStatus);
  const shouldShowRunDateTooltip =
    hasReachedFirstPublication &&
    [
      AffinityXSyncStatus.READY_TO_SYNC,
      AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
    ].some(status => status === syncStatus);

  return (
    <>
      {showOrderAlreadyExistsModal && (
        <CancelOrSubmitModal
          onClose={() => setModalToShow(null)}
          header="Order already exists"
          primaryButtonText="Yes, update order"
          tertiaryButtonText="Back"
          onSubmit={() => {
            if (noticeIsAfterDeadline) {
              setModalToShow('after-deadline-modal');
              return;
            }

            return handleSyncToAffinityWithLoading(
              async () => {
                await handleSyncToAffinity(true);
              },
              () =>
                setSyncStatus(
                  AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
                )
            );
          }}
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            Order {orderNumber} already exists in AffinityX. Do you want to
            update that order with new inputs?
          </div>
        </CancelOrSubmitModal>
      )}
      {showInactiveOrderModal && (
        <CancelOrSubmitModal
          onClose={() => setModalToShow(null)}
          header="Previous order was cancelled"
          primaryButtonText="Yes, generate new order"
          tertiaryButtonText="Back"
          onSubmit={() => {
            if (noticeIsAfterDeadline) {
              setModalToShow('after-deadline-modal');
              return;
            }

            return handleSyncToAffinityWithLoading(
              async () => {
                await handleSyncToAffinity(true);
              },
              () =>
                setSyncStatus(
                  AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
                )
            );
          }}
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            Order {orderNumber} already exists in AffinityX but was killed.
            Syncing will generate a new order. Are you sure you want to sync?
          </div>
        </CancelOrSubmitModal>
      )}
      {showSyncAfterDeadlineModal && (
        <CancelOrSubmitModal
          onClose={() => setModalToShow(null)}
          header="Sync to AffinityX after deadline?"
          primaryButtonText="Sync"
          tertiaryButtonText="Back"
          onSubmit={() =>
            handleSyncToAffinityWithLoading(handleSyncToAffinity, () =>
              setSyncStatus(
                AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
              )
            )
          }
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            The ad deadline has passed for this notice and your newspaper will
            be charged for the AffinityX order even if the designed ad is not
            complete by publication. Alternatively, you can edit the notice so
            that the ad deadline is in the future. Are you sure you would like
            to sync?
          </div>
        </CancelOrSubmitModal>
      )}
      {showCancelOrderModal && (
        <CancelOrSubmitModal
          onClose={() => setShowCancelOrderModal(false)}
          header="Cancel order in AffinityX?"
          primaryButtonText="Cancel"
          destructive
          tertiaryButtonText="Back"
          onSubmit={handleCancelOrder}
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            {noticeIsAfterDeadline &&
              syncStatus?.startsWith('SYNC_IN_PROGRESS') && (
                <p>
                  The ad deadline for this notice has passed. If you cancel this
                  sync to AffinityX, you may not have time to re-sync without
                  editing the notice's publication dates.
                </p>
              )}
            {!noticeIsAfterDeadline &&
              syncStatus?.startsWith('SYNC_IN_PROGRESS') && (
                <p>
                  Upon cancellation you may edit the notice and re-sync to
                  AffinityX.
                </p>
              )}
            {syncStatus ===
              AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION && (
              <p>
                Clicking "Cancel" will delete this order in AffinityX. Are you
                sure you want to cancel?
              </p>
            )}
          </div>
        </CancelOrSubmitModal>
      )}
      <div className="flex flex-row space-x-3">
        <Tooltip
          helpText={
            noticeSnap.data().isArchived
              ? 'You cannot sync to AffinityX because this notice has been archived. Duplicate this notice with future publication dates to place it again.'
              : shouldShowRunDateTooltip
              ? 'You cannot sync to AffinityX because the first publication date has passed for this notice. Please edit the notice to have a future publication date.'
              : ''
          }
        >
          <ColumnButton
            id="affinity-x-sync-button"
            fullWidth={false}
            primary
            buttonText={buttonText}
            size="md"
            disabled={disableSync}
            onClick={onSyncButtonClick}
            loading={loadingSyncToAffinity}
            type="button"
          />
        </Tooltip>
        <ColumnButton
          id="affinity-x-view-button"
          fullWidth={false}
          primary
          buttonText={'View In AffinityX'}
          size="md"
          disabled={disableViewButton}
          onClick={() => {
            const url = `${baseUrl}/portal/details/#orders/${orderIdInAffinityX}`;
            window.open(url, '_blank');
          }}
          loading={loadingSyncToAffinity}
          type="button"
        />
        <ColumnButton
          id="affinity-x-cancel-button"
          fullWidth={false}
          destructive
          buttonText={'Cancel'}
          size="md"
          disabled={disableViewButton}
          onClick={() => {
            setShowCancelOrderModal(true);
          }}
          loading={loadingSyncToAffinity}
          type="button"
        />
      </div>
    </>
  );
}

export default AffinityXSyncButton;
