import React, { useState } from 'react';
import api from 'api';
import { Product } from 'lib/enums/Product';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { wrapSuccess, wrapError } from 'lib/types/responses';
import {
  selectActiveOrganization,
  selectAvailableOrganizations,
  selectShowAllOrgsNotices,
  selectUser
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { useBooleanFlag } from 'utils/flags';
import { Alert } from 'lib/components/Alert';
import { isColumnUser } from 'lib/helpers';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';

import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { publisherReadyToUpload } from 'lib/publishers';
import { useSearchParams } from 'react-router-dom';
import TabGroup from 'lib/components/Tabs';
import { fuzzyStringContains } from 'lib/utils/strings';
import { TableLayout } from 'lib/components/TableLayout';
import { shouldShowDisabledColumnForUser } from 'lib/pagination/helpers';
import BulkDownloadModal from 'routes/notices/table/BulkDownloadModal';
import { Modal } from 'lib/components/Modal';
import { CatUploadingAHamburger } from 'lib/components/gifs';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import {
  SearchablePublicationIssueSectionRecord,
  SearchablePublicationIssueSectionRecordFilter,
  SORT_ASCENDING,
  SORT_DESCENDING,
  SORT_DIRECTIONS
} from 'lib/types/searchable';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { PublicationIssueSectionModel } from 'lib/model/objects/publicationIssueSectionModel';
import { getOrThrow } from 'lib/utils/refs';
import {
  PublicationIssueSearchRequest,
  PublicationIssueStatus
} from 'lib/types/publicationIssue';
import moment from 'moment';
import { safeStringify } from 'lib/utils/stringify';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { useGetPublicationIssueQuery } from '../hooks/useGetPublicationIssueQuery';
import { PaginationUpload } from '../upload/PaginationUpload';
import PublicationIssueDrawer from '../drawer/PublicationIssueDrawer';
import PaginationTableFilterDialog from '../PaginationTableFilterDialog';
import PaginationTableRow from '../PaginationTableRow';
import {
  PaginationTabs,
  PENDING_TAB,
  ADVANCED_PAGINATION_TABS,
  APPROVED_TAB,
  ARCHIVED_TAB,
  AWAITING_APPROVAL_TAB,
  DEFAULT_PAGINATION_ISSUE_FILTER,
  DISABLED_TAB,
  filterHasChanges,
  getShouldUseAdvancedPagination,
  getTableHeaderText,
  numberOfChanges,
  PRINT_READY_TAB,
  READY_FOR_PAGINATION_TAB,
  SIMPLIFIED_PAGINATION_TABS
} from '../paginationTableUtils';
import { UniversalDownloadForm } from '../UniversalDownloadForm';

function AsyncWrappedPaginationTableRow({
  publicationIssueSearchableRecord,
  publishingMediums,
  usingAdvancedPagination,
  product,
  setShowBulkDownloadModal,
  setShowPaginationUploadModal,
  reloadPublicationIssues,
  setUserError,
  setLoadingModalMessage
}: {
  publicationIssueSearchableRecord: SearchablePublicationIssueSectionRecord;
  publishingMediums: PublishingMedium[];
  usingAdvancedPagination: boolean;
  product: Product;
  setShowBulkDownloadModal: (show: false | PublicationIssueModel) => void;
  setShowPaginationUploadModal: (
    show: false | PublicationIssueWithSection
  ) => void;
  onUserError: (error: string) => void;
  reloadPublicationIssues: () => void;
  onShowLoadingModal: (message: string) => void;
  setUserError: (error: string) => void;
  setLoadingModalMessage: (message: string) => void;
}) {
  const { value: publicationIssueWithSection } = useAsyncEffect({
    fetchData: async () => {
      const publicationIssueRef = getFirebaseContext()
        .publicationIssuesRef()
        .doc(publicationIssueSearchableRecord.publicationissueid);
      const publicationIssue = await getOrThrow(publicationIssueRef);
      const publicationIssueSection = await getOrThrow(
        getFirebaseContext()
          .publicationIssueSectionsRef(publicationIssueRef)
          .doc(publicationIssueSearchableRecord.id)
      );
      return {
        section: getModelFromSnapshot(
          PublicationIssueSectionModel,
          getFirebaseContext(),
          publicationIssueSection
        ),
        publicationIssue: getModelFromSnapshot(
          PublicationIssueModel,
          getFirebaseContext(),
          publicationIssue
        )
      };
    },
    dependencies: [
      publicationIssueSearchableRecord.publicationissueid,
      publicationIssueSearchableRecord.id
    ]
  });
  if (!publicationIssueWithSection) {
    return null;
  }
  return (
    <PaginationTableRow
      publicationIssueWithSection={publicationIssueWithSection}
      publishingMediums={publishingMediums}
      usingAdvancedPagination={!!usingAdvancedPagination}
      product={product}
      setShowBulkDownloadModal={setShowBulkDownloadModal}
      setShowPaginationUploadModal={setShowPaginationUploadModal}
      onUserError={setUserError}
      reloadPublicationIssues={reloadPublicationIssues}
      onShowLoadingModal={setLoadingModalMessage}
    />
  );
}

const getSearchablePaginationRecords = async (
  publicationIssueQuery: PublicationIssueSearchRequest,
  paginationTableTab: PaginationTabs
) => {
  const filters: SearchablePublicationIssueSectionRecordFilter[] = [
    {
      publisherid: publicationIssueQuery.publisherIds
    },
    {
      type: [publicationIssueQuery.sectionType || Product.Notice]
    }
  ];
  const sort: Record<string, SORT_DIRECTIONS>[] = [];

  if (paginationTableTab.id === PENDING_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.PENDING
    });
    sort.push({
      publicationtimestamp: SORT_ASCENDING
    });
  } else if (paginationTableTab.id === APPROVED_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.APPROVED
    });
    sort.push({
      publicationtimestamp: SORT_DESCENDING
    });
  } else if (paginationTableTab.id === READY_FOR_PAGINATION_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.READY_FOR_PAGINATION
    });
    sort.push({
      publicationtimestamp: SORT_DESCENDING
    });
  } else if (paginationTableTab.id === PRINT_READY_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.PRINT_READY
    });
    sort.push({
      publicationtimestamp: SORT_DESCENDING
    });
  } else if (paginationTableTab.id === ARCHIVED_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.ARCHIVED
    });
    sort.push({
      publicationtimestamp: SORT_DESCENDING
    });
  } else if (paginationTableTab.id === DISABLED_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.DISABLED
    });
    sort.push({
      publicationtimestamp: SORT_DESCENDING
    });
  } else if (paginationTableTab.id === AWAITING_APPROVAL_TAB.id) {
    filters.push({
      status: PublicationIssueStatus.AWAITING_APPROVAL
    });
    sort.push({
      publicationtimestamp: SORT_DESCENDING
    });
  } else {
    logAndCaptureCriticalError(
      ColumnService.PAGINATION,
      new Error(`Unexpected pagination table tab: ${paginationTableTab.id}`),
      `Unexpected pagination table tab: ${paginationTableTab.id}`,
      {
        paginationTableTabId: paginationTableTab.id
      }
    );
  }

  if (publicationIssueQuery.deadlineTimestampFrom) {
    filters.push({
      deadlinetimestamp: {
        from: publicationIssueQuery.deadlineTimestampFrom.getTime()
      }
    });
  }

  if (publicationIssueQuery.deadlineTimestampTo) {
    filters.push({
      deadlinetimestamp: {
        to: publicationIssueQuery.deadlineTimestampTo.getTime()
      }
    });
  }

  if (publicationIssueQuery.publicationDateFrom) {
    filters.push({
      publicationtimestamp: {
        from: moment(publicationIssueQuery.publicationDateFrom).valueOf()
      }
    });
  }

  if (publicationIssueQuery.publicationDateTo) {
    filters.push({
      publicationtimestamp: {
        to: moment(publicationIssueQuery.publicationDateTo).valueOf()
      }
    });
  }

  const { error, response } = await api.safePost(
    'search/publication-issue-sections',
    {
      filters,
      pageSize: 1000,
      search: '',
      sort
    }
  );
  if (error || !response) {
    return wrapError(new Error(error));
  }
  return wrapSuccess(response);
};

export default function ElasticPaginationTable() {
  const availablePublishers = useAppSelector(selectAvailableOrganizations);
  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [searchParams] = useSearchParams();
  const product = (searchParams.get('product') as Product) || Product.Notice;
  const user = useAppSelector(selectUser);
  // Controls which tab of pagination issue data is shown
  const [paginationTableTab, setPaginationTableTab] =
    useState<PaginationTabs>(PENDING_TAB);

  // Current issue filter (by ad deadline or by publication date)
  const [issueFilter, setIssueFilter] = useState(
    DEFAULT_PAGINATION_ISSUE_FILTER
  );

  const reloadPublicationIssues = () => {
    setRefreshTimestamp(Date.now());
  };

  const [updatedIssueFilter, setUpdatedIssueFilter] = useState(issueFilter);

  const { publicationIssueQuery, publishingMediums } =
    useGetPublicationIssueQuery(
      issueFilter,
      paginationTableTab,
      activeOrganization,
      showAllOrgsNotices,
      availablePublishers,
      product
    );

  const [issuesWithChangesSubmitting, setIssuesWithChangesSubmitting] =
    useState<Record<string, number>>({});
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const changeIssueSubmittingStatus = (
    issueId: string,
    additionalChange: 1 | -1
  ) => {
    setIssuesWithChangesSubmitting(prev => {
      const existingNumberOfChanges = prev[issueId] || 0;
      const newNumberOfChanges = existingNumberOfChanges + additionalChange;
      return { ...prev, [issueId]: newNumberOfChanges };
    });
  };

  const columns = [
    'Newspaper',
    'Deadline',
    'Publication Date',
    'Status',
    ...(user && isColumnUser(user) ? ['Assignee'] : []),
    'Actions'
  ];
  const publisherIsNotReadyForPagination =
    product === Product.Notice &&
    exists(activeOrganization) &&
    !publisherReadyToUpload(activeOrganization);

  // Used to force a refresh of the publication issues
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());

  const [drawerPublicationIssue, setDrawerPublicationIssue] =
    useState<PublicationIssueWithSection>();

  const [showBulkDownloadModal, setShowBulkDownloadModal] = useState<
    false | PublicationIssueModel
  >(false);

  const [showPaginationUploadModal, setShowPaginationUploadModal] = useState<
    false | PublicationIssueWithSection
  >(false);

  const [userError, setUserError] = useState('');

  const { value: usingAdvancedPagination } = useAsyncEffect({
    fetchData: async () => {
      return await getShouldUseAdvancedPagination(activeOrganization, product);
    },
    dependencies: [
      product,
      activeOrganization?.id,
      refreshTimestamp,
      paginationTableTab.id
    ]
  });

  const paginationTabs = usingAdvancedPagination
    ? ADVANCED_PAGINATION_TABS
    : SIMPLIFIED_PAGINATION_TABS;
  const visiblePaginationTabs = shouldShowDisabledColumnForUser(user)
    ? [...paginationTabs, DISABLED_TAB]
    : paginationTabs;

  const {
    value: searchablePaginationRecords,
    error: getSearchablePaginationRecordsError,
    isLoading
  } = useAsyncEffect<SearchablePublicationIssueSectionRecord[]>({
    fetchData: async () => {
      if (!exists(activeOrganization)) {
        return [];
      }
      const { error, response } = await getSearchablePaginationRecords(
        publicationIssueQuery,
        paginationTableTab
      );
      if (error) {
        throw error;
      }
      return response?.results || [];
    },
    dependencies: [
      availablePublishers.map(publisher => publisher.id).join(','),
      product,
      paginationTableTab.id,
      safeStringify(publicationIssueQuery)
    ]
  });
  const showUniversalDownloadButton = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_UNIVERSAL_DOWNLOAD_BUTTON_ON_PAGINATION_TABLE
  );

  const displayError = userError || getSearchablePaginationRecordsError;

  return (
    <div id="pagination-table-view" className="pb-10 py-4 px-8 m-4">
      {showUniversalDownloadButton && <UniversalDownloadForm />}
      <main className="bg-white sm:rounded-lg border border-gray-300 mb-24 shadow-column-2">
        {displayError && (
          <Alert
            title="Error"
            description={displayError}
            id="pagination-table-error"
          />
        )}

        {/* Add in an in-app warning showing why publication issues will not show up for a particular publisher */}
        {publisherIsNotReadyForPagination && (
          <Alert
            title="Publisher not available"
            status="warning"
            description={`The publisher ${
              activeOrganization.data().name
            } does not have public notice placement enabled. Please enable public notice placement to view publication issues.`}
            id="pagination-table-not-ready"
          />
        )}

        <TabGroup
          onClickTab={setPaginationTableTab}
          activeTab={paginationTableTab}
          tabs={visiblePaginationTabs}
          id="pagination-table-tabs"
        />
        <div id="pagination-table">
          <TableLayout
            filterable={{
              shouldShowTableItem: (item, search) => {
                const publisherSnap = availablePublishers.find(
                  org => org.id === item.publisherid
                );
                if (
                  !exists(publisherSnap) ||
                  (product === Product.Notice &&
                    !publisherReadyToUpload(publisherSnap))
                ) {
                  return false;
                }

                // check for matching on the organization name
                if (fuzzyStringContains(publisherSnap.data().name, search)) {
                  return true;
                }

                // check for matching on the issue date
                if (fuzzyStringContains(item.publicationdate, search)) {
                  return true;
                }

                return false;
              },
              additionalFilters: {
                applyFilterChanges: () => {
                  setIssueFilter(updatedIssueFilter);
                },
                filterHasChanges: filterHasChanges(
                  issueFilter,
                  updatedIssueFilter
                ),
                numFiltersActive: numberOfChanges(issueFilter),
                resetFilters: () => {
                  setUpdatedIssueFilter(DEFAULT_PAGINATION_ISSUE_FILTER);
                  setIssueFilter(DEFAULT_PAGINATION_ISSUE_FILTER);
                },
                renderDialog: () => (
                  <PaginationTableFilterDialog
                    updatedFilter={updatedIssueFilter}
                    setUpdatedFilter={setUpdatedIssueFilter}
                  />
                )
              }
            }}
            loading={isLoading}
            renderRow={publicationIssueSearchableRecord => {
              return (
                <AsyncWrappedPaginationTableRow
                  publicationIssueSearchableRecord={
                    publicationIssueSearchableRecord
                  }
                  publishingMediums={publishingMediums}
                  usingAdvancedPagination={!!usingAdvancedPagination}
                  product={product}
                  setShowBulkDownloadModal={setShowBulkDownloadModal}
                  setShowPaginationUploadModal={setShowPaginationUploadModal}
                  onUserError={setUserError}
                  reloadPublicationIssues={reloadPublicationIssues}
                  onShowLoadingModal={setLoadingModalMessage}
                  setUserError={setUserError}
                  setLoadingModalMessage={setLoadingModalMessage}
                />
              );
            }}
            header={getTableHeaderText(paginationTableTab.id, product)}
            columns={columns}
            data={searchablePaginationRecords || []}
            clickable={{
              onClick: async row => {
                const publicationIssueRef = getFirebaseContext()
                  .publicationIssuesRef()
                  .doc(row.publicationissueid);
                const publicationIssue = await getOrThrow(publicationIssueRef);
                const publicationIssueSection = await getOrThrow(
                  getFirebaseContext()
                    .publicationIssueSectionsRef(publicationIssueRef)
                    .doc(row.id)
                );
                if (!publicationIssue || !publicationIssueSection) {
                  return null;
                }
                const publicationIssueWithSection = {
                  section: getModelFromSnapshot(
                    PublicationIssueSectionModel,
                    getFirebaseContext(),
                    publicationIssueSection
                  ),
                  publicationIssue: getModelFromSnapshot(
                    PublicationIssueModel,
                    getFirebaseContext(),
                    publicationIssue
                  )
                };
                setDrawerPublicationIssue(publicationIssueWithSection);
              }
            }}
            pagination={{ pageSize: 15 }}
          />
        </div>
        {drawerPublicationIssue && (
          <PublicationIssueDrawer
            publicationIssueWithSection={drawerPublicationIssue}
            closeDrawer={() => setDrawerPublicationIssue(undefined)}
            product={product}
            usingAdvancedPagination={!!usingAdvancedPagination}
          />
        )}
        {!!showBulkDownloadModal && (
          <BulkDownloadModal
            setOpen={value => {
              if (value === false) {
                setShowBulkDownloadModal(false);
              }
            }}
            publicationDate={showBulkDownloadModal.modelData.publicationDate}
            organization={
              availablePublishers.find(
                org => org.id === showBulkDownloadModal.modelData.publisher.id
              ) || null
            }
          />
        )}
        {!!showPaginationUploadModal && (
          <PaginationUpload
            publicationIssueWithSection={showPaginationUploadModal}
            product={product}
            changesSubmittingForIssue={
              issuesWithChangesSubmitting[
                showPaginationUploadModal.publicationIssue.id
              ] || 0
            }
            onHandleInProgressChanges={additionalChange =>
              changeIssueSubmittingStatus(
                showPaginationUploadModal.publicationIssue.id,
                additionalChange
              )
            }
            reloadPublicationIssues={reloadPublicationIssues}
            setShowPaginationUploadModal={setShowPaginationUploadModal}
            setPaginationTableTab={setPaginationTableTab}
          />
        )}
        {!!loadingModalMessage && (
          <Modal
            id="pagination-table-loading-modal"
            title="Loading, please wait..."
            subtitle={loadingModalMessage}
            onClose={() => setLoadingModalMessage('')}
          >
            <LoadingSpinner
              gifToReplaceSpinner={
                <img
                  className="rounded-full pt-4 pb-5 pl-4 pr-2 h-48 w-48 mb-4 bg-column-gray-100"
                  src={CatUploadingAHamburger}
                />
              }
            />
          </Modal>
        )}
      </main>
    </div>
  );
}
