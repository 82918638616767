import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { EOrganization, ERef, ETemplate } from 'lib/types';
import { useState, useContext, useEffect } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromId, getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { isAnonymousUserOrder, isPublisherOrder } from 'lib/types/order';
import { Alert } from 'lib/components/Alert';

import { OrderModel } from 'lib/model/objects/orderModel';
import { logAndCaptureException } from 'utils';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { getPublisherOrgOrderTemplate } from 'lib/utils/templates';
import { ColumnService } from 'lib/services/directory';
import LoadingState from 'components/LoadingState';
import { Ad, CategoryChoiceOption } from 'lib/types/ad';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';
import { groupBy } from 'lodash';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { asyncFilter, asyncMap, isDefined } from 'lib/helpers';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import { safeAsync } from 'lib/safeWrappers';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { DetailedProductPublishingSetting } from 'lib/types/publishingSetting';
import {
  doFilingTypesHaveConflictingMadlibs,
  isTemplateValidationIssue,
  validateNewspapersAndFilingTypes
} from './validation';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import { shouldExcludeNewspaperOrder } from '../CategoryChoice/helpers';
import PublisherCard from './PublisherCard';
import {
  getColorSettingsVisibility,
  getShouldForceGrayscale
} from '../colorHelpers';
import { getDefaultLayout } from '../layoutHelpers';
import { getJobcaseEnablement } from '../jobcaseHelpers';
import { useAdForm } from '../../contexts/AdFormStatusProvider';

const ALL_MEDIUMS = Object.values(PublishingMedium);

const getValidProductPublishingSettings = async (
  newspaper: ERef<EOrganization>,
  product: Product,
  isUserPublisher: boolean
): Promise<ResponseOrError<DetailedProductPublishingSetting[]>> => {
  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );

  const mediums = await asyncFilter(
    ALL_MEDIUMS,
    async (
      medium
    ): Promise<ResponseOrError<DetailedProductPublishingSetting | null>> => {
      const result =
        await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
          newspaper,
          product,
          medium,
          {
            shouldCreate: false
          }
        );

      // It's fine if the settings aren't there; we only care about other errors.
      if (result.error && !(result.error instanceof NotFoundError)) {
        return result;
      }

      const detailedProductPublishingSetting = result.response;

      const visibleFilingTypes =
        detailedProductPublishingSetting?.filingTypes.filter(filingType =>
          filingType.isVisibleToUser(isUserPublisher)
        );

      if (
        !detailedProductPublishingSetting ||
        !visibleFilingTypes ||
        !visibleFilingTypes.length ||
        detailedProductPublishingSetting.publishingSetting.modelData.deadlines.every(
          deadline => !deadline.publish
        )
      ) {
        return wrapSuccess(null);
      }

      return wrapSuccess({
        ...detailedProductPublishingSetting,
        filingTypes: visibleFilingTypes
      });
    }
  );

  return mediums;
};

const getFilingTypeForProduct = async (
  organizationModel: OrganizationModel,
  selectedFilingType: CategoryChoiceOption,
  anonymousOrder: boolean,
  product: Product
) => {
  const validFilingTypesResult = await asyncFilter(
    ALL_MEDIUMS,
    async medium => {
      const { response: matchedFilingType, error: filingTypeMatchError } =
        await organizationModel.fetchFilingTypeForProductMedium({
          selectedFilingType,
          product,
          publishingMedium: medium
        });

      const result =
        !matchedFilingType ||
        shouldExcludeNewspaperOrder({
          matchedFilingType,
          filingTypeMatchError,
          anonymousOrder
        })
          ? null
          : [medium, matchedFilingType];

      return wrapSuccess(result);
    }
  );

  if (validFilingTypesResult.error) {
    return validFilingTypesResult;
  }

  const validFilingTypes: Record<PublishingMedium, FilingTypeModel> =
    Object.fromEntries(validFilingTypesResult.response);

  return wrapSuccess(validFilingTypes);
};

async function slowGetNewspaperOrder(
  product: Product,
  newspaper: OrganizationModel,
  publishingMedium: PublishingMedium,
  anonymousOrder: boolean,
  inputData: Partial<Ad>
): Promise<ResponseOrError<Partial<NewspaperOrder>>> {
  const { response: adTemplate, error } = await getPublisherOrgOrderTemplate(
    getFirebaseContext(),
    newspaper.ref,
    product,
    publishingMedium
  );

  if (error) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      error,
      'Unable to get the template for order',
      { newspaperId: newspaper.id, product }
    );
    return wrapError(error);
  }

  const selectedFilingType = inputData.filingTypeName as CategoryChoiceOption;
  const validFilingTypesResult = await getFilingTypeForProduct(
    newspaper,
    selectedFilingType,
    anonymousOrder,
    product
  );

  if (validFilingTypesResult.error) {
    return validFilingTypesResult;
  }

  const productFilingType = validFilingTypesResult.response[publishingMedium];

  const [rateError, rate] = await productFilingType.getRate();
  if (rateError) {
    return wrapError(rateError);
  }
  const rateData = rate.data();
  const shouldForceGrayscale = getShouldForceGrayscale(rateData);
  const colorEnablement = getColorSettingsVisibility(rateData);
  const jobcaseEnablement = getJobcaseEnablement(rateData);

  const supportedLayouts =
    productFilingType.getSortedSupportedLayouts(inputData);

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: productFilingType?.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    jobcaseEnablement,
    colorEnablement,
    colorOptions: {
      isGrayscale: shouldForceGrayscale,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    // check if productFilingType exists before adding it to the order so we don't have an undefined field in firestore
    ...(productFilingType
      ? {
          filingType: productFilingType.ref,
          layout: getDefaultLayout(supportedLayouts)
        }
      : {})
  });
}

async function fastGetNewspaperOrder(
  newspaper: OrganizationModel,
  publishingMedium: PublishingMedium,
  inputData: Partial<Ad>,
  filingType?: FilingTypeModel,
  adTemplate?: ERef<ETemplate>
): Promise<ResponseOrError<Partial<NewspaperOrder>>> {
  if (!filingType) {
    return wrapError(new Error('Missing filing type'));
  }

  const productFilingType = filingType;

  const [rateError, rate] = await productFilingType.getRate();
  if (rateError) {
    return wrapError(rateError);
  }
  const rateData = rate.data();
  const shouldForceGrayscale = getShouldForceGrayscale(rateData);
  const colorEnablement = getColorSettingsVisibility(rateData);
  const jobcaseEnablement = getJobcaseEnablement(rateData);
  const supportedLayouts =
    productFilingType.getSortedSupportedLayouts(inputData);

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: productFilingType.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    jobcaseEnablement,
    colorEnablement,
    colorOptions: {
      isGrayscale: shouldForceGrayscale,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    filingType: productFilingType.ref,
    layout: getDefaultLayout(supportedLayouts)
  });
}

export type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
  publishersLoading: boolean;
  autoSelectedPaper: ColumnSelectOption<string> | null;
  showAddPublications: boolean;
  availablePublisherOptions: ColumnSelectOption<string>[];
  stateOptions: number[];
  stateFilter: number | undefined;
  setStateFilter: React.Dispatch<React.SetStateAction<number | undefined>>;
};

function SelectPublication({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  inputData,
  orderModel,
  publishersLoading,
  autoSelectedPaper,
  showAddPublications,
  availablePublisherOptions,
  stateOptions,
  stateFilter,
  setStateFilter
}: SelectPublicationProps) {
  const useEfficientPublisherLoading = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_EFFICIENT_ORDER_PUBLISHER_SELECTION,
    false
  );
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
  const { clearUserError, setFormStatus } = useAdForm();

  const newspaperOrdersWithMedium = newspaperOrdersFormData.filter(
    (
      no
    ): no is Partial<NewspaperOrder> &
      Pick<NewspaperOrder, 'publishingMedium'> => isDefined(no.publishingMedium)
  );

  const newspaperOrdersByNewspaperId = groupBy(
    newspaperOrdersWithMedium,
    no => no.newspaper?.id
  ) as Record<string, typeof newspaperOrdersWithMedium>;

  const [searchedNewspaperId, setSearchedNewspaperId] = useState<
    string | undefined
  >(undefined);

  const [addNewspaperAlert, setAddNewspaperAlert] = useState('');
  const anonymousOrder = isAnonymousUserOrder(orderModel.modelData);

  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const searchedNewspaperLoaded = publishersAvailableForPlacement.find(
    o => o.id === searchedNewspaperId
  );

  const [
    validProductPublishingSettingsByNewspaperId,
    setProductPublishingSettingsByNewspaperId
  ] = useState<Record<string, DetailedProductPublishingSetting[]>>({});

  const { isLoading: isLoadingValidSettings } = useAsyncEffect({
    fetchData: async () => {
      const uniquePublishers = newspaperOrdersFormData.filter(
        (o, idx) =>
          newspaperOrdersFormData.findIndex(
            otherOrder => otherOrder.newspaper?.id === o.newspaper?.id
          ) === idx
      );
      const validSettingsForExistingPapersResult = await asyncMap(
        uniquePublishers,
        async o => {
          if (!o.newspaper) {
            return wrapError(Error('Newspaper not found'));
          }

          const [
            getPublishingSettingsError,
            validProductPublishingSettingsForPaper
          ] = await getValidProductPublishingSettings(
            o.newspaper,
            product,
            isPublisherOrder(orderModel.modelData)
          );

          if (getPublishingSettingsError) {
            return wrapError(getPublishingSettingsError);
          }

          return wrapSuccess([
            o.newspaper.id,
            validProductPublishingSettingsForPaper
          ]);
        }
      );

      if (validSettingsForExistingPapersResult.error) {
        return validSettingsForExistingPapersResult;
      }

      const validMediumsForExistingPapers: Record<
        string,
        DetailedProductPublishingSetting[]
      > = Object.fromEntries(validSettingsForExistingPapersResult.response);

      setProductPublishingSettingsByNewspaperId(validMediumsForExistingPapers);

      return wrapSuccess(validMediumsForExistingPapers);
    },
    dependencies: []
  });

  const loading = publishersLoading || isLoadingValidSettings;

  async function fastAddNewspaper({
    organizationModel,
    newProductPublishingSettingsByNewspaperId
  }: {
    organizationModel: OrganizationModel;
    newProductPublishingSettingsByNewspaperId: Record<
      string,
      DetailedProductPublishingSetting[]
    >;
  }) {
    if (!inputData.filingTypeName) {
      return setAddNewspaperAlert('Please select a category first.');
    }

    // Check if newspaper supports category (aka filing type) selected.
    // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
    const newspaperPublishingSettings =
      newProductPublishingSettingsByNewspaperId[organizationModel.id].filter(
        publishingSetting =>
          publishingSetting.filingTypes.some(
            filingType =>
              filingType.modelData.label === inputData.filingTypeName
          )
      );
    /*
     * Set add error alert if filing type is unavailable for selected newspaper.
     */
    if (!newspaperPublishingSettings.length) {
      return setAddNewspaperAlert(
        `${organizationModel.modelData.name} selected does not support ${inputData.filingTypeName}. Please select another publisher.`
      );
    }

    const allFilingTypes = Object.keys(
      newProductPublishingSettingsByNewspaperId
    ).flatMap(publisherId => {
      const filingTypes = newProductPublishingSettingsByNewspaperId[publisherId]
        .map(setting => setting.filingTypes)
        .flat();
      return filingTypes.filter(
        filingType => filingType.modelData.label === inputData.filingTypeName
      );
    });

    const filingTypesHaveConflictingMadlibs =
      doFilingTypesHaveConflictingMadlibs(allFilingTypes);

    if (filingTypesHaveConflictingMadlibs) {
      const alertMessage = `The selected publications have templates for ${inputData.filingTypeName} ads that do not support placing in multiple publications. Please select a different category or publisher.`;
      return setAddNewspaperAlert(alertMessage);
    }

    clearUserError();
    setAddNewspaperAlert('');

    return await asyncMap(newspaperPublishingSettings, publishingSetting => {
      const filingType = publishingSetting.filingTypes.find(
        filingType => filingType.modelData.label === inputData.filingTypeName
      );

      return fastGetNewspaperOrder(
        organizationModel,
        publishingSetting.productPublishingSetting.modelData.publishingMedium,
        inputData,
        filingType,
        publishingSetting.publishingSetting.modelData.adTemplate
      );
    });
  }

  async function slowAddNewspaper({
    organizationModel,
    newProductPublishingSettingsByNewspaperId
  }: {
    organizationModel: OrganizationModel;
    newProductPublishingSettingsByNewspaperId: Record<
      string,
      DetailedProductPublishingSetting[]
    >;
  }) {
    let newspaperPublishingSettings =
      newProductPublishingSettingsByNewspaperId[organizationModel.id];

    // We need the filing type model out of the block below
    // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
    let availableClassifiedFilingTypesByMedium: Partial<
      Record<PublishingMedium, FilingTypeModel>
    > = {};
    // Check if newspaper supports category (aka filing type) selected. We only need to do this for classifieds
    if (inputData.filingTypeName) {
      const validFilingTypesResult = await getFilingTypeForProduct(
        organizationModel,
        inputData.filingTypeName,
        anonymousOrder,
        product
      );

      if (validFilingTypesResult.error) {
        return setAddNewspaperAlert(
          'Failed to validate filing types for newspaper.'
        );
      }

      availableClassifiedFilingTypesByMedium = validFilingTypesResult.response;

      newspaperPublishingSettings = newspaperPublishingSettings.filter(
        publishingSetting =>
          Object.keys(availableClassifiedFilingTypesByMedium).includes(
            publishingSetting.productPublishingSetting.modelData
              .publishingMedium
          )
      );
      /*
       * Set add error alert if filing type is unavailable for selected newspaper.
       */
      if (!newspaperPublishingSettings.length) {
        return setAddNewspaperAlert(
          `${organizationModel.modelData.name} selected does not support ${inputData.filingTypeName}. Please select another publisher.`
        );
      }

      const validationStatus = await validateNewspapersAndFilingTypes({
        newspaperOrders: [
          ...newspaperOrdersFormData,
          ...Object.keys(availableClassifiedFilingTypesByMedium).map(
            medium => ({
              newspaper: organizationModel.ref,
              publishingMedium: medium as PublishingMedium
            })
          )
        ],
        selectedFilingTypeLabel: inputData.filingTypeName,
        product
      });

      if (!validationStatus.isValid) {
        const alertMessage = isTemplateValidationIssue(validationStatus.details)
          ? `The following publications have templates for ${
              inputData.filingTypeName
            } ads that do not support placing in multiple publications: ${validationStatus.details.papersWithTemplatedFilingTypes.join(
              ', '
            )}`
          : 'An unexpected error occurred while validating selected publishers. Please try again.';
        return setAddNewspaperAlert(alertMessage);
      }

      clearUserError();
      setAddNewspaperAlert('');
    }

    return await asyncMap(newspaperPublishingSettings, publishingSetting =>
      slowGetNewspaperOrder(
        product,
        organizationModel,
        publishingSetting.productPublishingSetting.modelData.publishingMedium,
        anonymousOrder,
        inputData
      )
    );
  }

  async function addNewspaper(newspaperId: string) {
    if (newspaperOrdersByNewspaperId[newspaperId]) {
      return;
    }

    const [getOrganizationError, organizationModel] = await safeAsync(() =>
      getModelFromId(
        OrganizationModel,
        getFirebaseContext(),
        getFirebaseContext().organizationsRef(),
        newspaperId
      )
    )();

    if (getOrganizationError) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        getOrganizationError,
        'Unable to get the organization model',
        { newspaperId }
      );
      return setAddNewspaperAlert(
        'There was an error loading the publisher. Please try again.'
      );
    }

    // Load all publishing settings with all filing types for the product
    const [publishingSettingsError, validPublishingSettings] =
      await getValidProductPublishingSettings(
        organizationModel.ref,
        product,
        isPublisherOrder(orderModel.modelData)
      );

    if (publishingSettingsError) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        publishingSettingsError,
        'Error getting valid product publishing settings for publisher',
        { newspaperId: organizationModel.id }
      );
      return setAddNewspaperAlert(
        'Failed to get valid publishing mediums for newspaper.'
      );
    }

    const newProductPublishingSettingsByNewspaperId = {
      ...validProductPublishingSettingsByNewspaperId,
      [organizationModel.id]: validPublishingSettings
    };

    setProductPublishingSettingsByNewspaperId(
      newProductPublishingSettingsByNewspaperId
    );

    const newOrdersResult = useEfficientPublisherLoading
      ? await fastAddNewspaper({
          organizationModel,
          newProductPublishingSettingsByNewspaperId
        })
      : await slowAddNewspaper({
          organizationModel,
          newProductPublishingSettingsByNewspaperId
        });

    if (!newOrdersResult || newOrdersResult.error) {
      return setAddNewspaperAlert('Failed to add newspaper');
    }

    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData,
      ...newOrdersResult.response
    ]);
    setSearchedNewspaperId(newspaperId);
  }

  function removeNewspaper(newspaperId: string) {
    onNewspaperOrdersFormDataChange(
      newspaperOrdersFormData.filter(no => no.newspaper?.id !== newspaperId)
    );
  }

  useEffect(() => {
    if (newspaperOrdersFormData.length || !autoSelectedPaper) {
      return;
    }

    // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
    if (autoSelectedPaper) {
      void addNewspaper(autoSelectedPaper.value);
    }
  }, [autoSelectedPaper?.value]);

  const selectedNewspaperIds = Object.keys(newspaperOrdersByNewspaperId);

  return (
    <>
      {addNewspaperAlert && (
        <Alert
          id="add-newspaper-error"
          onDismiss={() => setAddNewspaperAlert('')}
          title={addNewspaperAlert}
          status="error"
          icon={<ExclamationCircleIcon className="h-5 w-5" />}
        />
      )}

      <div className="grid grid-cols-12 gap-6">
        {showAddPublications && (
          <>
            <div className="col-span-12 md:col-span-6 xl:col-span-8">
              <Autocomplete
                id="selectPublisher"
                labelText="Add publications"
                placeholder={`Choose one or more places to run your ${productTypeName}`}
                value={
                  searchedNewspaperLoaded && !loading ? searchedNewspaperId : ''
                }
                options={availablePublisherOptions}
                onChange={async newspaperId => {
                  setFormStatus('loading');
                  if (
                    newspaperOrdersFormData.find(
                      o => o.newspaper?.id === newspaperId
                    )
                  ) {
                    removeNewspaper(newspaperId);
                  } else if (newspaperId) {
                    await addNewspaper(newspaperId);
                  }
                  setFormStatus('idle');
                }}
                loading={loading}
                required={publishersAvailableForPlacement.length === 0}
                validationMessages={{
                  valueMissing: 'Please select a publisher'
                }}
                selectedOptionsValues={selectedNewspaperIds}
                showCheckBoxForSelectedItems
              />
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
              <PublisherLocationFilter
                stateOptions={stateOptions}
                onStateChange={state => {
                  setStateFilter(state);
                }}
                activeFilters={{ stateFilter }}
              />
            </div>
          </>
        )}

        <div className="col-span-12 flex flex-col gap-8">
          {loading && (
            <LoadingState
              isFullScreen={false}
              context={{
                service: ColumnService.ORDER_PLACEMENT,
                location: 'Ad placement - Select publication',
                tags: {
                  product,
                  adPlacementFlow: 'true',
                  orderId: orderModel.id
                }
              }}
            />
          )}

          {!loading &&
            Object.entries(newspaperOrdersByNewspaperId).map(
              ([newspaperId, ordersForNewspaper]) => {
                /**
                 * We are traversing newspaperOrders instead of the newspapers context
                 * so the soft deleting of newspaperOrders can be reflected in the UI
                 */
                const newspaper = publishersAvailableForPlacement.find(
                  n => n.id === newspaperId
                );
                if (!newspaper) return null;
                return (
                  <div key={newspaper.id} className="col-span-12">
                    <PublisherCard
                      inputData={inputData}
                      onRemovePublisher={removeNewspaper}
                      required={
                        newspaper.data().name === autoSelectedPaper?.label
                      }
                      newspaper={newspaper}
                      validProductPublishingSettings={(
                        validProductPublishingSettingsByNewspaperId[
                          newspaperId
                        ] || []
                      ).map(
                        setting => setting.productPublishingSetting.modelData
                      )}
                      publishingMediums={ordersForNewspaper.map(
                        o => o.publishingMedium
                      )}
                      onPublishingMediumsChange={async value => {
                        setAddNewspaperAlert('');
                        setFormStatus('loading');

                        const missingMedium = !ordersForNewspaper.some(
                          o => o.publishingMedium === value
                        );

                        if (missingMedium) {
                          const publishingSettings =
                            validProductPublishingSettingsByNewspaperId[
                              newspaperId
                            ];
                          const relevantPublishingSetting =
                            publishingSettings.find(
                              setting =>
                                setting.productPublishingSetting.modelData
                                  .publishingMedium === value
                            );
                          const organizationModel = getModelFromSnapshot(
                            OrganizationModel,
                            getFirebaseContext(),
                            newspaper
                          );
                          const newOrderResult = useEfficientPublisherLoading
                            ? await fastGetNewspaperOrder(
                                organizationModel,
                                value,
                                inputData,
                                relevantPublishingSetting?.filingTypes.find(
                                  filingType =>
                                    filingType.modelData.label ===
                                    inputData.filingTypeName
                                ),
                                relevantPublishingSetting?.publishingSetting
                                  .modelData.adTemplate
                              )
                            : await slowGetNewspaperOrder(
                                product,
                                organizationModel,
                                value,
                                anonymousOrder,
                                inputData
                              );

                          if (newOrderResult.error) {
                            return;
                          }

                          onNewspaperOrdersFormDataChange([
                            ...newspaperOrdersFormData,
                            newOrderResult.response
                          ]);
                        } else {
                          if (
                            !ordersForNewspaper.some(
                              o => o.publishingMedium !== value
                            )
                          ) {
                            setAddNewspaperAlert(
                              'At least one publishing medium must be selected.'
                            );

                            return;
                          }

                          onNewspaperOrdersFormDataChange(
                            newspaperOrdersFormData.filter(
                              o =>
                                o.newspaper?.id !== newspaperId ||
                                o.publishingMedium !== value
                            )
                          );
                        }

                        setFormStatus('idle');
                      }}
                    />
                  </div>
                );
              }
            )}
        </div>
      </div>
    </>
  );
}

export default SelectPublication;
